import { useContext, useEffect, useState } from 'react';
import classes from './Record.module.scss';
import {
  GetUserRecordContext,
  GetUserRecordResponse,
} from '../../hooks/reducer/UserRecordReducer';
import { InstrumentType, typeMapper } from '../../hooks/reducer/UserJobReducer';
import miraImage from '../../static/images/img_mira.png';
import { getColorByPoint } from '../../common/utility/style.util';
import { addComma, formatSign } from '../../common/utility/number.util';
import { FooterContext } from '../../organisms/footer/Footer';
import { useNavigate } from 'react-router-dom';
import { useJob } from '../../hooks/custom/useJob';
import flatIcon from '../../static/images/flat1-mirai-ina.png';
import smileIcon from '../../static/images/smile-mirai-ina.png';
import sadIcon from '../../static/images/sad-mirai-ina.png';
import { FetchStatusContext } from '../../hooks/reducer/StatusReducer';
import { badgeMap } from '../../molecules/badge-result/BadgeResultCard';
import teacherIcon from '../../static/images/teacher-point.png';

const Record = () => {
  const userJob = useJob();
  const userRecordContext = useContext(GetUserRecordContext);
  const statusContext = useContext(FetchStatusContext);
  const [userRecordList, setUserRecordList] =
    useState<GetUserRecordResponse[]>();
  const { setFooterMessages, setFooterIcon } = useContext(FooterContext);
  const navigate = useNavigate();

  const getBadge = (record: GetUserRecordResponse) => {
    const targetStatus = statusContext?.state?.data?.filter(
      (d) => d.instrumentType === record.instrumentType
    );

    if (targetStatus == null) {
      return '';
    }

    let index = 0;
    targetStatus.forEach((d, i) => {
      if (d.clearCount === record.statusClearCount) {
        index = i;
      }
    });

    return badgeMap[record.instrumentType][index];
  };
  useEffect(() => {
    userRecordContext?.refetch?.();
    statusContext?.refetch?.({ instrumentType: 'ALL' });
  }, []);

  useEffect(() => {
    if (userJob == null) {
      return;
    }

    if (userJob.totalReturn == null) {
      return;
    }

    if (userJob.totalReturn === 0) {
      setFooterIcon(flatIcon);
      setFooterMessages([
        '運用（うんよう）のコツは「リスク」と「リターン」の バランス ミラ！',
        'ミラを 増（ふ）やすためには どんな運用（うんよう）をすれば いいミラ？',
      ]);
    } else if (userJob.totalReturn > 0) {
      setFooterIcon(smileIcon);
      setFooterMessages([
        'イイね！運用（うんよう）は じゅんちょうミラ！',
        '次のグレードのスキルバッジを目指すミラ！',
      ]);
    } else {
      setFooterIcon(sadIcon);
      setFooterMessages([
        '運用（うんよう）のコツは「リスク」と「リターン」の バランス ミラ！',
        '「リスク」を 減（へ）らすと いいかもしれないミラ！',
      ]);
    }
  }, [userJob]);

  useEffect(() => {
    const response = userRecordContext?.state?.data;
    if (response == null) {
      return;
    }
    setUserRecordList(response);
  }, [userRecordContext]);

  return (
    <div className={classes.container}>
      {userRecordList?.length === 0 ? (
        <div className={classes.noRecord}>
          <div className={classes.noRecordMessgae}>
            <p>うんようのきろくがのこっていくよ！</p>
            <img src={teacherIcon} alt='wallet teacher' />
          </div>
        </div>
      ) : (
        userRecordList?.map((record, index) => (
          <div
            className={classes.record}
            onClick={() =>
              navigate(`/record-chart`, {
                state: record,
              })
            }
          >
            <div className={classes.investmentTermName}>
              {record.investmentTermName}
            </div>
            <div className={classes.instrumentType}>
              {typeMapper[record.instrumentType]}
            </div>
            {record.statusId && (
              <div className={classes.statusArea}>
                <img
                  src={getBadge(record)}
                  alt={getBadge(record)}
                  className={classes.badgeIcon}
                />
                <div>
                  <div className={classes.statusName}>{record.statusName}</div>
                  <div className={classes.clearCount}>
                    {typeMapper[record.instrumentType]}で合計
                    {record.statusClearCount}
                    回ミラをふやす
                  </div>
                </div>
              </div>
            )}
            <div className={classes.pointArea}>
              <div className={classes.totalCurrentPointContainer}>
                <img
                  className={classes.miraIcon}
                  src={miraImage}
                  alt={miraImage}
                />
                {/* 色の条件をtotalReturnの正負判定と合わせるためにあえて指定 */}
                <div
                  style={getColorByPoint(record.totalReturn)}
                  className={classes.totalCurrentPoint}
                >
                  {addComma(record.totalCurrentPoint)}
                </div>
              </div>
              <div className={classes.totalSubArea}>
                <div
                  className={classes.totalReturn}
                  style={getColorByPoint(record.totalReturn)}
                >
                  {formatSign(record.totalReturn)}
                </div>
                <div
                  className={classes.totalYield}
                  style={getColorByPoint(record.totalYield)}
                >
                  {formatSign(record.totalYield, true)}
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Record;
