import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleButton from '../../atoms/simple-button/SimpleButton';
import { UserJobType } from '../../hooks/custom/useJob';
import { DeleteUserRecordContext } from '../../hooks/reducer/DeleteUserRecordReducer';
import { PostUserRecordContext } from '../../hooks/reducer/PostUserRecordReducer';
import ResultCard from '../../molecules/result-card/ResultCard';
import Header from '../header/Header';
import classes from './CancelResult.module.scss';

type Props = {
  userJob: UserJobType;
  isPrev: boolean;
};
const CancelResult = (props: Props) => {
  const [status, setStatus] = useState<'confirm' | 'cancel'>('confirm');
  const postUserRecordReducer = useContext(PostUserRecordContext);
  const deleteUserRecordReducer = useContext(DeleteUserRecordContext);

  const userJobTarget = props.isPrev
    ? props.userJob.prevUserJob
    : props.userJob.userJob;
  const term = props.isPrev
    ? props.userJob.calendarStatus?.prevInvestmentTerm
    : props.userJob.calendarStatus?.currentInvestmentTerm;

  const navigate = useNavigate();

  const isSubmitButtonDisabled = props.userJob?.user?.totalResetCount == 3;

  const cancel = () => {
    if (term == null) {
      return;
    }

    deleteUserRecordReducer.deleteRequest?.({
      investmentTerm: term,
    });
    setStatus('cancel');
  };

  const submit = () => {
    if (
      props.userJob.calendarStatus == null ||
      userJobTarget == null ||
      term == null
    ) {
      return;
    }

    postUserRecordReducer.post?.({
      investmentTerm: term,
      totalInitialAllocatedPoint:
        userJobTarget.totalInitialInvestmentAllocatedPoint,
      totalCurrentPoint: userJobTarget.totalCurrentPoint,
      totalReturn: userJobTarget.totalReturn,
      totalYield: userJobTarget.totalYield,
    });

    navigate('/job');
  };
  return (
    <div className={classes.container}>
      <Header title='ウォレット' />
      <div className={classes.inner}>
        {status === 'confirm' && (
          <>
            <div className={classes.title}>
              けっかを キャンセル しますか？
            </div>
            <div className={classes.subtitle}>
              キャンセルできる回数：あと
              {3 - (props.userJob?.user?.totalResetCount ?? 0)}回
            </div>
          </>
        )}
        {status === 'cancel' && (
          <>
            <div className={classes.title}>キャンセル しました</div>
            <div className={classes.subtitle}>
              キャンセルできる回数：あと
              {2 - (props.userJob?.user?.totalResetCount ?? 0)}回
            </div>
          </>
        )}
        <ResultCard isCancel={status === 'cancel'} userJob={userJobTarget} />
        <div className={classes.buttonArea}>
          {status === 'confirm' && (
            <>
              <div className={classes.button}>
                <SimpleButton
                  color='transparent'
                  label='いいえ'
                  minWidth='180rem'
                  maxWidth='180rem'
                  minHeight='48rem'
                  fontSize='24rem'
                  onClick={submit}
                />
              </div>
              <div className={classes.button}>
                <SimpleButton
                  color='red'
                  label='はい'
                  minWidth='180rem'
                  maxWidth='180rem'
                  minHeight='48rem'
                  fontSize='24rem'
                  onClick={cancel}
                  disabled={isSubmitButtonDisabled}
                />
              </div>
            </>
          )}
          {status === 'cancel' && (
            <div className={classes.button}>
              <SimpleButton
                label='うんよう画面へ'
                minWidth='180rem'
                maxWidth='180rem'
                minHeight='48rem'
                fontSize='24rem'
                onClick={() => navigate('/job')}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CancelResult;
