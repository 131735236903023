import { addComma } from '../../common/utility/number.util';
import classes from './DisplayPoint.module.scss';

type DisplayPointProps = {
  withLabel?: true;
  point?: number;
};

const DisplayPoint = (props: DisplayPointProps) => {
  return (
    <div className={classes.container}>
      {props.withLabel && <div className={classes.label}>保有ミラ</div>}
      <div className={classes.mira}>
        {props.point != null ? addComma(props.point) : 0}
      </div>
    </div>
  );
};

export default DisplayPoint;
