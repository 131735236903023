import { JobStatus } from '../../hooks/custom/useJob';
import { toTime } from './date.util';
import { createJobStartKey, getLocalStorage } from './localstorage.util';

export const isFirstJobStart = (
  jobStatus: JobStatus,
  currentInvestmentTerm: string,
  today: string | Date,
  announceDay: string | Date
) => {
  if (isJobEnd(today, announceDay)) {
    return false;
  }

  if (jobStatus === 'PROGRESS' || jobStatus === 'NOTHING') {
    const key = createJobStartKey(currentInvestmentTerm);
    if (getLocalStorage(key) == null) {
      return true;
    }
  }

  return false;
};

export const isJobEnd = (today: string | Date, announceDay: string | Date) => {
  return toTime(today) >= toTime(announceDay);
};
