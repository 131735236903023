export const createJobStartKey = (investmentTerm: string) => {
  return `mirai-wallet-job-start-${investmentTerm}`;
};

export const createNotInvestKey = (investmentTerm: string) => {
  return `mirai-wallet-not-invest-${investmentTerm}`;
};

export const saveLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, value);
};

export const getLocalStorage = (key: string) => {
  return localStorage.getItem(key);
};
