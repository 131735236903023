import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useJob } from '../../hooks/custom/useJob';
import { PostUserRecordContext } from '../../hooks/reducer/PostUserRecordReducer';
import ResultCard from '../../molecules/result-card/ResultCard';
import CancelResult from '../../organisms/cancel-result/CancelResult';
import SkillBadge from '../../organisms/skill-badge/SkillBadge';
import flatImage from '../../static/images/teacher-flat.png';
import minusImage from '../../static/images/teacher-sad.png';
import plusImage from '../../static/images/teacher-smile.png';
import classes from './JobEnd.module.scss';
import { isJobEnd } from '../../common/utility/business.util';

const JobEnd = () => {
  const isPrev =
    new URLSearchParams(useLocation().search).get('term') === 'prev';
  const [show, setShow] = useState<'normal' | 'skillbadge' | 'cancel'>(
    'normal'
  );

  const [image, setImage] = useState<string>();
  const userJob = useJob();
  const postUserRecordReducer = useContext(PostUserRecordContext);
  const navigate = useNavigate();

  const click = () => {
    if (
      userJob.userJob == null ||
      userJob.prevUserJob == null ||
      userJob.calendarStatus == null
    ) {
      return;
    }

    const d = isPrev ? userJob.prevUserJob : userJob.userJob;
    if (d.totalReturn >= 0) {
      const term = isPrev
        ? userJob.calendarStatus.prevInvestmentTerm
        : userJob.calendarStatus.currentInvestmentTerm;
      postUserRecordReducer.post?.({
        investmentTerm: term,
        totalInitialAllocatedPoint: d.totalInitialInvestmentAllocatedPoint,
        totalCurrentPoint: d.totalCurrentPoint,
        totalReturn: d.totalReturn,
        totalYield: d.totalYield,
      });
      return;
    }
    toPage('cancel');
  };

  const toPage = (to: 'skillbadge' | 'job' | 'cancel') => {
    if (to === 'job') {
      navigate('/job');
    } else {
      setShow(to);
    }
  };

  useEffect(() => {
    const response = postUserRecordReducer.state.data?.[0];
    if (response == null) {
      return;
    }

    if (response.statusId != null) {
      toPage('skillbadge');
    } else {
      toPage('job');
    }
  }, [postUserRecordReducer.state]);

  useEffect(() => {
    if (
      userJob == null ||
      userJob.userJob == null ||
      userJob.businessDate == null ||
      userJob.calendarStatus == null
    ) {
      return;
    }

    // URL直接アクセスしてきた場合、ちゃんと結果発表期間か判定して
    // そうでない場合はjob画面に飛ばす
    const isPrevJobStatus = userJob.userJob.prevJobStatus === 'set';
    const isCurrentJobStatus = userJob.userJob.currentJobStatus === 'set';
    const yetPrevPublicationStatus = !(
      userJob.userJob.prevPublicationStatus === 'published'
    );
    const yetCurrentPublicationStatus = !(
      userJob.userJob.currentPublicationStatus === 'published'
    );

    if (
      !(isPrevJobStatus && yetPrevPublicationStatus) &&
      !(
        isCurrentJobStatus &&
        yetCurrentPublicationStatus &&
        isJobEnd(
          userJob.businessDate,
          userJob.calendarStatus.currentAnnoucementDay
        )
      )
    ) {
      navigate('/job');
    }

    const publicationStatus = isPrev
      ? userJob.userJob?.prevPublicationStatus
      : userJob.userJob?.currentPublicationStatus;

    if (publicationStatus === 'published') {
      navigate('/job');
    }

    if (userJob.userJob?.totalYield == null) {
      return;
    }

    if (userJob.userJob.totalYield > 0) {
      setImage(plusImage);
    } else if (userJob.userJob.totalYield < 0) {
      setImage(minusImage);
    } else {
      setImage(flatImage);
    }
  }, [userJob]);

  return (
    <div className={classes.container}>
      {show === 'normal' && (
        <div className={classes.inner} onClick={click}>
          <div className={classes.title}>けっかはっぴょう</div>
          <div className={classes.subtitle}>CLEAR!</div>
          <div className={classes.result}>
            <ResultCard
              userJob={isPrev ? userJob.prevUserJob : userJob.userJob}
            />
          </div>
          <div className={classes.logo}>
            <img src={image} alt='teacher' />
          </div>
        </div>
      )}
      {show === 'skillbadge' && (
        <div className={classes.inner} onClick={() => toPage('job')}>
          <SkillBadge userRecord={postUserRecordReducer.state.data?.[0]} />
        </div>
      )}
      {show === 'cancel' && <CancelResult userJob={userJob} isPrev={isPrev} />}
    </div>
  );
};

export default JobEnd;
