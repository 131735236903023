import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FetchUserResponse } from '../../hooks/reducer/UserReducer';
import SimpleButton from '../../atoms/simple-button/SimpleButton';
import classes from './BadgeResult.module.scss';
import {
  BadgeResultCardProps,
  badgeMap,
} from '../../molecules/badge-result/BadgeResultCard';
import disableBadge from '../../static/images/disable-badge.png';
import { getBackColorByInstrumentType } from '../../common/utility/style.util';
import { InstrumentType, typeMapper } from '../../hooks/reducer/UserJobReducer';
import cancelIcon from '../../static/images/left-vector-green.svg';
import Badge from '../../atoms/badge/Badge';

const BadgeResult = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [badgeResult, setBadgeResult] = useState<BadgeResultCardProps>();
  const [totalSuccessCount, setTotalSuccessCount] = useState<number>(0);
  const [badgeList, setBadgeList] = useState<string[]>();

  const isDisabled = (index: number) => {
    if (
      badgeResult == null ||
      badgeResult.status == null ||
      badgeResult.totalSuccessCount == null
    ) {
      return true;
    }

    if (
      badgeResult?.status[index].clearCount > badgeResult?.totalSuccessCount
    ) {
      return true;
    }

    return false;
  };

  const getBadgeColor = (index: number) => {
    // 本来はindexではなく、規定のカウント
    if (isDisabled(index)) {
      return { background: '#4A5568' };
    } else {
      if (badgeResult == null) {
        return;
      }
      return getBackColorByInstrumentType(badgeResult.instrumentType);
    }
  };

  useEffect(() => {
    setBadgeResult(location.state.badgeResult);
  }, [location.state.badgeResult]);

  useEffect(() => {
    if (badgeResult?.totalSuccessCount == null) {
      return;
    }
    setTotalSuccessCount(badgeResult.totalSuccessCount);
    setBadgeList(badgeMap[badgeResult.instrumentType]);
  }, [badgeResult]);
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.left}>
          <SimpleButton
            color='transparentGreen'
            label={'もどる'}
            onClick={() => navigate(-1)}
            leftIcon={cancelIcon}
            fontSize='20rem'
            minHeight='30rem'
            minWidth='90rem'
            maxWidth='110rem'
          />
        </div>
      </div>
      <div className={classes.statusContainer}>
        <div className={classes.skillName}>{badgeResult?.skillName}</div>
        <div className={classes.statusName}>{badgeResult?.statusName}</div>
        <div className={classes.badgeImage}>
          {badgeList != null && badgeResult?.totalStatusCount != null && (
            <Badge
              height='200rem'
              width='200rem'
              boxShadow='0 0 16rem 0'
              instrumentType={badgeResult.instrumentType}
              badge={badgeList[badgeResult?.totalStatusCount - 1]}
            />
          )}
        </div>
        <div className={classes.statusMessageContainer}>
          <div className={classes.statusMessage}>
            {totalSuccessCount > 0 ? (
              <>
                {badgeResult && typeMapper[badgeResult.instrumentType]}で合計
                {totalSuccessCount}
                回ミラをふやす
              </>
            ) : (
              'まだミラをふやせていないよ'
            )}
          </div>
        </div>
        <div className={classes.statusArea}>
          <div className={classes.statusCountContainer}>
            {badgeResult != null &&
              badgeList?.map((badge, index) => (
                <div className={classes.smallBadgeContainer}>
                  <div className={classes.badgeBlock}>
                    <Badge
                      height='65rem'
                      width='65rem'
                      boxShadow='0 0 16rem 0'
                      instrumentType={badgeResult.instrumentType}
                      disabled={isDisabled(index)}
                      badge={badge}
                    />
                  </div>
                  <div
                    className={classes.badgeCountContainer}
                    style={getBadgeColor(index)}
                  >
                    <div className={classes.badgeCount}>
                      {badgeResult?.status?.[index]?.clearCount}
                    </div>
                    <div className={classes.badgeCountSmall}>回</div>
                  </div>
                </div>
              ))}
            {/*
            {badgeResult?.status &&
              badgeResult.status.map((status, index) => (
                <div className={classes.smallBadgeContainer}>
                  {totalSuccessCount >= status.clearCount ? (
                    <>
                      <img
                        src={badgeMap[badgeResult?.instrumentType][index]}
                        className={classes.smallBadgeImage}
                      />
 */}
            {/* 2回同じ書き方をしてしまっている */}
            {/*
                      <div
                        className={classes.badgeCountContainer}
                        style={getBadgeColor(status.clearCount)}
                      >
                        <div className={classes.badgeCount}>
                          {status.clearCount}
                        </div>
                        <div className={classes.badgeCountSmall}>回</div>
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        src={disableBadge}
                        alt={disableBadge}
                        className={classes.smallBadgeImage}
                      />
                      <div
                        className={classes.badgeCountContainer}
                        style={getBadgeColor(status.clearCount)}
                      >
                        <div className={classes.badgeCount}>
                          {status.clearCount}
                        </div>
                        <div className={classes.badgeCountSmall}>回</div>
                      </div>
                    </>
                  )}
                </div>
              ))}
                  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BadgeResult;
