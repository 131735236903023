import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isFirstJobStart } from '../../common/utility/business.util';
import {
  createJobStartKey,
  saveLocalStorage,
} from '../../common/utility/localstorage.util';
import { useJob } from '../../hooks/custom/useJob';
import GoalSetting from '../../molecules/goal-setting/GoalSetting';
import teacherImg from '../../static/images/teacher-flat.png';
import classes from './JobStart.module.scss';

const JobStart = () => {
  const navigate = useNavigate();
  const userJob = useJob();

  // 不正に遷移してきた場合ジョブ画面に返す
  useEffect(() => {
    if (
      userJob.jobStatus == null ||
      userJob.calendarStatus == null ||
      userJob.businessDate == null
    ) {
      return;
    }

    if (
      !isFirstJobStart(
        userJob.jobStatus,
        userJob.calendarStatus.currentInvestmentTerm,
        userJob.businessDate,
        userJob.calendarStatus.currentAnnoucementDay
      )
    ) {
      navigate('/job');
    }
  }, [userJob]);

  const confirm = () => {
    if (userJob.calendarStatus == null) {
      return;
    }

    const key = createJobStartKey(
      userJob.calendarStatus?.currentInvestmentTerm
    );
    saveLocalStorage(key, true);
    navigate('/job');
  };
  return (
    <div className={classes.container} onClick={confirm}>
      <div className={classes.title}>うんよう スタート</div>
      <div className={classes.subtitle}>START!</div>
      <div className={classes.goal}>
        <GoalSetting summaryOnly={true} />
      </div>
      <div className={classes.logo}>
        <img src={teacherImg} alt='job-start-teacher' />
      </div>
    </div>
  );
};

export default JobStart;
