import { useContext, useEffect, useState } from 'react';
import { LoginContext, useLogin } from '../../hooks/reducer/LoginReducer';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from './SsoLogin.module.scss';
import { useSsoLogin } from '../../hooks/reducer/SsoLoginReducer';
import axios from 'axios';

const SsoLogin = () => {
  const { ssoLoginState, ssoLogin } = useSsoLogin();
  const query = new URLSearchParams(useLocation().search);
  const payload = query.get('payload');
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (ssoLogin == null) {
      return;
    }

    if (payload == null) {
      setIsError(true);
      return;
    }

    ssoLogin({ payload });
  }, []);

  useEffect(() => {
    if (ssoLoginState == null) {
      return;
    }

    if (ssoLoginState.status === 'success') {
      navigate('/job');
    }

    if (ssoLoginState.status === 'error') {
      setIsError(true);
    }
  }, [ssoLoginState]);

  return (
    <>
      {isError && (
        <div className={classes.container}>
          <div className={classes.title}>ログインでエラーが発生しました。</div>
          <div className={classes.message}>
            みらいいパークからログインしなおしてください。
          </div>
        </div>
      )}
    </>
  );
};

export default SsoLogin;
