import classes from './RiskReturnProfile.module.scss';

type Props = {
  profile: 'HIGH' | 'MIDDLE' | 'LOW' | 'NONE';
  type: 'RISK' | 'RETURN';
};

const RiskReturnProfile = (props: Props) => {
  const typeMap = {
    RISK: {
      HIGH: { class: classes.red, text: 'ハイリスク' },
      MIDDLE: { class: classes.yellow, text: 'ミドルリスク' },
      LOW: { class: classes.green, text: 'ローリスク' },
      NONE: { class: classes.gray, text: 'ノーリスク'},
    },
    RETURN: {
      HIGH: { class: classes.red, text: 'ハイリターン' },
      MIDDLE: { class: classes.yellow, text: 'ミドルリターン' },
      LOW: { class: classes.green, text: 'ローリターン' },
      NONE: { class: classes.gray, text: 'ノーリターン'},
    },
  };

  const content = typeMap[props.type][props.profile];

  return (
    <div className={classes.container}>
      <div className={`${classes.label} ${content.class}`}>{content.text}</div>
    </div>
  );
};

export default RiskReturnProfile;
