import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useJob } from '../../hooks/custom/useJob';
import { FetchStockContext } from '../../hooks/reducer/StockReducer';
import StockCard from '../../molecules/stock-card/StockCard';
import JobHeader from '../../organisms/job-header/JobHeader';
import classes from './Stock.module.scss';

type DisplayUserJob = {
  totalInitialJobAllocatedPoint: number;
  totalCurrentPoint: number;
  assignableMira: number;
  maxBuyableShares: number;
  buyableShares: number;
  purchasedInstrumentIdList: string[];
  canBuyable: boolean;
};

const Stock = () => {
  const { state, refetch } = useContext(FetchStockContext);
  const [investmentsCount, setInvestmentsCount] = useState(0);
  const { userJob } = useJob();
  const navigate = useNavigate();
  useEffect(() => {
    if (refetch == null) {
      return;
    }
    refetch({ instrumentId: 'ALL' });
  }, [refetch]);

  const openDetail = (instrumentId: string) => {
    navigate(`/stock-detail?instrumentId=${instrumentId}`);
  };

  useEffect(() => {
    if (userJob == null) {
      return;
    }

    setInvestmentsCount(userJob.investments.length);
  }, [userJob]);

  return (
    <div className={classes.container}>
      <JobHeader title='かぶを買う' previousUrl='/job' />
      <div className={classes.contents}>
        <div className={classes.cardArea}>
          {state.data?.map((d) => {
            return (
              <div
                key={`${d.instrumentType}-${d.instrumentId}`}
                className={classes.card}
              >
                <StockCard
                  userJob={userJob}
                  stock={d}
                  onClick={() => {
                    openDetail(d.instrumentId);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.label}>このうんようで買える かぶ</div>
        <div className={classes.quantity}>{investmentsCount}/3</div>
      </div>
    </div>
  );
};

export default Stock;
