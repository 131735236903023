import { useJob } from '../../hooks/custom/useJob';
import classes from './InvestResult.module.scss';
import moneyImage from '../../static/images/money.svg';
import { memo, useContext, useEffect } from 'react';
import { addComma } from '../../common/utility/number.util';
import { useNavigate } from 'react-router-dom';
import SimpleButton from '../../atoms/simple-button/SimpleButton';
import leftVectorImage from '../../static/images/left-vector-white.svg';
import { InvestResultContext } from '../../hooks/reducer/InvestResultReducer';
import Header from '../../organisms/header/Header';
import checkIcon from '../../static/images/check-circle-black.svg';

export type InvestResultProps = {
  title: string;
  text: string;
  mira: number;
};

const InvestResult = () => {
  const { jobStatus, userJob } = useJob();
  const navigate = useNavigate();
  const { result } = useContext(InvestResultContext);

  const back = () => {
    navigate('/job');
  };

  const stock = () => {
    navigate('/stock');
  };

  const createTitle = () => {
    if (result == null) {
      return '';
    }

    if (result.type === 'STOCK') {
      return `「${result.instrumentName}」のかぶを買いました`;
    }

    if (result.type === 'BANK') {
      return `「${result.instrumentName}」に貯金しました`;
    }

    return `「${result.instrumentName}」を買いました`;
  };

  return (
    <>
      <div className={classes.container}>
        <Header title='ウォレット' />
        <div className={classes.innerContainer}>
          <div className={classes.title}>{createTitle()}</div>
          <div className={classes.contents}>
            {result?.type === 'STOCK' ? (
              <>
                <div className={classes.line}>
                  <div>のこりミラ</div>
                  <div className={classes.block}>
                    <img className={classes.icon} src={moneyImage} />
                    <span className={classes.point}>
                      {result.remainingPoint}
                    </span>
                  </div>
                </div>
                <div className={classes.line}>
                  <div>買える かぶ</div>
                  <div className={classes.block}>
                    <span>あと</span>
                    <span className={classes.remainingAmount}>
                      {result.remainingNumber}{' '}
                    </span>
                    <span>しゅるい</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={classes.line}>
                  <div>{result?.instrumentName}</div>
                  <div className={classes.block}>
                    <img className={classes.icon} src={moneyImage} />
                    <span className={classes.point}>{result?.investPoint}</span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={classes.buttonArea}>
            <div className={classes.button}>
              <SimpleButton
                fontSize='22rem'
                color='transparent'
                label='うんよう画面にもどる'
                minHeight='45rem'
                minWidth='295rem'
                onClick={() => back()}
              />
            </div>
            {result?.type === 'STOCK' && (
              <div className={classes.button}>
                <SimpleButton
                  fontSize='22rem'
                  minHeight='45rem'
                  minWidth='200rem'
                  leftIcon={checkIcon}
                  label='つづけて'
                  onClick={stock}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(InvestResult);
