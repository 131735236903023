export const toYYYYMDWithJapanese = (d?: Date | string) => {
  if (d == null) {
    return '';
  }
  const tmp = new Date(d);
  return `${tmp.getFullYear()}年${toMDWithJapanese(d)}`;
};

export const toMDWithJapanese = (d?: Date | string) => {
  if (d == null) {
    return '';
  }
  const tmp = new Date(d);
  return `${tmp.getMonth() + 1}月${tmp.getDate()}日`;
};

export const toYYYYMM = (d: Date | string) => {
  const tmp = new Date(d);
  return `${('0000' + tmp.getFullYear()).slice(-4)}${(
    '00' +
    (tmp.getMonth() + 1)
  ).slice(-2)}`;
};

export const toYYYYMMDD = (d: Date | string) => {
  const tmp = new Date(d);
  const yyyymm = toYYYYMM(tmp);

  return `${yyyymm}${('00' + tmp.getDate()).slice(-2)}`;
};

export const toDate = (d: Date | string) => {
  return new Date(d);
};

export const toTime = (d: Date | string) => {
  return toDate(d).getTime();
};
