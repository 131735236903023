import { useEffect, useState } from 'react';
import classes from './GoalSetting.module.scss';
import { Term } from '../investment-term/InvestmentTerm';
import moneyImg from '../../static/images/money.svg';
import { useJob } from '../../hooks/custom/useJob';
import { addComma } from '../../common/utility/number.util';
import { useNavigate } from 'react-router-dom';
import SimpleButton from '../../atoms/simple-button/SimpleButton';
import settingIcon from '../../static/images/setting-black.svg';
import resetIcon from '../../static/images/reset.svg';

type Props = {
  summaryOnly?: true;
};

// 設定期間中に表示されるゴール
// 運用期間中とあまりにも違うのでコンポーネント分離
const GoalSetting = (props: Props) => {
  const { jobStatus, userJob } = useJob();

  const [quantitativeGoal, setQuantitativeGoal] = useState<string>();
  const [qualitativeGoal, setQualitativeGoal] = useState<string>();
  const [totalInitialJobAllocatedPoint, setTotalInitialJobAllocatedPoint] =
    useState<string>();
  const [buttonLabel, setButtonLabel] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    if (jobStatus === 'SELECTABLE') {
      setQuantitativeGoal('???');
      setQualitativeGoal('??????');
      setTotalInitialJobAllocatedPoint('???');
      setButtonLabel('うんようをつくる');
    } else if (jobStatus === 'NOTHING') {
      setQuantitativeGoal('-');
      setQualitativeGoal('設定していないよ');
      setTotalInitialJobAllocatedPoint('0');
    } else {
      setQuantitativeGoal(`${userJob?.quantitativeGoal}%`);
      setQualitativeGoal(userJob?.qualitativeGoal);
      setTotalInitialJobAllocatedPoint(
        addComma(userJob?.totalInitialJobAllocatedPoint)
      );
      setButtonLabel('せっていを変える');
    }
  }, [jobStatus, userJob]);

  const setting = () => {
    navigate('/job-setting');
  };

  const reset = () => {
    navigate('/job-reset');
  };

  return (
    <div className={classes.container}>
      <div className={`${classes.block} ${classes.blockMira}`}>
        <div className={classes.label}>うんよう</div>
        <div className={classes.content}>
          <img className={classes.icon} src={moneyImg} alt='goal-money-image' />
          <span>{totalInitialJobAllocatedPoint}</span>
        </div>
      </div>
      <div className={`${classes.block} ${classes.blockQuantitativeGoal}`}>
        <div className={classes.label}>ていりょう目標</div>
        <div className={classes.content}>{quantitativeGoal}</div>
      </div>
      <div className={`${classes.block} ${classes.blockQualitativeGoal}`}>
        <div className={classes.label}>ていせい目標</div>
        <div className={classes.goalContent}>{qualitativeGoal}</div>
      </div>
      {!props.summaryOnly && (
        <div className={classes.buttonContent}>
          <div className={classes.button}>
            <SimpleButton
              color='white'
              label='せってい'
              leftIcon={settingIcon}
              minWidth='88rem'
              minHeight='24rem'
              onClick={() => setting()}
            />
          </div>
          {userJob != null && (
            <div className={classes.button}>
              <SimpleButton
                color='red'
                label='リセット'
                minWidth='88rem'
                minHeight='24rem'
                leftIcon={resetIcon}
                onClick={() => reset()}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GoalSetting;
