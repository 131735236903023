import SimpleButton from '../../atoms/simple-button/SimpleButton';
import { FetchEventHistoryResponse } from '../../hooks/reducer/EventHistoryReducer';
import checkIcon from '../../static/images/check-circle-black.svg';
import classes from './News.module.scss';

export type DisplayEventHistory = FetchEventHistoryResponse & {
  has: boolean;
};

type Props = {
  data: FetchEventHistoryResponse;
  has?: boolean;
  onClick?: () => void;
  largeStyle?: true;
};

const News = (props: Props) => {
  const getLabelStyle = () => {
    if (props.largeStyle) {
      return { fontSize: '16rem' };
    }
    return {};
  };

  const getTextStyle = () => {
    if (props.largeStyle) {
      return { fontSize: '20rem' };
    }

    return {};
  };
  return (
    <div className={classes.card}>
      <div className={classes.line}>
        <span className={classes.eventDate} style={getLabelStyle()}>
          {props.data.eventDate}
        </span>
        {props.has && <span className={classes.has}>もっている</span>}
      </div>
      <div className={classes.sentence} style={getTextStyle()}>
        {props.data.eventProfile}
        {props.onClick != null && (
          <div className={classes.button}>
            <SimpleButton
              label='OK'
              fontSize='20rem'
              minWidth='85rem'
              minHeight='35rem'
              leftIcon={checkIcon}
              color='whiteNoShadow'
              onClick={props.onClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default News;
