import { useJob } from '../../hooks/custom/useJob';
import classes from './Job.module.scss';
// icon受領後に差し替え
import InvestmentTerm from '../../molecules/investment-term/InvestmentTerm';
import GoalDoing from '../../molecules/goal-doing/GoalDoing';
import GoalSetting from '../../molecules/goal-setting/GoalSetting';
import InvestmentDoing from '../investment-doing/InvestmentDoing';
import InvestmentSetting from '../investment-setting/InvestmentSetting';

const Job = () => {
  const { isSelectableTerm } = useJob();
  return (
    <>
      <div className={classes.container}>
        <div className={classes.investmentTerm}>
          <InvestmentTerm />
        </div>
        <div className={classes.goal}>
          {isSelectableTerm ? <GoalSetting /> : <GoalDoing />}
        </div>
        <div
          className={
            isSelectableTerm
              ? classes.investmentSetting
              : classes.investmentDoing
          }
        >
          {isSelectableTerm ? <InvestmentSetting /> : <InvestmentDoing />}
        </div>
      </div>
    </>
  );
};

export default Job;
