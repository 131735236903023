import axios from 'axios';
import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useReducer } from 'react';
import { ErrorHandlerContext } from '../../organisms/error-handler/ErrorHandler';
import {
  ChangeLoadingState,
  LoadingContext,
} from '../../organisms/loading/Loading';
import { Action, State, createInitialState } from './state';

type FetchBusinessCalendarResponse = {
  businessDate: string;
};

type ContextType = {
  state: State<FetchBusinessCalendarResponse>;
  dispatch?: React.Dispatch<Action<FetchBusinessCalendarResponse>>;
  refetch?: () => State<FetchBusinessCalendarResponse>;
};

// レデューサー
const reducer = (
  state: State<FetchBusinessCalendarResponse>,
  action: Action<FetchBusinessCalendarResponse>
): State<FetchBusinessCalendarResponse> => {
  switch (action.type) {
    case 'processing':
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case 'success':
      return {
        data: action.response,
        error: null,
        status: action.type,
      };
    case 'error':
      return {
        data: null,
        error: action.error,
        status: action.type,
      };
    default:
      return state;
  }
};

export const initialState = createInitialState<FetchBusinessCalendarResponse>();

// コンテキストの作成
export const FetchBusinessCalendarContext = createContext<ContextType>({
  state: initialState,
});

let calling = false;

function fetchBusinessCalendar(
  context: ContextType,
  changeLoadingState: ChangeLoadingState,
  handleError: (hasError: boolean, error?: unknown) => void
) {
  const url = 'api/business-calendars';

  if (context.dispatch == null) {
    return context.state;
  }

  if (calling) {
    return context.state;
  }

  calling = true;
  changeLoadingState(true);
  context.dispatch({ type: 'processing', response: null, error: null });

  (async () => {
    try {
      const response = await axios.get(url);
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: 'success',
        response: response.data.response,
        error: null,
      });
      calling = false;
      changeLoadingState(false);
    } catch (error) {
      handleError(true, error);
      calling = false;
      changeLoadingState(false);
    }
  })();
  return context.state;
}

export function FetchBusinessCalendarProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { changeLoadingState } = useContext(LoadingContext);
  const { handleError } = useContext(ErrorHandlerContext);

  const refetch = useCallback(
    () =>
      fetchBusinessCalendar(
        { state, dispatch },
        changeLoadingState,
        handleError
      ),
    []
  );

  return (
    <FetchBusinessCalendarContext.Provider value={{ state, dispatch, refetch }}>
      {children}
    </FetchBusinessCalendarContext.Provider>
  );
}
