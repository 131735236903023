import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { CSSProperties, memo, useContext, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useLocation, useNavigate } from 'react-router-dom';
import Label from '../../atoms/label/Label';
import SimpleButton from '../../atoms/simple-button/SimpleButton';
import { toMDWithJapanese } from '../../common/utility/date.util';
import { addComma, formatSign } from '../../common/utility/number.util';
import { getColorByPoint } from '../../common/utility/style.util';
import { useJob } from '../../hooks/custom/useJob';
import { FetchCalendarStatusContext } from '../../hooks/reducer/CalendarStatusReducer';
import { FetchUserJobChartContext } from '../../hooks/reducer/UserJobChartReducer';
import {
  FetchUserJobContext,
  FetchUserJobResponse,
  typeMapper,
} from '../../hooks/reducer/UserJobReducer';
import { GetUserRecordResponse } from '../../hooks/reducer/UserRecordReducer';
import { createKey } from '../../hooks/reducer/state';
import { Display } from '../../molecules/investment-term/InvestmentTerm';
import moneyIcon from '../../static/images/money.svg';
import classes from './RecordChart.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const RecordChart = memo(() => {
  const urlParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const record: GetUserRecordResponse = useLocation().state;
  const userJobContext = useContext(FetchUserJobContext);
  const userJobChartContext = useContext(FetchUserJobChartContext);
  const calendarStatusContext = useContext(FetchCalendarStatusContext);
  const userJob = useJob();

  const [x, setX] = useState<string[]>();
  const [yMax, setYMax] = useState<number>();
  const [yMin, setYMin] = useState<number>();
  const [term, setTerm] = useState<Date[]>();
  const [allocatedPoint, setAllocatedPoint] = useState<number[]>([]);
  const [currentPoint, setCurrentPoint] = useState<(number | null)[]>([]);
  const [newsPoint, setNewsPoint] = useState<(number | null)[]>([]);
  const [options, setOptions] = useState<ChartOptions<'line'>>();
  const [data, setData] = useState<ChartData<'line'>>({ datasets: [] });
  const [display, setDisplay] = useState<Display>();
  const [currentUserJob, setCurrentUserJob] = useState<FetchUserJobResponse>();

  const [investmentReturnSummary, setInvestmentReturnSummary] = useState<{
    point: number;
    style: CSSProperties;
  }>();
  const [investmentYieldSummary, setInvestmentYieldSummary] = useState<{
    point: number;
    style: CSSProperties;
  }>();
  const [currentPointSummary, setCurrentPointSummary] = useState<{
    point: number;
    style: CSSProperties;
  }>();

  const [selectIndex, setSelectIndex] = useState<number>(
    Number(urlParams.get('index'))
  );

  useEffect(() => {
    if (calendarStatusContext.state.data == null || userJob == null) {
      return;
    }

    const from = new Date(record.investmentTermFrom);
    //from.setDate(from.getDate() - 1);
    from.setDate(from.getDate());

    // 最終日まで履歴を出すため変更
    //const to = new Date(calendarStatus.currentInvestmentTermTo);
    const to = new Date(record.annoucementDay);
    const term: Date[] = [];

    while (from < to) {
      term.push(new Date(from));
      from.setDate(from.getDate() + 1);
    }

    // 開催期間の配列
    setTerm(term);
    setDisplay({
      title: record.investmentTermName,
      from: record.investmentTermFrom,
      to: record.investmentTermTo,
      announceDay: record.annoucementDay,
    });

    userJobChartContext.refetch?.({
      investmentTerm: record.investmentTerm,
    });

    userJobContext.refetch?.({
      investmentTerm: record.investmentTerm,
      withInvestments: true,
    });
  }, [userJob]);

  useEffect(() => {
    if (term == null || userJobChartContext.state.data == null) {
      return;
    }

    // 開催期間の配列と、ポイントのマッピング
    const currentPointList = term.map((d) => {
      const point = userJobChartContext.state.data?.[0].jobHistory;
      const match = point?.find(
        (p) => new Date(p.pricingDate).getTime() === d.getTime()
      );
      if (match != null) {
        return match.aggregatePoint;
      }
      return null;
    });

    // 初期投資ポイントを開催期間中の配列とlengthを合わせる
    const initialAllocatedPoint =
      userJobChartContext.state.data?.[0]
        ?.totalInitialInvestmentAllocatedPoint ?? 0;
    const allocatedPointList = term.map((d) => {
      return initialAllocatedPoint;
    });

    // y軸のスケール設定
    // 初期ポイントと現在ポイントの最大値を1.3倍してY軸のスケールにする
    const yMax =
      currentPointList.reduce((prev, cur) => {
        if (cur == null) {
          return prev;
        }

        if (prev == null) {
          return cur;
        }

        return prev > cur ? prev : cur;
      }, initialAllocatedPoint) ?? 0;

    const yMin =
      currentPointList.reduce((prev, cur) => {
        if (cur == null) {
          return prev;
        }

        if (prev == null) {
          return cur;
        }

        return prev > cur ? cur : prev;
      }, initialAllocatedPoint) ?? 0;

    const diff = yMax * 1.0005 - yMax;
    const yScaleMax = Math.ceil((yMax + diff) / 100) * 100;
    const yScaleMin = Math.floor((yMin - diff) / 100) * 100;

    // クリックされたときにインデックスからNewsを取得して表示するために用意

    const currentPoint = userJobChartContext.state.data[0]?.totalCurrentPoint;
    setCurrentPointSummary({
      point: currentPoint,
      style: getColorByPoint(currentPoint - initialAllocatedPoint),
    });

    const investmentReturn = userJobChartContext.state.data[0]?.totalReturn;
    setInvestmentReturnSummary({
      point: investmentReturn,
      style: getColorByPoint(investmentReturn),
    });

    const investmentYield = userJobChartContext.state.data[0]?.totalYield;
    setInvestmentYieldSummary({
      point: investmentYield,
      style: getColorByPoint(investmentYield),
    });

    setX(term.map((d) => d.getDate().toString()));
    setYMax(yScaleMax);
    setYMin(yScaleMin);
    setAllocatedPoint(allocatedPointList);
    setCurrentPoint(currentPointList);
  }, [term, userJobChartContext.state]);

  useEffect(() => {
    if (
      x == null ||
      yMax == null ||
      yMin == null ||
      allocatedPoint == null ||
      currentPoint == null ||
      newsPoint == null
    ) {
      return;
    }
    const op: ChartOptions<'line'> = {
      interaction: {
        mode: 'point',
        intersect: false,
      },
      responsive: true,
      scales: {
        x: {
          ticks: {
            color: 'white',
            font: {
              size: 20,
              family: 'M PLUS Rounded 1c',
            },
          },

          display: true,
          grid: {
            color: '#58C2C7',
          },
        },
        y: {
          display: true,
          max: yMax,
          min: yMin,
          ticks: {
            color: 'white',
            font: {
              size: 20,
              family: 'M PLUS Rounded 1c',
            },
          },
          grid: {
            display: false,
          },
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    };

    const dt: ChartData<'line'> = {
      labels: x, // x軸のラベルの配列
      datasets: [
        {
          data: newsPoint,
          pointBackgroundColor: '#58C2C7',
          pointRadius: 12,
          pointHoverRadius: 20,
          label: 'news',
        },
        {
          data: currentPoint,
          backgroundColor: currentPointSummary?.style.color,
          pointHoverRadius: 0,
          borderWidth: 8,
          borderColor: currentPointSummary?.style.color, // グラフの棒の色
        },
        {
          label: '買ったとき', // 凡例
          data: allocatedPoint, // データの配列(labelsと要素数同じ)
          backgroundColor: '#00F5A0', // グラフの棒の色
          borderColor: '#00F5A0', // グラフの棒の色
          pointHoverRadius: 0,
          borderDash: [8, 8],
        },
      ],
    };
    setOptions(op);
    setData(dt);
  }, [x, yMax, yMin, allocatedPoint, currentPoint, newsPoint]);

  useEffect(() => {
    const key = createKey({
      investmentTerm: record.investmentTerm,
      withInvestments: true,
    });
    if (userJobContext.state.data?.[key]?.[0] == null) {
      return;
    }
    setCurrentUserJob(userJobContext.state.data?.[key]?.[0]);
  }, [userJobContext.state.data]);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.left}>
            <SimpleButton
              color='transparentGreen'
              label={'もどる'}
              fontSize='20rem'
              onClick={() => navigate(-1)}
            />
            <div className={classes.name}>{record.investmentTermName}</div>
            <div className={classes.instrumentType}>
              {typeMapper[record.instrumentType]}
            </div>
          </div>
          <div className={classes.right}>
            <img
              className={classes.icon}
              src={moneyIcon}
              alt='chart-money-icon'
            />
            <div>
              <div className={classes.above}>
                <span
                  className={classes.currentPoint}
                  style={currentPointSummary?.style}
                >
                  {addComma(currentPointSummary?.point)}
                </span>
              </div>
              <div className={classes.below}>
                <span
                  className={classes.subPoint}
                  style={investmentReturnSummary?.style}
                >
                  {formatSign(investmentReturnSummary?.point)}
                </span>
                <span
                  className={classes.subPoint}
                  style={investmentYieldSummary?.style}
                >
                  {formatSign(investmentYieldSummary?.point, true)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.contents}>
          <div className={classes.investmentTerm}>
            {/* TODO：<InvestmentTerm />で共通化したかったが、一旦個別に実装することにした */}
            <div className={classes.investmentTermContainer}>
              <span className={classes.title}>{display?.title}</span>
              <span className={classes.label}>
                <Label fontSizeRem={12}>うんよう</Label>
              </span>
              <span>
                {toMDWithJapanese(display?.from)} ～{' '}
                {toMDWithJapanese(display?.to)}
              </span>
              <span className={classes.label}>
                <Label fontSizeRem={12} color='yellow'>
                  けっか
                </Label>
              </span>
              <span>{toMDWithJapanese(display?.announceDay)}</span>
              {allocatedPoint[0] != null && (
                <>
                  <span className={classes.label}>
                    <Label fontSizeRem={12} color='red'>
                      買ったとき
                    </Label>
                  </span>
                  <span className={classes.buyRegend}></span>
                  <span>{addComma(allocatedPoint[0])}</span>
                </>
              )}
            </div>
          </div>
          <div className={classes.investments}>
            {currentUserJob?.investments.map((investment, index) => {
              return (
                <div key={index} className={classes.investment}>
                  <div className={classes.investmentTitle}>
                    {investment.instrumentName}
                  </div>
                  <div>
                    <div className={classes.investmentAbobe}>
                      <img
                        className={classes.miniIcon}
                        src={moneyIcon}
                        alt='chart-money-icon'
                      />
                      <span
                        className={classes.currentPoint}
                        style={getColorByPoint(
                          investment?.currentPoint -
                            investment?.initialAllocatedPoint
                        )}
                      >
                        {addComma(investment?.currentPoint)}
                      </span>
                    </div>
                    <div className={classes.investmentBelow}>
                      <span
                        className={classes.subPoint}
                        style={getColorByPoint(investment?.investmentReturn)}
                      >
                        {formatSign(investment?.investmentReturn)}
                      </span>
                      <span
                        className={classes.subPoint}
                        style={getColorByPoint(investment?.investmentYield)}
                      >
                        {formatSign(investment?.investmentYield, true)}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className={classes.chartContainer}>
            <div className={classes.chart}>
              <Line
                options={options}
                data={data}
                style={{ height: '100%', width: '100%' }}
              />
              ;
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default RecordChart;
