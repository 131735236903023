import { useContext, useEffect } from 'react';
import DisplayPoint from '../../atoms/display-point/DisplayPoint';
import classes from './Header.module.scss';
import { FetchUserContext } from '../../hooks/reducer/UserReducer';
import Help from '../../molecules/help/Help';
import { useBusinessDate } from '../../hooks/custom/useBusinessDate';
import { toYYYYMDWithJapanese } from '../../common/utility/date.util';
// icon受領後に差し替え
import iconImages from '../../../../client/src/static/images/icon.svg';
import bubbleImage from '../../static/images/bubble.svg';
import parkButton from '../../static/images/button-miraiipark.svg';
import { useJob } from '../../hooks/custom/useJob';
import { useNavigate } from 'react-router-dom';

// TODO 最後まとめて消す
type HeaderProps = {
  title: string;
};

const Header = (props: HeaderProps) => {
  const { state, refetch } = useContext(FetchUserContext);
  const businessDate = useBusinessDate() || '';
  const formattedBusinessDate = toYYYYMDWithJapanese(businessDate);
  const useJobState = useJob();

  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, []);

  const backToPark = () => {
    const url = useJobState.user?.parkUrl;

    if (url == null) {
      return;
    }

    window.location.replace(url);
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>{props.title}</div>
      <img
        className={classes.parkButton}
        src={parkButton}
        alt={parkButton}
        onClick={backToPark}
      />
      <img className={classes.icon} src={iconImages} alt='icon'></img>
      <div className={classes.userName}>{state?.data?.[0].userName}</div>
      <div className={classes.miraBlock}>
        <DisplayPoint withLabel={true} point={state?.data?.[0].currentPoint} />
      </div>
    </div>
  );
};

export default Header;
