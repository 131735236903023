import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleButton from '../../atoms/simple-button/SimpleButton';
import TextForm, { TextFormResult } from '../../atoms/text-form/TextForm';
import { useJob } from '../../hooks/custom/useJob';
import { InvestResultContext } from '../../hooks/reducer/InvestResultReducer';
import { BuyStockContext } from '../../hooks/reducer/StockReducer';
import { InstrumentType, Investment } from '../../hooks/reducer/UserJobReducer';
import checkIcon from '../../static/images/check-circle-black.svg';
import resetIcon from '../../static/images/reset.svg';
import classes from './InvestmentFooter.module.scss';

type Props = {
  title: string;
  type: InstrumentType;
  instrumentId?: string;
  instrumentName?: string;
  passedAllocatedPoint?: string;
  errorMessage?: string;
};

export type ActionType = 'BUY' | 'RESET' | 'UPDATE';
const InvestmentFooter = (props: Props) => {
  const [allocatedPoint, setAllocatedPoint] = useState(
    props.passedAllocatedPoint || '0'
  );
  const [buyDisabled, setBuyDisabled] = useState(false);
  const [actionType, setActionType] = useState<ActionType>();
  const { buy, state, dispatch, update, reset } = useContext(BuyStockContext);
  const { userJob, user } = useJob();
  const resultContext = useContext(InvestResultContext);
  const navigate = useNavigate();

  const validate = () => {
    if (buy == null || userJob == null) {
      return 'ERROR';
    }

    if (allocatedPoint == null || allocatedPoint === '') {
      return 'VALUE_IS_NULL';
    }

    if (allocatedPoint === '0') {
      return 'VALUE_0_IS_INVALID';
    }

    if (Number(allocatedPoint) < 0) {
      return 'MINUS_VALUE_IS_INVALID';
    }

    const remainingPoint =
      userJob.totalInitialJobAllocatedPoint -
      userJob.totalInitialInvestmentAllocatedPoint;

    if (Number(allocatedPoint) > remainingPoint) {
      return 'MIRA_IS_SHORTAGE';
    }

    if (props.instrumentId == null) {
      return 'INSTRUMENT_IS_NOT_SELECTED';
    }

    // 株以外の場合は運用ミラ全額のみ設定可能
    if (
      props.type !== 'STOCK' &&
      Number(allocatedPoint) !== userJob.totalInitialJobAllocatedPoint
    ) {
      return 'FULL_MIRA_IS_ALLOWED';
    }

    return 'SUCCESS';
  };

  const [hasInvestment, setHasInvestment] = useState<Investment>();

  useEffect(() => {
    if (userJob == null || userJob.currentJobStatus === 'unset') {
      return;
    }

    setHasInvestment(
      userJob.investments.find(
        (iv) =>
          iv.instrumentId === props.instrumentId &&
          iv.instrumentType === props.type
      )
    );
  }, [userJob, props]);

  useEffect(() => {
    if (
      state == null ||
      dispatch == null ||
      resultContext.setResult == null ||
      userJob == null
    ) {
      return;
    }

    if (state.status === 'success') {
      dispatch({ type: 'processing', response: null, error: null });

      if (actionType === 'RESET') {
        navigate(0);
      } else {
        resultContext.setResult({
          type: props.type,
          instrumentName: props.instrumentName!,
          investPoint: Number(allocatedPoint),
          remainingPoint:
            (userJob.totalInitialJobAllocatedPoint ?? 0) -
            (userJob.totalInitialInvestmentAllocatedPoint ?? 0) -
            Number(allocatedPoint),
          remainingNumber: 3 - ((userJob?.investments.length ?? 0) + 1),
        });

        navigate('/invest-result');
      }
    }
  }, [state, dispatch, userJob]);

  useEffect(() => {
    setBuyDisabled(validate() !== 'SUCCESS' || hasInvestment != null);
  }, [allocatedPoint, userJob, props]);

  const invest = () => {
    if (validate() !== 'SUCCESS') {
      return;
    }

    if (hasInvestment != null) {
      update?.({
        instrumentType: props.type,
        instrumentId: props.instrumentId ?? '',
        allocatedPoint: Number(allocatedPoint),
      });
      setActionType('UPDATE');
    } else {
      if (userJob?.investments.length === 3) {
        navigate('/exceed-stock');
        return;
      }
      buy?.({
        instrumentType: props.type,
        instrumentId: props.instrumentId ?? '',
        allocatedPoint: Number(allocatedPoint),
      });
      setActionType('BUY');
    }
  };

  const onReset = () => {
    if (hasInvestment != null) {
      reset?.({
        instrumentType: hasInvestment.instrumentType,
        instrumentId: hasInvestment.instrumentId,
      });
      setActionType('RESET');
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>{props.title}</div>

      <div className={classes.form}>
        <div className={classes.textForm}>
          {validate() === 'MIRA_IS_SHORTAGE' && hasInvestment == null && (
            <div className={classes.balloon}>
              <span className={classes.error}>ミラが足りないよ！</span>
            </div>
          )}
          {validate() === 'MINUS_VALUE_IS_INVALID' && (
            <div className={classes.balloon}>
              <span className={classes.error}>マイナスはダメだよ！</span>
            </div>
          )}
          {hasInvestment != null && (
            <div className={classes.balloon}>
              <span className={classes.point}>
                {hasInvestment.initialAllocatedPoint}
              </span>
              <span>ミラあるよ</span>
            </div>
          )}
          {validate() === 'SUCCESS' && props.errorMessage && (
            <div className={classes.balloon}>
              <span className={classes.error}>{props.errorMessage}</span>
            </div>
          )}
          <TextForm
            name={'mira'}
            type='number'
            page='investment-footer'
            maxWidth='130rem'
            value={allocatedPoint}
            onChange={(args: TextFormResult) => {
              setAllocatedPoint(args.value);
            }}
          />
        </div>
        <span className={classes.mira}>ミラ</span>
      </div>
      <div className={classes.button}>
        <SimpleButton
          label='リセット'
          leftIcon={resetIcon}
          color='red'
          disabled={hasInvestment == null}
          minHeight='55rem'
          minWidth='135rem'
          maxWidth='135rem'
          fontSize='20rem'
          onClick={onReset}
        />
      </div>
      <div className={classes.button}>
        <SimpleButton
          label='買う'
          leftIcon={checkIcon}
          minHeight='55rem'
          minWidth='135rem'
          maxWidth='135rem'
          fontSize='20rem'
          disabled={buyDisabled}
          onClick={invest}
        />
      </div>
    </div>
  );
};

export default InvestmentFooter;
