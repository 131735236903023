import { useContext, useEffect, useState } from 'react';
import { useCalendarStatus } from '../../hooks/custom/useCalendarStatus';
import { useJob } from '../../hooks/custom/useJob';
import { FetchEventHistoryContext } from '../../hooks/reducer/EventHistoryReducer';
import News, { DisplayEventHistory } from '../../molecules/news/News';
import classes from './NewsList.module.scss';

const NewsList = () => {
  const { state, refetch } = useContext(FetchEventHistoryContext);
  const calendarStatus = useCalendarStatus();
  const { userJob } = useJob();

  const [data, setData] = useState<DisplayEventHistory[]>();

  useEffect(() => {
    if (refetch == null || calendarStatus == null) {
      return;
    }

    refetch({
      instrumentType: 'ALL',
      investmentTerm: calendarStatus.currentInvestmentTerm,
    });
  }, [calendarStatus]);

  useEffect(() => {
    if (state.data == null || userJob == null) {
      return;
    }

    const instrumentIds = userJob.investments.map((i) => i.instrumentId);
    const data = state.data.map((d) => {
      return {
        ...d,
        has: instrumentIds.includes(d.instrumentId),
      };
    });

    setData(data);
  }, [state.data, userJob]);
  return (
    <div className={classes.container}>
      <div className={classes.title}>できごと</div>
      <div className={classes.cardContainer}>
        {data?.map((d) => {
          return (
            <div className={classes.card}>
              <News data={d} has={d.has} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NewsList;
