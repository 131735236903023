import axios from 'axios';
import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useReducer } from 'react';
import { ErrorHandlerContext } from '../../organisms/error-handler/ErrorHandler';
import {
  ChangeLoadingState,
  LoadingContext,
} from '../../organisms/loading/Loading';
import { InstrumentType } from './UserJobReducer';
import { Action, State, createInitialState } from './state';

export type PostUserRecordResponse = {
  userId: string;
  instrumentType: InstrumentType;
  statusId: string;
  statusName: string;
  totalSuccessStockCount?: number;
  totalSuccessTrustCount?: number;
  totalSuccessCryptoCount?: number;
};

type ContextType = {
  state: State<PostUserRecordResponse>;
  dispatch?: React.Dispatch<Action<PostUserRecordResponse>>;
  post?: (params: Params) => State<PostUserRecordResponse>;
};

// レデューサー
const reducer = (
  state: State<PostUserRecordResponse>,
  action: Action<PostUserRecordResponse>
): State<PostUserRecordResponse> => {
  switch (action.type) {
    case 'processing':
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case 'success':
      return {
        data: action.response,
        error: null,
        status: action.type,
      };
    case 'error':
      return {
        data: null,
        error: action.error,
        status: action.type,
      };
    default:
      return state;
  }
};

export const initialState = createInitialState<PostUserRecordResponse>();

// コンテキストの作成
export const PostUserRecordContext = createContext<ContextType>({
  state: initialState,
});

type Params = {
  investmentTerm: string;
  totalInitialAllocatedPoint: number;
  totalCurrentPoint: number;
  totalReturn: number;
  totalYield: number;
};

let calling = false;
function postUserRecord(
  params: Params,
  context: ContextType,
  changeLoadingState: ChangeLoadingState,
  handleError: (hasError: boolean, error?: unknown) => void
) {
  const url = 'api/user-records';

  if (context.dispatch == null) {
    return context.state;
  }

  if (calling) {
    return context.state;
  }

  calling = true;
  context.dispatch({ type: 'processing', response: null, error: null });
  changeLoadingState(true);

  (async () => {
    try {
      const response = await axios.post(url, params);
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: 'success',
        response: response.data.response,
        error: null,
      });
      calling = false;
      changeLoadingState(false);
    } catch (error) {
      handleError(true, error);
      calling = false;
      changeLoadingState(false);
    }
  })();
  return context.state;
}

export function PostUserRecordProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { changeLoadingState } = useContext(LoadingContext);
  const { handleError } = useContext(ErrorHandlerContext);

  const post = useCallback(
    (params: Params) =>
      postUserRecord(
        params,
        { state, dispatch },
        changeLoadingState,
        handleError
      ),
    []
  );

  return (
    <PostUserRecordContext.Provider value={{ state, dispatch, post }}>
      {children}
    </PostUserRecordContext.Provider>
  );
}
