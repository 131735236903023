import { ReactNode, createContext, useContext, useState } from 'react';
import classes from './Footer.module.scss';

export type SetFooterMesssages = (messages: string[]) => void;
export type SetFooterIcon = (icon: string) => void;

export const FooterContext = createContext<{
  messages: string[];
  icon?: string;
  setFooterMessages: SetFooterMesssages;
  setFooterIcon: SetFooterIcon;
}>({
  messages: [],
  setFooterMessages: (messages: string[]) => {},
  setFooterIcon: (icon: string) => {},
});

export const FooterProvider = ({ children }: { children: ReactNode }) => {
  const [messages, setMessages] = useState<string[]>([]);
  const [icon, setIcon] = useState<string>();

  const setFooterMessages = (messages: string[]) => {
    setMessages(messages);
  };

  const setFooterIcon = (icon: string) => {
    setIcon(icon);
  };

  return (
    <FooterContext.Provider
      value={{ messages, icon, setFooterMessages, setFooterIcon }}
    >
      {children}
    </FooterContext.Provider>
  );
};

const Footer = () => {
  const { messages, icon } = useContext(FooterContext);
  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        {icon != null && <img src={icon} alt='footer-logo' />}
      </div>
      <div className={classes.messageContainer}>
        {messages.map((message) => (
          <div>{message}</div>
        ))}
      </div>
    </div>
  );
};

export default Footer;
