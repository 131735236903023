export const addComma = (value?: number) => {
  return value != null ? value.toLocaleString() : '';
};

export const formatSign = (value?: number, withPercent?: true) => {
  const suffix = withPercent ? '%' : '';

  if (value == null) {
    return '';
  }

  if (value === 0) {
    return `±${value}${suffix}`;
  }

  if (value > 0) {
    return `+${value}${suffix}`;
  }

  return `${value}${suffix}`;
};
