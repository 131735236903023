import classes from './JobReset.module.scss';
import { useNavigate } from 'react-router-dom';
import icon from '../../static/images/teacher-point.png';
import SimpleButton from '../../atoms/simple-button/SimpleButton';
import resetIcon from '../../static/images/reset.svg';
import { ResetUserJobContext } from '../../hooks/reducer/UserJobReducer';
import { useContext, useEffect } from 'react';

const JobReset = () => {
  const navigate = useNavigate();
  const resetContext = useContext(ResetUserJobContext);
  useEffect(() => {
    if (resetContext.state?.status === 'success') {
      resetContext?.dispatch?.({
        type: 'processing',
        response: null,
        error: null,
      });
      navigate(-1);
    }
  }, [resetContext.state]);

  const resetJob = () => {
    resetContext.reset?.();
  };

  return (
    <div className={classes.container}>
      <div className={classes.inner}>
        <div className={`${classes.parts} ${classes.title}`}>
          うんようをリセットしますか？
        </div>
        <div className={`${classes.parts} ${classes.teacher}`}>
          <img src={icon} alt='job-reset-teacher' />
        </div>
        <div className={classes.buttonArea}>
          <div className={classes.button}>
            <SimpleButton
              label='キャンセル'
              color='transparent'
              minHeight='48rem'
              maxWidth='180rem'
              minWidth='180rem'
              fontSize='24rem'
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className={classes.button}>
            <SimpleButton
              label='リセット'
              leftIcon={resetIcon}
              color='red'
              minHeight='48rem'
              maxWidth='180rem'
              minWidth='180rem'
              fontSize='24rem'
              onClick={resetJob}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobReset;
