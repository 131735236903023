import { useEffect, useState } from 'react';
import Label from '../../atoms/label/Label';
import { toMDWithJapanese } from '../../common/utility/date.util';
import { addComma } from '../../common/utility/number.util';
import { useCalendarStatus } from '../../hooks/custom/useCalendarStatus';
import classes from './InvestmentTerm.module.scss';

export type Term = 'PREV';

type InvestmentTermProps = {
  term?: Term;
  investmentPoint?: number;
  display?: Display;
};

export type Display = {
  title: string;
  from: string;
  to: string;
  announceDay: string;
};
const InvestmentTerm = (props: InvestmentTermProps) => {
  const calendarStatus = useCalendarStatus();
  const [state, setState] = useState<Display>();

  useEffect(() => {
    if (calendarStatus == null) {
      return;
    }

    if (props.display != null) {
      setState(props.display);
      return;
    }

    if (props.term == null) {
      setState({
        title: calendarStatus.currentInvestmentTermName,
        from: calendarStatus.currentInvestmentTermFrom,
        to: calendarStatus.currentInvestmentTermTo,
        announceDay: calendarStatus.currentAnnoucementDay,
      });
    } else if (props.term === 'PREV') {
      setState({
        title: calendarStatus.prevInvestmentTermName,
        from: calendarStatus.prevInvestmentTermFrom,
        to: calendarStatus.prevInvestmentTermTo,
        announceDay: calendarStatus.prevAnnoucementDay,
      });
    }
  }, [calendarStatus, props.display]);

  return (
    <div className={classes.container}>
      <span className={classes.title}>{state?.title}</span>
      <span className={classes.label}>
        <Label fontSizeRem={12}>うんよう</Label>
      </span>
      <span>
        {toMDWithJapanese(state?.from)} ～ {toMDWithJapanese(state?.to)}
      </span>
      <span className={classes.label}>
        <Label fontSizeRem={12} color='yellow'>
          けっか
        </Label>
      </span>
      <span>{toMDWithJapanese(state?.announceDay)}</span>
      {props.investmentPoint != null && (
        <>
          <span className={classes.label}>
            <Label fontSizeRem={12} color='red'>
              買ったとき
            </Label>
          </span>
          <span>{addComma(props.investmentPoint)}</span>
        </>
      )}
    </div>
  );
};

export default InvestmentTerm;
