import axios from 'axios';
import type { ReactNode } from 'react';
import { createContext, useContext, useReducer } from 'react';
import { ErrorHandlerContext } from '../../organisms/error-handler/ErrorHandler';
import { LoadingContext } from '../../organisms/loading/Loading';
import { Action, State, createInitialState } from './state';

type LoginResponse = {
  status: number;
  result: boolean;
};

const initialState = createInitialState<LoginResponse>();

// コンテキストの作成
export const LoginContext = createContext<State<LoginResponse>>(initialState);

// レデューサー
const reducer = (
  state: State<LoginResponse>,
  action: Action<LoginResponse>
): State<LoginResponse> => {
  switch (action.type) {
    case 'processing':
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case 'success':
      return {
        data: action.response,
        error: null,
        status: action.type,
      };
    case 'error':
      return {
        data: null,
        error: action.error,
        status: action.type,
      };
    default:
      return state;
  }
};

// カスタムフック
export function useLogin() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { changeLoadingState } = useContext(LoadingContext);
  const { handleError } = useContext(ErrorHandlerContext);

  const login = (name: string, password: string) => {
    const url = 'api/login';
    dispatch({ type: 'processing', response: null, error: null });
    changeLoadingState(true);
    (async () => {
      try {
        const response = await axios.post(url, { userId: name, password });
        dispatch({ type: 'success', response: response.data, error: null });
        changeLoadingState(false);
      } catch (error) {
        handleError(true, error);
        changeLoadingState(false);
      }
    })();
  };

  return { loginState: state, login };
}

// FetchDataProviderコンポーネント
export function LoginProvider({ children }: { children: ReactNode }) {
  const { loginState } = useLogin();
  return (
    <LoginContext.Provider value={loginState}>{children}</LoginContext.Provider>
  );
}
