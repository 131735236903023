import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isAxiosError } from 'axios';
import { ReactNode, createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleButton from '../../atoms/simple-button/SimpleButton';
import { ErrorAction, ErrorResponse } from '../../hooks/reducer/state';
import classes from './ErrorHandler.module.scss';

export type ChangeErrorHandlerState = (flag: boolean) => void;

export const ErrorHandlerContext = createContext<{
  handleError: (hasError: boolean, error?: unknown) => void;
  hasError: boolean;
  messages: ErrorResponse[];
}>({
  handleError: (hasError: boolean, error?: unknown) => {},
  hasError: false,
  messages: [],
});

export const ErrorHandlerProvider = ({ children }: { children: ReactNode }) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [messages, setMessages] = useState<ErrorResponse[]>([]);
  const navigate = useNavigate();

  const handleError = (hasError: boolean, error?: unknown) => {
    setHasError(hasError);

    if (isAxiosError<ErrorAction>(error)) {
      if (error?.response?.status === 401) {
        setHasError(false);
        navigate('/login');
        return;
      }
    }

    const errorMessage = (() => {
      if (isAxiosError<ErrorAction>(error)) {
        const errorResponse = error?.response?.data.error;
        if (errorResponse != null && errorResponse.length !== 0) {
          return errorResponse;
        }
      }
      return ['エラーが発生しました。あとでもう一度試してね'];
    })();

    setMessages(errorMessage);
  };
  return (
    <ErrorHandlerContext.Provider value={{ handleError, hasError, messages }}>
      {children}
    </ErrorHandlerContext.Provider>
  );
};
const ErrorHandler = () => {
  const { handleError, hasError, messages } = useContext(ErrorHandlerContext);

  const close = () => {
    handleError(false);
  };

  return (
    <>
      {hasError && (
        <div className={classes.container}>
          <div className={classes.inner}>
            <div className={classes.messageArea}>
              {messages.map((m) => (
                <div className={classes.message}>
                  <div className={classes.icon}>
                    <FontAwesomeIcon icon={faExclamationCircle} />
                  </div>
                  <div>{m}</div>
                </div>
              ))}
            </div>
            <div className={classes.button}>
              <SimpleButton
                color='whiteNoShadow'
                label='閉じる'
                minHeight='30rem'
                onClick={close}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ErrorHandler;
