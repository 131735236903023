import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useReducer,
} from 'react';
import { InstrumentType } from './UserJobReducer';
import { Action, State, createInitialState } from './state';
import { ChangeLoadingState } from '../../organisms/loading/Loading';
import axios from 'axios';
import { LoadingContext } from '../../organisms/loading/Loading';

export type GetUserRecordResponse = {
  userId: string;
  investmentTerm: string;
  investmentTermName: string;
  instrumentType: InstrumentType;
  investmentTermFrom: string;
  investmentTermTo: string;
  annoucementDay: string;
  totalInitialAllocatedPoint: number;
  totalCurrentPoint: number;
  totalReturn: number;
  totalYield: number;
  statusId: string;
  statusName: string;
  statusClearCount: number;
};

type ContextType = {
  state: State<GetUserRecordResponse>;
  dispatch?: React.Dispatch<Action<GetUserRecordResponse>>;
  refetch?: () => State<GetUserRecordResponse>;
};

const reducer = (
  state: State<GetUserRecordResponse>,
  action: Action<GetUserRecordResponse>
): State<GetUserRecordResponse> => {
  switch (action.type) {
    case 'processing':
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case 'success':
      return {
        status: action.type,
        data: action.response,
        error: null,
      };
    case 'error':
      return {
        status: action.type,
        data: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const initialState = createInitialState<GetUserRecordResponse>();

export const GetUserRecordContext = createContext<ContextType>({
  state: initialState,
});

let calling = false;
function getUserRecord(
  context: ContextType,
  changeLoadState: ChangeLoadingState
) {
  const url = 'api/user-records';

  if (context.dispatch == null) {
    return context.state;
  }

  if (calling) {
    return context.state;
  }

  calling = true;
  context.dispatch({ type: 'processing', response: null, error: null });
  changeLoadState(true);

  (async () => {
    try {
      const response = await axios.get(url);
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: 'success',
        response: response.data.response,
        error: null,
      });
      calling = false;
      changeLoadState(false);
    } catch (error) {
      // TODO: エラーハンドリング不明
      calling = false;
      changeLoadState(false);
    }
  })();
  return context.state;
}

export function GetUserRecordProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { changeLoadingState } = useContext(LoadingContext);

  const refetch = useCallback(
    () => getUserRecord({ state, dispatch }, changeLoadingState),
    []
  );
  return (
    <GetUserRecordContext.Provider value={{ state, dispatch, refetch }}>
      {children}
    </GetUserRecordContext.Provider>
  );
}
