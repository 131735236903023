import { useContext, useEffect, useState } from 'react';
import classes from './JobResult.module.scss';
import { FetchUserResponse } from '../../hooks/reducer/UserReducer';
import miraImage from '../../static/images/money.svg';
import stockBadge from '../../static/images/stock-badge.png';
import trustBadge from '../../static/images/trust-badge.png';
import cryptoBadge from '../../static/images/crypto-badge.png';
import BadgeResultCard from '../../molecules/badge-result/BadgeResultCard';
import {
  FetchStatusContext,
  FetchStatusResponse,
} from '../../hooks/reducer/StatusReducer';
import { getColorByPoint } from '../../common/utility/style.util';
import { addComma, formatSign } from '../../common/utility/number.util';
import { FooterContext } from '../../organisms/footer/Footer';
import { useJob } from '../../hooks/custom/useJob';
import flatIcon from '../../static/images/flat1-mirai-ina.png';
import smileIcon from '../../static/images/smile-mirai-ina.png';
import sadIcon from '../../static/images/sad-mirai-ina.png';

interface JobResultProps {}

const JobResult = () => {
  const userJob = useJob();
  const statusContext = useContext(FetchStatusContext);
  const [userData, setUserData] = useState<FetchUserResponse>();
  const [stockStatus, setStockStatus] = useState<FetchStatusResponse[]>();
  const [trustStatus, setTrustStatus] = useState<FetchStatusResponse[]>();
  const [cryptoStatus, setCryptoStatus] = useState<FetchStatusResponse[]>();
  const { setFooterMessages, setFooterIcon } = useContext(FooterContext);

  useEffect(() => {
    statusContext?.refetch?.({ instrumentType: 'ALL' });
  }, []);

  useEffect(() => {
    if (userJob == null) {
      return;
    }

    setUserData(userJob.user);

    if (userJob.totalReturn == null) {
      return;
    }

    if (userJob.totalReturn === 0) {
      setFooterIcon(flatIcon);
      setFooterMessages([
        '運用（うんよう）のコツは「リスク」と「リターン」の バランス ミラ！',
        'ミラを 増（ふ）やすためには どんな運用（うんよう）をすれば いいミラ？',
      ]);
    } else if (userJob.totalReturn > 0) {
      setFooterIcon(smileIcon);
      setFooterMessages([
        'イイね！運用（うんよう）は じゅんちょうミラ！',
        '次のグレードのスキルバッジを目指すミラ！',
      ]);
    } else {
      setFooterIcon(sadIcon);
      setFooterMessages([
        '運用（うんよう）のコツは「リスク」と「リターン」の バランス ミラ！',
        '「リスク」を 減（へ）らすと いいかもしれないミラ！',
      ]);
    }
  }, [userJob]);

  useEffect(() => {
    const response = statusContext.state.data;

    if (response == null) {
      return;
    }

    // TODO: statusのfilter方法を考える
    setStockStatus(
      response.filter((status) => status.instrumentType === 'STOCK')
    );
    setTrustStatus(
      response.filter((status) => status.instrumentType === 'TRUST')
    );
    setCryptoStatus(
      response.filter((status) => status.instrumentType === 'CRYPTO')
    );
  }, [statusContext.state.data]);

  return (
    <div className={classes.container}>
      <div className={classes.currentPointContainer}>
        <div className={classes.currentPointTitle}>
          ウォレットでゲットしたミラ
        </div>
        <div className={classes.miraIconContainer}>
          <img className={classes.miraIcon} src={miraImage} alt={miraImage} />
          <div
            className={classes.currentPoint}
            style={getColorByPoint(userData?.currentPoint)}
          >
            {addComma(userJob.totalReturn)}
          </div>
        </div>
      </div>
      <div className={classes.productAreaContainer}>
        <div className={classes.card}>
          <BadgeResultCard
            totalReturnPoint={userData?.totalReturnStockPoint}
            totalSuccessCount={userData?.totalSuccessStockCount}
            totalStatusCount={userData?.totalStockStatusCount}
            statusName={userData?.stockStatusName}
            skillName={userData?.stockSkillName}
            instrumentType={'STOCK'}
            status={stockStatus}
          />
        </div>
        <div className={classes.card}>
          <BadgeResultCard
            totalReturnPoint={userData?.totalReturnTrustPoint}
            totalSuccessCount={userData?.totalSuccessTrustCount}
            totalStatusCount={userData?.totalTrustStatusCount}
            statusName={userData?.trustStatusName}
            skillName={userData?.trustSkillName}
            instrumentType={'TRUST'}
            status={trustStatus}
          />
        </div>
        <div className={classes.card}>
          <BadgeResultCard
            totalReturnPoint={userData?.totalReturnCryptoPoint}
            totalSuccessCount={userData?.totalSuccessCryptoCount}
            totalStatusCount={userData?.totalCryptoStatusCount}
            statusName={userData?.cryptoStatusName}
            skillName={userData?.cryptoSkillName}
            instrumentType={'CRYPTO'}
            status={cryptoStatus}
          />
        </div>
        <div className={classes.card}>
          <BadgeResultCard totalReturnPoint={0} instrumentType={'BANK'} />
        </div>
      </div>
    </div>
  );
};

export default JobResult;
