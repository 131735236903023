import axios from 'axios';
import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useReducer } from 'react';
import { ErrorHandlerContext } from '../../organisms/error-handler/ErrorHandler';
import {
  ChangeLoadingState,
  LoadingContext,
} from '../../organisms/loading/Loading';
import { Action, State, createInitialState } from './state';

export type FetchUserResponse = {
  userId: string;
  userName: string;
  imageFile: string;
  parkUrl: string | null;
  totalResetCount: number;
  currentPoint: number;
  initialPoint: number;
  stockStatusId: string;
  trustStatusId: string;
  cryptoStatusId: string;
  totalStockStatusCount: number;
  totalTrustStatusCount: number;
  totalCryptoStatusCount: number;
  totalSuccessStockCount: number;
  totalSuccessTrustCount: number;
  totalSuccessCryptoCount: number;
  totalReturnStockPoint: number;
  totalReturnTrustPoint: number;
  totalReturnCryptoPoint: number;
  updatedAt: string;
  createdAt: string;
  stockSkillName: string;
  trustSkillName: string;
  cryptoSkillName: string;
  stockStatusName: string;
  trustStatusName: string;
  cryptoStatusName: string;
};

type ContextType = {
  state: State<FetchUserResponse>;
  dispatch?: React.Dispatch<Action<FetchUserResponse>>;
  refetch?: () => State<FetchUserResponse>;
};

// レデューサー
const reducer = (
  state: State<FetchUserResponse>,
  action: Action<FetchUserResponse>
): State<FetchUserResponse> => {
  switch (action.type) {
    case 'processing':
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case 'success':
      return {
        data: action.response,
        error: null,
        status: action.type,
      };
    case 'error':
      return {
        data: null,
        error: action.error,
        status: action.type,
      };
    default:
      return state;
  }
};

export const initialState = createInitialState<FetchUserResponse>();

// コンテキストの作成
export const FetchUserContext = createContext<ContextType>({
  state: initialState,
});

let calling = false;
function fetchUser(
  context: ContextType,
  changeLoadingState: ChangeLoadingState,
  handleError: (hasError: boolean, error?: unknown) => void
) {
  const url = 'api/users';

  if (context.dispatch == null) {
    return context.state;
  }

  if (calling) {
    return context.state;
  }

  calling = true;
  context.dispatch({ type: 'processing', response: null, error: null });
  changeLoadingState(true);

  (async () => {
    try {
      const response = await axios.get(url);
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: 'success',
        response: response.data.response,
        error: null,
      });
      calling = false;
      changeLoadingState(false);
    } catch (error) {
      handleError(true, error);
      calling = false;
      changeLoadingState(false);
    }
  })();
  return context.state;
}

export function FetchUserProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { changeLoadingState } = useContext(LoadingContext);
  const { handleError } = useContext(ErrorHandlerContext);

  const refetch = useCallback(
    () => fetchUser({ state, dispatch }, changeLoadingState, handleError),
    []
  );

  return (
    <FetchUserContext.Provider value={{ state, dispatch, refetch }}>
      {children}
    </FetchUserContext.Provider>
  );
}
