import { useContext, useEffect, useMemo, useState } from 'react';
import { toYYYYMMDD } from '../../common/utility/date.util';
import {
  FetchCalendarStatusContext,
  FetchCalendarStatusResponse,
} from '../reducer/CalendarStatusReducer';
import { useBusinessDate } from './useBusinessDate';

export const useCalendarStatus = () => {
  const [calendarStatus, setCalendarStatus] =
    useState<FetchCalendarStatusResponse>();
  const businessDate = useBusinessDate();
  const calendarStatusContext = useContext(FetchCalendarStatusContext);

  useEffect(() => {
    if (businessDate == null) {
      return;
    }

    calendarStatusContext?.refetch?.({
      businessDate: toYYYYMMDD(businessDate),
    });
  }, [businessDate]);

  useEffect(() => {
    if (calendarStatusContext.state.data == null) {
      return;
    }

    setCalendarStatus(calendarStatusContext.state.data[0]);
  }, [calendarStatusContext.state.data]);

  return useMemo(() => calendarStatus, [calendarStatus]);
};
