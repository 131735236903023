import axios from 'axios';
import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useReducer } from 'react';
import { ErrorHandlerContext } from '../../organisms/error-handler/ErrorHandler';
import {
  ChangeLoadingState,
  LoadingContext,
} from '../../organisms/loading/Loading';
import { Action, State, createInitialState } from './state';

export type FetchTrustResponse = {
  instrumentId: string;
  instrumentType: 'STOCK' | 'TRUST' | 'CRYPTO' | 'BANK';
  name: string;
  profile: string;
  riskProfile: RiskProfile;
  returnProfile: ReturnProfile;
  videoLink: string;
  tradingUnit: number;
  priceVolatility: number;
  hasDistribution: boolean;
  distributionProbability: number;
  distributionAmount: number;
  addByUser: boolean;
  addedUser: string;
};

// Todo 他のAPIでも使用するから定義を移す？
export type BuyUserInvestmentRequest = {
  instrumentType: 'STOCK' | 'TRUST' | 'CRYPTO' | 'BANK';
  instrumentId: string;
  allocatedPoint: number;
};

export type ResetUserInvestmentRequest = {
  instrumentType: 'STOCK' | 'TRUST' | 'CRYPTO' | 'BANK';
  instrumentId: string;
};

export type RiskProfile = 'LOW' | 'MIDDLE' | 'HIGH';
export type ReturnProfile = 'LOW' | 'MIDDLE' | 'HIGH';

type ContextType<T> = {
  state: State<T>;
  dispatch?: React.Dispatch<Action<T>>;
  refetch?: (params: Params) => State<T>;
  buy?: (request: T) => State<T>;
};

// レデューサー
const reducer = (
  state: State<FetchTrustResponse>,
  action: Action<FetchTrustResponse>
): State<FetchTrustResponse> => {
  switch (action.type) {
    case 'processing':
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case 'success':
      return {
        data: action.response,
        error: null,
        status: action.type,
      };
    case 'error':
      return {
        data: null,
        error: action.error,
        status: action.type,
      };
    default:
      return state;
  }
};

// Todo
// stateが取得と登録で分かれているのでreducerも分けた。しかし冗長な気がする
const buyReducer = (
  state: State<BuyUserInvestmentRequest>,
  action: Action<BuyUserInvestmentRequest>
): State<BuyUserInvestmentRequest> => {
  switch (action.type) {
    case 'processing':
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case 'success':
      return {
        data: action.response,
        error: null,
        status: action.type,
      };
    case 'error':
      return {
        data: null,
        error: action.error,
        status: action.type,
      };
    default:
      return state;
  }
};

export const initialState = createInitialState<FetchTrustResponse>();
export const initialBuyState = createInitialState<BuyUserInvestmentRequest>();

// コンテキストの作成
export const FetchTrustContext = createContext<ContextType<FetchTrustResponse>>(
  {
    state: initialState,
  }
);

export const BuyInvestmentContext = createContext<
  ContextType<BuyUserInvestmentRequest>
>({
  state: initialBuyState,
});

type Params = {
  instrumentId: string;
};

function fetchTrust(
  context: ContextType<FetchTrustResponse>,
  changeLoadingState: ChangeLoadingState,
  handleError: (hasError: boolean, error?: unknown) => void,
  params: Params
) {
  const url = 'api/trusts';

  if (context.dispatch == null) {
    return context.state;
  }

  context.dispatch({ type: 'processing', response: null, error: null });
  changeLoadingState(true);

  (async () => {
    try {
      const response = await axios.get(url, {
        params,
      });
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: 'success',
        response: response.data.response,
        error: null,
      });
      changeLoadingState(false);
    } catch (error) {
      handleError(true, error);
      changeLoadingState(false);
    }
  })();
  return context.state;
}

export function FetchTrustProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { changeLoadingState } = useContext(LoadingContext);
  const { handleError } = useContext(ErrorHandlerContext);

  const refetch = useCallback(
    (params: Params) =>
      fetchTrust({ state, dispatch }, changeLoadingState, handleError, params),
    []
  );

  return (
    <FetchTrustContext.Provider value={{ state, dispatch, refetch }}>
      {children}
    </FetchTrustContext.Provider>
  );
}

let calling = false;
function buyInvestment(
  context: ContextType<BuyUserInvestmentRequest>,
  changeLoadingState: ChangeLoadingState,
  handleError: (hasError: boolean, error?: unknown) => void,
  request: BuyUserInvestmentRequest
) {
  const url = `api/user-investments`;

  if (context.dispatch == null) {
    return context.state;
  }

  if (calling) {
    return context.state;
  }

  calling = true;
  context.dispatch({ type: 'processing', response: null, error: null });
  changeLoadingState(true);

  (async () => {
    try {
      const response = await axios.post(url, request);
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: 'success',
        response: response.data.response,
        error: null,
      });
      calling = false;
      changeLoadingState(false);
    } catch (error) {
      handleError(true, error);
      calling = false;
      changeLoadingState(false);
    }
  })();

  return context.state;
}

export function BuyTrustProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(buyReducer, initialBuyState);
  const { changeLoadingState } = useContext(LoadingContext);
  const { handleError } = useContext(ErrorHandlerContext);

  const buy = (request: BuyUserInvestmentRequest) =>
    buyInvestment(
      { state, dispatch },
      changeLoadingState,
      handleError,
      request
    );

  return (
    <BuyInvestmentContext.Provider value={{ state, dispatch, buy }}>
      {children}
    </BuyInvestmentContext.Provider>
  );
}
