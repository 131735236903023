import { ReactNode } from 'react';
import { BuyBankProvider, FetchBankProvider } from '../reducer/BankReducer';
import { FetchBusinessCalendarProvider } from '../reducer/BusinessCalendarReducer';
import { FetchCalendarStatusProvider } from '../reducer/CalendarStatusReducer';
import {
  BuyCryptoProvider,
  FetchCryptoProvider,
} from '../reducer/CryptoReducer';
import { DeleteUserRecordProvider } from '../reducer/DeleteUserRecordReducer';
import { FetchEventHistoryProvider } from '../reducer/EventHistoryReducer';
import { PostUserRecordProvider } from '../reducer/PostUserRecordReducer';
import { BuyStockProvider, FetchStockProvider } from '../reducer/StockReducer';
import { BuyTrustProvider, FetchTrustProvider } from '../reducer/TrustReducer';
import { FetchUserInstrumentChartProvider } from '../reducer/UserInstrumentChartReducer';
import {
  CreateUserJobProvider,
  FetchUserJobProvider,
  ResetUserJobProvider,
} from '../reducer/UserJobReducer';
import { FetchUserProvider } from '../reducer/UserReducer';
import { GetUserRecordProvider } from '../reducer/UserRecordReducer';
import { FetchStateProvider } from '../reducer/StatusReducer';
import { FetchUserJobChartProvider } from '../reducer/UserJobChartReducer';
import { SsoLoginProvider } from '../reducer/SsoLoginReducer';

const AppProvider = ({ children }: { children: ReactNode }) => {
  return (
    <SsoLoginProvider>
      <DeleteUserRecordProvider>
        <PostUserRecordProvider>
          <FetchUserProvider>
            <FetchUserJobProvider>
              <FetchUserInstrumentChartProvider>
                <FetchStockProvider>
                  <FetchTrustProvider>
                    <FetchCalendarStatusProvider>
                      <FetchBusinessCalendarProvider>
                        <BuyTrustProvider>
                          <FetchCryptoProvider>
                            <FetchBankProvider>
                              <ResetUserJobProvider>
                                <CreateUserJobProvider>
                                  <BuyStockProvider>
                                    <BuyCryptoProvider>
                                      <BuyBankProvider>
                                        <FetchEventHistoryProvider>
                                          <GetUserRecordProvider>
                                            <FetchStateProvider>
                                              <FetchUserJobChartProvider>
                                                {children}
                                              </FetchUserJobChartProvider>
                                            </FetchStateProvider>
                                          </GetUserRecordProvider>
                                        </FetchEventHistoryProvider>
                                      </BuyBankProvider>
                                    </BuyCryptoProvider>
                                  </BuyStockProvider>
                                </CreateUserJobProvider>
                              </ResetUserJobProvider>
                            </FetchBankProvider>
                          </FetchCryptoProvider>
                        </BuyTrustProvider>
                      </FetchBusinessCalendarProvider>
                    </FetchCalendarStatusProvider>
                  </FetchTrustProvider>
                </FetchStockProvider>
              </FetchUserInstrumentChartProvider>
            </FetchUserJobProvider>
          </FetchUserProvider>
        </PostUserRecordProvider>
      </DeleteUserRecordProvider>
    </SsoLoginProvider>
  );
};

export default AppProvider;
