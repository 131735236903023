import classes from './JobHeader.module.scss';
import SimpleButton from '../../atoms/simple-button/SimpleButton';
import moneyIcon from '../../static/images/money.svg';
import { useNavigate } from 'react-router-dom';
import { useJob } from '../../hooks/custom/useJob';
import settingIcon from '../../static/images/setting-black.svg';
import cancelIcon from '../../static/images/left-vector-green.svg';

type Props = {
  title: string;
  previousUrl: string;
};

const JobHeader = (props: Props) => {
  const navigate = useNavigate();
  const userJob = useJob();
  return (
    <div className={classes.header}>
      <div className={classes.left}>
        <div className={classes.button}>
          <SimpleButton
            color='transparentGreen'
            leftIcon={cancelIcon}
            label='もどる'
            fontSize='20rem'
            minHeight='30rem'
            minWidth='90rem'
            maxWidth='110rem'
            onClick={() => navigate(props.previousUrl)}
          />
        </div>
        <span className={classes.title}>{props.title}</span>
      </div>
      <div className={classes.right}>
        <span>このうんようで使えるミラ</span>
        <img className={classes.icon} src={moneyIcon} />
        <span className={classes.investingMira}>
          {(userJob.userJob?.totalInitialJobAllocatedPoint ?? 0) -
            (userJob.userJob?.totalInitialInvestmentAllocatedPoint ?? 0)}
        </span>
        <span className={classes.holdingMira}>
          /{userJob.userJob?.totalInitialJobAllocatedPoint}
        </span>
        <div className={classes.button}>
          <SimpleButton
            label='せってい'
            minHeight='30rem'
            fontSize='14rem'
            minWidth='90rem'
            maxWidth='110rem'
            leftIcon={settingIcon}
            onClick={() => {
              navigate('/job-setting');
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default JobHeader;
