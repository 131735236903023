import { CSSProperties } from 'react';
import { InstrumentType } from '../../hooks/reducer/UserJobReducer';
import classes from './Badge.module.scss';
import defaultBadge from '../../static/images/default-badge.png';

type Props = {
  badge: string | null;
  instrumentType?: InstrumentType;
  boxShadow?: string;
  disabled?: boolean;
  width: string;
  height: string;
};

const Badge = (props: Props) => {
  const badgeOuterStyle = (() => {
    const style: CSSProperties = {
      width: props.width,
      height: props.height,
    };
    if (props.instrumentType == null) {
      return style;
    }

    if (props.disabled) {
      return style;
    }

    const boxShadowBase = props.boxShadow ?? '0 0 54.621rem 0';

    if (props.instrumentType === 'STOCK') {
      style.boxShadow = `${boxShadowBase} #00F0FF`;
    }
    if (props.instrumentType === 'TRUST') {
      style.boxShadow = `${boxShadowBase} #F9ED23`;
    }
    if (props.instrumentType === 'CRYPTO') {
      style.boxShadow = `${boxShadowBase} #E34084`;
    }

    return style;
  })();
  return (
    <>
      {(props.disabled != null && props.disabled) || props.badge == null ? (
        <div className={classes.badgeOuter} style={badgeOuterStyle}>
          <img
            src={defaultBadge}
            alt={defaultBadge}
            className={classes.badge}
          />
        </div>
      ) : (
        <div className={classes.badgeOuter} style={badgeOuterStyle}>
          <img src={props.badge} alt={props.badge} className={classes.badge} />
        </div>
      )}
    </>
  );
};

export default Badge;
