import axios from 'axios';
import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useReducer } from 'react';
import { ErrorHandlerContext } from '../../organisms/error-handler/ErrorHandler';
import {
  ChangeLoadingState,
  LoadingContext,
} from '../../organisms/loading/Loading';
import {
  BuyUserInvestmentRequest,
  ResetUserInvestmentRequest,
} from './TrustReducer';
import { Action, State, createInitialState } from './state';

type ProfileLevel = 'HIGH' | 'MIDDLE' | 'LOW';

export type FetchStockResponse = {
  instrumentType: 'STOCK' | 'TRUST' | 'CRYPTO' | 'BANK';
  profile: string;
  name: string;
  videoLink: string;
  tradingUnit: number;
  hasDistribution: boolean;
  distributionAmount: number;
  addedUser: string;
  industryId: number;
  instrumentId: string;
  profileSummary: string;
  imageFile: string;
  priceVolatility: number;
  distributionProbability: number;
  addedByUser: boolean;
  riskProfile: ProfileLevel;
  returnProfile: ProfileLevel;
};

type ContextType<T, U, V> = {
  state: State<T>;
  dispatch?: React.Dispatch<Action<T>>;
  refetch?: (params: Params) => State<T>;
  buy?: (request: T) => State<T>;
  reset?: (request: U) => State<U>;
  update?: (request: V) => State<V>;
};

// レデューサー
const reducer = (
  state: State<FetchStockResponse>,
  action: Action<FetchStockResponse>
): State<FetchStockResponse> => {
  switch (action.type) {
    case 'processing':
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case 'success':
      return {
        data: action.response,
        error: null,
        status: action.type,
      };
    case 'error':
      return {
        data: null,
        error: action.error,
        status: action.type,
      };
    default:
      return state;
  }
};

export const initialState = createInitialState<FetchStockResponse>();

// コンテキストの作成
export const FetchStockContext = createContext<
  ContextType<FetchStockResponse, null, null>
>({
  state: initialState,
});

type Params = {
  instrumentId: string;
};

let calling = false;
function fetchStock(
  context: ContextType<FetchStockResponse, null, null>,
  changeLoadingState: ChangeLoadingState,
  handleError: (hasError: boolean, error?: unknown) => void,
  params: Params
) {
  const url = 'api/stocks';

  if (context.dispatch == null) {
    return context.state;
  }

  if (calling) {
    return context.state;
  }

  calling = true;
  context.dispatch({ type: 'processing', response: null, error: null });
  changeLoadingState(true);

  (async () => {
    try {
      const response = await axios.get(url, {
        params,
      });
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: 'success',
        response: response.data.response,
        error: null,
      });
      calling = false;
      changeLoadingState(false);
    } catch (error) {
      handleError(true, error);
      calling = false;
      changeLoadingState(false);
    }
  })();
  return context.state;
}

export function FetchStockProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { changeLoadingState } = useContext(LoadingContext);
  const { handleError } = useContext(ErrorHandlerContext);

  const refetch = useCallback(
    (params: Params) =>
      fetchStock({ state, dispatch }, changeLoadingState, handleError, params),
    []
  );

  return (
    <FetchStockContext.Provider value={{ state, dispatch, refetch }}>
      {children}
    </FetchStockContext.Provider>
  );
}

export const initialBuyState = createInitialState<BuyUserInvestmentRequest>();
export const BuyStockContext = createContext<
  ContextType<
    BuyUserInvestmentRequest,
    ResetUserInvestmentRequest,
    BuyUserInvestmentRequest
  >
>({
  state: initialBuyState,
});

// Todo
// stateが取得と登録で分かれているのでreducerも分けた。しかし冗長な気がする
const buyReducer = (
  state: State<BuyUserInvestmentRequest>,
  action: Action<BuyUserInvestmentRequest>
): State<BuyUserInvestmentRequest> => {
  switch (action.type) {
    case 'processing':
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case 'success':
      return {
        data: action.response,
        error: null,
        status: action.type,
      };
    case 'error':
      return {
        data: null,
        error: action.error,
        status: action.type,
      };
    default:
      return state;
  }
};

function buyStock(
  context: ContextType<
    BuyUserInvestmentRequest,
    ResetUserInvestmentRequest,
    BuyUserInvestmentRequest
  >,
  changeLoadingState: ChangeLoadingState,
  handleError: (hasError: boolean, error?: unknown) => void,
  request: BuyUserInvestmentRequest
) {
  const url = `api/user-investments`;

  if (context.dispatch == null) {
    return context.state;
  }

  if (calling) {
    return context.state;
  }

  calling = true;
  context.dispatch({ type: 'processing', response: null, error: null });
  changeLoadingState(true);

  (async () => {
    try {
      const response = await axios.post(url, request);
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: 'success',
        response: response.data.response,
        error: null,
      });
      calling = false;
      changeLoadingState(false);
    } catch (error) {
      handleError(true, error);
      calling = false;
      changeLoadingState(false);
    }
  })();

  return context.state;
}

function resetStock(
  context: ContextType<
    BuyUserInvestmentRequest,
    ResetUserInvestmentRequest,
    BuyUserInvestmentRequest
  >,
  changeLoadingState: ChangeLoadingState,
  handleError: (hasError: boolean, error?: unknown) => void,
  request: ResetUserInvestmentRequest
) {
  const url = `api/user-investments`;

  if (context.dispatch == null) {
    return context.state;
  }

  if (calling) {
    return context.state;
  }

  calling = true;
  context.dispatch({ type: 'processing', response: null, error: null });
  changeLoadingState(true);

  (async () => {
    try {
      const response = await axios.delete(url, { params: request });
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: 'success',
        response: response.data.response,
        error: null,
      });
      calling = false;
      changeLoadingState(false);
    } catch (error) {
      handleError(true, error);
      calling = false;
      changeLoadingState(false);
    }
  })();

  return context.state;
}

function updateStock(
  context: ContextType<
    BuyUserInvestmentRequest,
    ResetUserInvestmentRequest,
    BuyUserInvestmentRequest
  >,
  changeLoadingState: ChangeLoadingState,
  handleError: (hasError: boolean, error?: unknown) => void,
  request: BuyUserInvestmentRequest
) {
  const url = `api/user-investments`;

  if (context.dispatch == null) {
    return context.state;
  }

  if (calling) {
    return context.state;
  }

  calling = true;
  context.dispatch({ type: 'processing', response: null, error: null });
  changeLoadingState(true);

  (async () => {
    try {
      const response = await axios.put(url, request);
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: 'success',
        response: response.data.response,
        error: null,
      });
      calling = false;
      changeLoadingState(false);
    } catch (error) {
      handleError(true, error);
      calling = false;
      changeLoadingState(false);
    }
  })();

  return context.state;
}

export function BuyStockProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(buyReducer, initialBuyState);
  const { changeLoadingState } = useContext(LoadingContext);
  const { handleError } = useContext(ErrorHandlerContext);

  const buy = (request: BuyUserInvestmentRequest) =>
    buyStock({ state, dispatch }, changeLoadingState, handleError, request);

  const reset = (request: ResetUserInvestmentRequest) =>
    resetStock({ state, dispatch }, changeLoadingState, handleError, request);
  const update = (request: BuyUserInvestmentRequest) =>
    updateStock({ state, dispatch }, changeLoadingState, handleError, request);

  return (
    <BuyStockContext.Provider value={{ state, dispatch, buy, reset, update }}>
      {children}
    </BuyStockContext.Provider>
  );
}
