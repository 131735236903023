import { useEffect, useState } from 'react';
import { useJob } from '../../hooks/custom/useJob';
import { InstrumentType } from '../../hooks/reducer/UserJobReducer';
import InvestmentCard from '../../molecules/investment-card/InvestmentCard';
import NewsList from '../../organisms/news-list/NewsList';
import classes from './InvestmentDoing.module.scss';

const InvestmentDoing = () => {
  const { userJob, isSelectableTerm } = useJob();
  const [instrumentType, setInstrumentType] = useState<InstrumentType>();

  useEffect(() => {
    if (userJob == null || userJob.currentJobStatus === 'unset') {
      return;
    }

    setInstrumentType(userJob.investments[0].instrumentType);
  }, [userJob]);

  const hasUserJob = () => {
    if (userJob == null || userJob.investments == null) {
      return false;
    }

    return userJob.investments.length > 0;
  };

  return (
    <div className={classes.container}>
      <div className={classes.investment}>
        {(userJob?.investments.length ?? 0) > 0 && (
          <div className={classes.half}>
            <InvestmentCard
              type={instrumentType ?? 'STOCK'}
              userJob={userJob}
              isSelectableTerm={isSelectableTerm}
            />
          </div>
        )}
        <div className={hasUserJob() ? classes.half : classes.full}>
          <NewsList />
        </div>
      </div>
    </div>
  );
};

export default InvestmentDoing;
