import { CSSProperties, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addComma, formatSign } from '../../common/utility/number.util';
import { getColorByPoint } from '../../common/utility/style.util';
import { useJob } from '../../hooks/custom/useJob';
import moneyImg from '../../static/images/money.svg';
import classes from './GoalDoing.module.scss';

type Props = {
  summaryOnly?: true;
};

// 運用期間中に表示されるゴール
// 設定期間中とあまりにも違うのでコンポーネント分離
const GoalDoing = (props: Props) => {
  const { jobStatus, userJob } = useJob();

  const [totalReturn, setTotalReturn] = useState<string>();
  const [totalYeild, setTotalYeild] = useState<string>();
  const [qualitativeGoal, setQualitativeGoal] = useState<string>();
  const [totalCurrentPoint, setTotalCurrentPoint] = useState<string>();
  const navigate = useNavigate();

  const [totalReturnStyle, setTotalReturnStyle] = useState<CSSProperties>();
  const [totalYeildStyle, setTotalYeildStyle] = useState<CSSProperties>();
  const [totalCurrentPointStyle, setTotalCurrentPointStyle] =
    useState<CSSProperties>();

  useEffect(() => {
    if (jobStatus === 'NOTHING') {
      setTotalReturn('±0');
      setTotalYeild('±0%');
      setQualitativeGoal('-');
      setTotalCurrentPoint('0');
    } else {
      setTotalReturnStyle(getColorByPoint(userJob?.totalReturn));
      setTotalReturn(`${formatSign(userJob?.totalReturn)}`);
      setTotalYeild(`${formatSign(userJob?.totalYield, true)}`);
      setTotalYeildStyle(getColorByPoint(userJob?.totalYield));
      setQualitativeGoal(userJob?.qualitativeGoal);
      setTotalCurrentPoint(addComma(userJob?.totalCurrentPoint));
      setTotalCurrentPointStyle(getColorByPoint(userJob?.totalCurrentPoint));
    }
  }, [jobStatus, userJob]);

  const setting = () => {
    navigate('/job-setting');
  };

  const reset = () => {
    navigate('/job-reset');
  };

  return (
    <div className={classes.container}>
      <div className={`${classes.block} ${classes.blockMira}`}>
        <div className={classes.label}>うんよう</div>
        <div className={classes.content}>
          <img className={classes.icon} src={moneyImg} alt='goal-money-image' />
          <span style={totalCurrentPointStyle}>{totalCurrentPoint}</span>
        </div>
      </div>
      <div className={`${classes.block} ${classes.blockQuantitativeGoal}`}>
        <div className={classes.label}>増減ミラ</div>
        <div className={classes.content} style={totalReturnStyle}>
          {totalReturn}
        </div>
        <div className={classes.content} style={totalYeildStyle}>
          {totalYeild}
        </div>
      </div>
      <div className={`${classes.block} ${classes.blockQualitativeGoal}`}>
        <div className={classes.label}>ていせい目標</div>
        <div className={classes.goalContent}>{qualitativeGoal}</div>
      </div>
    </div>
  );
};

export default GoalDoing;
