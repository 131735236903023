import { useContext, useEffect, useState } from 'react';
import { useJob } from '../../hooks/custom/useJob';
import StockCard from '../../molecules/stock-card/StockCard';
import Header from '../../organisms/header/Header';
import classes from './ExceedStock.module.scss';
import {
  FetchStockContext,
  FetchStockResponse,
} from '../../hooks/reducer/StockReducer';
import SimpleButton from '../../atoms/simple-button/SimpleButton';
import icon from '../../static/images/reset.svg';
import { Navigate, useNavigate } from 'react-router-dom';
import moneyIcon from '../../static/images/money.svg';

const ExceedStock = () => {
  const { userJob } = useJob();
  const { state, refetch } = useContext(FetchStockContext);
  const navigate = useNavigate();

  const [selected, setSelected] = useState<FetchStockResponse>();

  const [investedStocks, setInvestedStocks] = useState<FetchStockResponse[]>(
    []
  );

  useEffect(() => {
    refetch?.({ instrumentId: 'ALL' });
  }, [refetch]);

  useEffect(() => {
    if (userJob == null) {
      return;
    }

    if (state.status === 'success') {
      const ids = userJob.investments.map((i) => i.instrumentId);
      const targets = state.data.filter((d) => ids.includes(d.instrumentId));
      setInvestedStocks(targets);
    }
  }, [state, userJob]);

  const select = (stock: FetchStockResponse) => {
    setSelected(stock);
  };

  const update = () => {
    if (selected == null) {
      return;
    }

    navigate(`/stock-detail?instrumentId=${selected.instrumentId}`);
  };
  return (
    <div className={classes.container}>
      <Header title='ウォレット' />
      <div className={classes.contents}>
        <div className={classes.contentsInner}>
          <div className={classes.title}>かぶは 3種類までしか 持てないよ</div>
          <div className={classes.subtitle}>
            <div>今持っている株の数がいっぱいです。</div>
            <div>この株を買うには、変更する株を1つ以上選んでね。</div>
          </div>
          <div className={classes.box}>
            {investedStocks.map((stock) => {
              return (
                <div className={classes.card}>
                  <StockCard
                    selected={
                      selected?.instrumentType === stock.instrumentType &&
                      selected?.instrumentId === stock.instrumentId
                    }
                    userJob={userJob}
                    stock={stock}
                    onClick={() => select(stock)}
                  />
                </div>
              );
            })}
          </div>
          <div className={classes.buttonArea}>
            <div className={classes.button}>
              <SimpleButton
                color='transparent'
                label='キャンセル'
                fontSize='22rem'
                minHeight='45rem'
                minWidth='200rem'
                maxWidth='200rem'
                onClick={() => navigate(-1)}
              />
            </div>
            <div className={classes.button}>
              <SimpleButton
                color='red'
                leftIcon={icon}
                label='変更する'
                fontSize='22rem'
                minHeight='45rem'
                minWidth='200rem'
                maxWidth='200rem'
                disabled={selected == null}
                onClick={update}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExceedStock;
