import axios from 'axios';
import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useReducer } from 'react';
import { ErrorHandlerContext } from '../../organisms/error-handler/ErrorHandler';
import {
  ChangeLoadingState,
  LoadingContext,
} from '../../organisms/loading/Loading';
import { createReducer } from './BaseReducer';
import {
  ActionWithKey,
  StateWithKey,
  createInitialStateWithKey,
  createKey,
  request,
} from './state';

export type InstrumentType = 'STOCK' | 'TRUST' | 'CRYPTO' | 'BANK';

export const typeMapper: { [key in InstrumentType]: string } = {
  STOCK: 'かぶ',
  TRUST: 'とうしん',
  CRYPTO: 'かそうつうか',
  BANK: 'ちょきん',
};

export type Investment = {
  uniqueKey: string;
  instrumentType: InstrumentType;
  instrumentId: string;
  instrumentName: string;
  initialAllocatedPoint: number;
  currentPoint: number;
  investmentYield: number;
  investmentReturn: number;
};

export type PostRequestParams = {
  qualitativeGoal: string;
  quantitativeGoal: number;
  allocatedPoint: number;
};

export type FetchUserJobResponse = {
  userId: string;
  qualitativeGoal: string;
  quantitativeGoal: number;
  currentPublicationStatus: 'unpublished' | 'published';
  prevPublicationStatus: 'unpublished' | 'published';
  currentJobStatus: 'unset' | 'set';
  prevJobStatus: 'unset' | 'set';
  totalInitialJobAllocatedPoint: number;
  totalInitialInvestmentAllocatedPoint: number;
  totalCurrentPoint: number;
  totalReturn: number;
  totalYield: number;
  investments: Investment[];
};

type ContextType = {
  state: StateWithKey<FetchUserJobResponse>;
  dispatch?: React.Dispatch<ActionWithKey<FetchUserJobResponse>>;
  // refetch?: (params: Params) => StateWithKey<FetchUserJobResponse>;
  refetch?: (params: Params) => any;
  reset?: () => StateWithKey<FetchUserJobResponse>; // todo
  create?: (params: PostRequestParams) => StateWithKey<FetchUserJobResponse>; // todo
};

const reducer = createReducer<FetchUserJobResponse>();

export const initialState = createInitialStateWithKey<FetchUserJobResponse>();

// コンテキストの作成
export const FetchUserJobContext = createContext<ContextType>({
  state: initialState,
});

export const ResetUserJobContext = createContext<ContextType>({
  state: initialState,
});

export const CreateUserJobContext = createContext<ContextType>({
  state: initialState,
});

type Params = {
  investmentTerm: string;
  withInvestments: boolean;
};

let calling: { [key: string]: boolean } = {};

export function FetchUserJobProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { changeLoadingState } = useContext(LoadingContext);
  const { handleError } = useContext(ErrorHandlerContext);

  const refetch = useCallback((params: Params) => {
    return request<FetchUserJobResponse, Params>(
      'GET',
      'api/user-jobs',
      { state, dispatch },
      changeLoadingState,
      handleError,
      params,
      (data: any) => {
        return {
          ...data,
          investments: data.investments.map((i: any) => {
            return {
              ...i,
              uniqueKey: `${i.instrumentType}-${i.instrumentId}`,
            };
          }),
        };
      }
    );
  }, []);
  return (
    <FetchUserJobContext.Provider value={{ state, dispatch, refetch }}>
      {children}
    </FetchUserJobContext.Provider>
  );
}

function resetUserJob(
  context: ContextType,
  changeLoadingState: ChangeLoadingState,
  handleError: (hasError: boolean, error?: unknown) => void
) {
  const url = 'api/user-jobs';

  if (context.dispatch == null) {
    return context.state;
  }

  const key = createKey({ static: 'static' });
  if (key in calling && calling[key]) {
    return context.state;
  }

  calling[key] = true;
  context.dispatch({ type: 'processing', response: null, error: null });
  changeLoadingState(true);

  (async () => {
    try {
      const response = await axios.delete(url);
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: 'success',
        response: response.data.response,
        error: null,
      });
      calling[key] = false;
      changeLoadingState(false);
    } catch (error) {
      handleError(true, error);
      calling[key] = false;
      changeLoadingState(false);
    }
  })();
  return context.state;
}

export function ResetUserJobProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { changeLoadingState } = useContext(LoadingContext);
  const { handleError } = useContext(ErrorHandlerContext);

  const reset = useCallback(
    () => resetUserJob({ state, dispatch }, changeLoadingState, handleError),
    []
  );
  return (
    <ResetUserJobContext.Provider value={{ state, dispatch, reset }}>
      {children}
    </ResetUserJobContext.Provider>
  );
}

function createUserJob(
  context: ContextType,
  changeLoadingState: ChangeLoadingState,
  handleError: (hasError: boolean, error?: unknown) => void,
  params: PostRequestParams
) {
  const url = 'api/user-jobs';

  if (context.dispatch == null) {
    return context.state;
  }

  const key = createKey(params);
  if (key in calling && calling[key]) {
    return context.state;
  }

  calling[key] = true;
  context.dispatch({ type: 'processing', response: null, error: null });
  changeLoadingState(true);

  (async () => {
    try {
      const response = await axios.post(url, params);
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: 'success',
        response: response.data.response,
        error: null,
      });
      calling[key] = false;
      changeLoadingState(false);
    } catch (error) {
      handleError(true, error);
      calling[key] = false;
      changeLoadingState(false);
    }
  })();
  return context.state;
}

export function CreateUserJobProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { changeLoadingState } = useContext(LoadingContext);
  const { handleError } = useContext(ErrorHandlerContext);

  const create = useCallback(
    (params: PostRequestParams) =>
      createUserJob(
        { state, dispatch },
        changeLoadingState,
        handleError,
        params
      ),
    []
  );
  return (
    <CreateUserJobContext.Provider value={{ state, dispatch, create }}>
      {children}
    </CreateUserJobContext.Provider>
  );
}
