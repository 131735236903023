import { ReactNode } from 'react';

type Props = {
  color?: 'green' | 'yellow' | 'red';
  fontSizeRem: number;
  children: ReactNode;
};

const Label = (props: Props) => {
  const style = (() => {
    const defaultStyle = {
      border: 'solid 1rem',
      fontSize: `${props.fontSizeRem}rem`,
      // padding: `${props.fontSizeRem / 6}rem ${props.fontSizeRem / 2}rem`,
      padding: `4rem 13rem`,
      borderRadius: '8rem',
      height: `${props.fontSizeRem}rem`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#FFF',
      fontWeight: 'bold',
    };
    if (props.color == null || props.color === 'green') {
      return {
        ...defaultStyle,
        borderColor: '#00C5C9',
      };
    }

    if (props.color === 'red') {
      return {
        ...defaultStyle,
        borderColor: '#AA3400',
      };
    }

    return {
      ...defaultStyle,
      borderColor: '#FFD600',
    };
  })();
  return <span style={style}>{props.children}</span>;
};

export default Label;
