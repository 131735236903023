import classes from './Arrow.module.scss';
import arrowLeft from '../../static/images/arrow-left.svg';
import arrowRight from '../../static/images/arrow-right.svg';
import { click } from '@testing-library/user-event/dist/click';

type Props = {
  direction: 'right' | 'left';
  click: () => void;
};
const Arrow = (props: Props) => {
  const imageMap = {
    right: arrowRight,
    left: arrowLeft,
  };

  return (
    <img
      className={classes.arrow}
      src={imageMap[props.direction]}
      onClick={() => props.click()}
      alt={`arrow-${props.direction}`}
    />
  );
};

export default Arrow;
