import { ActionWithKey, StateWithKey } from './state';

export const createReducer = <T,>() => {
  return (
    state: StateWithKey<T>,
    action: ActionWithKey<T>
  ): StateWithKey<T> => {
    switch (action.type) {
      case 'processing':
        return {
          status: action.type,
          data: null,
          error: null,
        };
      case 'success':
        return {
          data: {
            ...state.data,
            ...action.response,
          },
          error: null,
          status: action.type,
        };
      case 'error':
        return {
          data: null,
          error: action.error,
          status: action.type,
        };
      default:
        return state;
    }
  };
};
