import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import assetImage from '../../../src/static/images/asset.png';
import bankImage from '../../../src/static/images/bank.png';
import bitcoinImage from '../../../src/static/images/bitcoin.png';
import stockImage from '../../../src/static/images/stock.png';
import SimpleButton from '../../atoms/simple-button/SimpleButton';
import {
  FetchUserJobResponse,
  InstrumentType,
} from '../../hooks/reducer/UserJobReducer';
import InvestmentProduct from '../../organisms/investment-product/InvestmentProduct';
import classes from './InvestmentCard.module.scss';

type Props = {
  type: InstrumentType;
  userJob?: FetchUserJobResponse;
  isSelectableTerm?: boolean;
};

type State = 'NONE' | 'SELECTED' | 'UNSELECTED' | 'DOING';

export const investmentMap = {
  STOCK: {
    image: stockImage,
    label: 'かぶ',
    buttonLabel: '買う',
    url: '/stock',
  },
  CRYPTO: {
    image: bitcoinImage,
    label: 'かそうつうか',
    buttonLabel: '買う',
    url: '/crypto',
  },
  TRUST: {
    image: assetImage,
    label: 'とうしん',
    buttonLabel: '買う',
    url: '/trust',
  },
  BANK: {
    image: bankImage,
    label: 'ちょきん',
    buttonLabel: 'する',
    url: '/bank',
  },
};

const InvestmentCard = React.memo((props: Props) => {
  const navigate = useNavigate();
  const [state, setState] = useState<State>();

  useEffect(() => {
    if (props.isSelectableTerm == null) {
      return;
    }

    const state: State = (() => {
      if (props.isSelectableTerm) {
        if (props.userJob == null || props.userJob?.investments.length === 0) {
          return 'NONE';
        }

        if (props.userJob.investments[0].instrumentType === props.type) {
          return 'SELECTED';
        }

        return 'UNSELECTED';
      }

      return 'DOING';
    })();

    setState(state);
  }, [props]);

  return (
    <div
      className={classes.container}
      style={{
        backgroundImage: `url(${investmentMap[props.type].image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }}
    >
      {state === 'NONE' && (
        <>
          <div className={classes.title}>{investmentMap[props.type].label}</div>
          <div className={classes.button}>
            <SimpleButton
              fontSize='24rem'
              minHeight='40rem'
              minWidth='100rem'
              maxWidth='100rem'
              label={investmentMap[props.type].buttonLabel}
              onClick={() => navigate(investmentMap[props.type].url)}
            />
          </div>
        </>
      )}
      {state === 'UNSELECTED' && (
        <>
          <div className={classes.title}>{investmentMap[props.type].label}</div>
        </>
      )}
      {state === 'SELECTED' && (
        <>
          <div className={classes.selectedTitle}>
            {investmentMap[props.type].label}
          </div>
          <div className={classes.productArea}>
            {props.userJob?.investments.map((iv) => (
              <div className={classes.product}>
                <InvestmentProduct investment={iv} />
              </div>
            ))}
          </div>
          <div className={classes.selectedButton}>
            <SimpleButton
              fontSize='24rem'
              minHeight='48rem'
              minWidth='100%'
              maxWidth='100%'
              label='へんこうする'
              onClick={() => navigate(investmentMap[props.type].url)}
            />
          </div>
        </>
      )}
      {state === 'DOING' && (
        <>
          <div className={classes.selectedTitle}>
            {investmentMap[props.type].label}
          </div>
          <div className={classes.productArea}>
            {props.userJob?.investments.map((iv, index) => (
              <div
                className={classes.product}
                onClick={() => navigate(`/chart?index=${index}`)}
              >
                <InvestmentProduct investment={iv} isDoing={true} />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
});

export default InvestmentCard;
