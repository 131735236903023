import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../atoms/button/Button';
import TextForm, { TextFormResult } from '../../atoms/text-form/TextForm';
import { useLogin } from '../../hooks/reducer/LoginReducer';
import classes from './Login.module.scss';

type Form = {
  name: string;
  password: string;
};

const Login = () => {
  const [formValue, setFormValue] = useState<Form>({
    name: '',
    password: '',
  });

  const { loginState, login } = useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    if (loginState.status === 'success') {
      // TODO 直前の状態を保持しておき、ログイン後にルーティングする
      navigate('/job');
    }
  }, [loginState]);

  const handleInputChange = (args: TextFormResult) => {
    setFormValue((prevValues) => ({ ...prevValues, [args.name]: args.value }));
  };

  const onClickLoginButton = () => {
    login(formValue.name, formValue.password);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.title}>みらいい ウォレット</div>
        <div className={classes.formContainer}>
          <div className={classes.form}>
            <TextForm
              label='メールアドレス'
              type='text'
              name='name'
              color='white'
              page='login'
              value={formValue.name}
              onChange={handleInputChange}
            ></TextForm>
          </div>
          <div className={classes.form}>
            <TextForm
              label='パスワード'
              type='password'
              name='password'
              color='white'
              page='login'
              value={formValue.password}
              onChange={handleInputChange}
            ></TextForm>
          </div>
        </div>
        <div>
          <Button
            label='ログイン'
            onClick={onClickLoginButton}
            color='yellow'
          ></Button>
        </div>
      </div>
    </>
  );
};

export default Login;
