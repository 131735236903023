import { CSSProperties } from 'react';

export const getColorByPoint = (point?: number): CSSProperties => {
  if (point == null) {
    return { color: 'white' };
  }

  if (point > 0) {
    return { color: '#FF005B' };
  }

  if (point < 0) {
    return { color: 'green' };
  }

  return { color: 'white' };
};

export const getColorByInstrumentType = (
  type: string,
  zero?: boolean
): CSSProperties => {
  if (zero) {
    return { color: 'grey' };
  }

  if (type === 'STOCK') {
    return { color: '#58c2c7' };
  }

  if (type === 'TRUST') {
    return { color: '#F9D649' };
  }

  if (type === 'CRYPTO') {
    return { color: '#E34084' };
  }
  return { color: 'white' };
};

export const getBackColorByInstrumentType = (
  type: string,
  zero?: boolean
): CSSProperties => {
  if (zero) {
    return { background: 'grey' };
  }

  if (type === 'STOCK') {
    return { background: '#58c2c7' };
  }

  if (type === 'TRUST') {
    return { background: '#F9D649' };
  }

  if (type === 'CRYPTO') {
    return { background: '#E34084' };
  }
  return { background: 'white' };
};
