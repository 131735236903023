import {
  Dispatch,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useReducer,
} from 'react';
import { Action, State, createInitialState } from './state';
import {
  ChangeLoadingState,
  LoadingContext,
} from '../../organisms/loading/Loading';
import axios from 'axios';
import { ErrorHandlerContext } from '../../organisms/error-handler/ErrorHandler';
import { InstrumentType } from './UserJobReducer';

export type FetchStatusResponse = {
  statusId: string;
  instrumentType: string;
  clearCount: number;
  fromPoints: number;
  statusName: string;
};

type ContextType = {
  state: State<FetchStatusResponse>;
  dispatch?: Dispatch<Action<FetchStatusResponse>>;
  refetch?: (params: Params) => State<FetchStatusResponse>;
};

const reducer = (
  state: State<FetchStatusResponse>,
  action: Action<FetchStatusResponse>
): State<FetchStatusResponse> => {
  switch (action.type) {
    case 'processing':
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case 'success':
      return {
        data: action.response,
        error: null,
        status: action.type,
      };
    case 'error':
      return {
        data: null,
        error: action.error,
        status: action.type,
      };
    default:
      return state;
  }
};

type Params = {
  instrumentType: string;
};

export const initialState = createInitialState<FetchStatusResponse>();

export const FetchStatusContext = createContext<ContextType>({
  state: initialState,
});

let calling = false;
function fetchState(
  context: ContextType,
  changeLoadingState: ChangeLoadingState,
  handleError: (hasError: boolean, error?: unknown) => void,
  params: Params
) {
  const url = 'api/statuses';

  if (context.dispatch == null) {
    return context.state;
  }

  if (calling) {
    return context.state;
  }

  calling = true;
  context.dispatch({ type: 'processing', response: null, error: null });
  changeLoadingState(true);

  (async () => {
    try {
      const response = await axios.get(url, { params });
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: 'success',
        response: response.data.response,
        error: null,
      });
      calling = false;
      changeLoadingState(false);
    } catch (error) {
      handleError(true, error);
      calling = false;
      changeLoadingState(false);
    }
  })();
  return context.state;
}

export function FetchStateProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { changeLoadingState } = useContext(LoadingContext);
  const { handleError } = useContext(ErrorHandlerContext);

  const refetch = useCallback(
    (params: Params) =>
      fetchState({ state, dispatch }, changeLoadingState, handleError, params),
    []
  );

  return (
    <FetchStatusContext.Provider value={{ state, dispatch, refetch }}>
      {children}
    </FetchStatusContext.Provider>
  );
}
