import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isFirstJobStart, isJobEnd } from '../../common/utility/business.util';
import { FooterContext } from '../../organisms/footer/Footer';
import flat1Icon from '../../static/images/flat1-mirai-ina.png';
import flat2Icon from '../../static/images/flat2-mirai-ina.png';
import flat3Icon from '../../static/images/flat3-mirai-ina.png';
import sadIcon from '../../static/images/sad-mirai-ina.png';
import smileIcon from '../../static/images/smile-mirai-ina.png';
import { FetchCalendarStatusResponse } from '../reducer/CalendarStatusReducer';
import {
  FetchUserJobContext,
  FetchUserJobResponse,
} from '../reducer/UserJobReducer';
import { FetchUserContext, FetchUserResponse } from '../reducer/UserReducer';
import { createKey } from '../reducer/state';
import { useBusinessDate } from './useBusinessDate';
import { useCalendarStatus } from './useCalendarStatus';

export type UserJobType = {
  jobStatus?: JobStatus;
  userJob?: FetchUserJobResponse;
  prevUserJob?: FetchUserJobResponse;
  user?: FetchUserResponse;
  isSelectableTerm?: boolean;
  calendarStatus?: FetchCalendarStatusResponse;
  totalReturn?: number;
  businessDate?: Date;
};
export type JobStatus =
  | 'SELECTABLE'
  | 'JOB_ONLY'
  | 'SELECTED'
  | 'PROGRESS'
  | 'NOTHING';

// NowのuserJobと、今のtermの区分値を返す
export const useJob = (): UserJobType => {
  // SELECTABLE = 選択可能期間（まだ何も選ばれてない）
  // JOB_ONLY = 選択可能期間（ミラ割り当てだけDONE）
  // SELECTED = 選択可能期間（ミラ割り当ても商品購入もDONE）
  // PROGRESS = 運用期間
  // NOTHING = 運用期間（ミラ割り当て、商品購入がされてない）
  const [status, setStatus] = useState<
    'SELECTABLE' | 'JOB_ONLY' | 'SELECTED' | 'PROGRESS' | 'NOTHING'
  >();

  // 選択可能期間か否か
  const [isSelectableTerm, setIsSelectableTerm] = useState<boolean>();
  const [userJob, setUserJob] = useState<FetchUserJobResponse>();
  const [prevUserJob, setPrevUserJob] = useState<FetchUserJobResponse>();
  const [user, setUser] = useState<FetchUserResponse>();
  const [totalReturn, setTotalReturn] = useState<number>();
  const userJobContext = useContext(FetchUserJobContext);
  const userContext = useContext(FetchUserContext);
  const businessDate = useBusinessDate();
  const calendarStatus = useCalendarStatus();
  const [userJobKey, setUserJobKey] = useState<{
    prev: string;
    current: string;
  }>();
  const { setFooterMessages, setFooterIcon } = useContext(FooterContext);
  const pathname = useLocation().pathname;
  const navigate = useNavigate();

  useEffect(() => {
    setFooterMessages([]);
    userContext?.refetch?.();
  }, []);

  useEffect(() => {
    if (calendarStatus == null) {
      return;
    }

    const prevParams = {
      investmentTerm: calendarStatus.prevInvestmentTerm,
      withInvestments: true,
    };
    const currentParams = {
      investmentTerm: calendarStatus.currentInvestmentTerm,
      withInvestments: true,
    };

    const prevKey = createKey(prevParams);
    const currentKey = createKey(currentParams);
    setUserJobKey({ prev: prevKey, current: currentKey });
    userJobContext.refetch?.(currentParams);
    userJobContext.refetch?.(prevParams);
  }, [calendarStatus]);

  useEffect(() => {
    if (
      calendarStatus == null ||
      businessDate == null ||
      userJobKey == null ||
      userJobContext.state.data == null ||
      Object.keys(userJobContext.state.data).length !== 2 ||
      userContext.state.data == null
    ) {
      return;
    }

    const prevUserJob = userJobContext.state.data?.[userJobKey.prev]?.[0];
    const userJob = userJobContext.state.data?.[userJobKey.current]?.[0];
    const hasUserJob = userJob?.currentJobStatus === 'set';
    const hasInvestments =
      hasUserJob &&
      userJob?.investments != null &&
      userJob?.investments.length > 0;
    const selectable =
      new Date(calendarStatus.currentSelectableTermFrom) <= businessDate &&
      businessDate < new Date(calendarStatus.currentSelectableTermTo);

    setIsSelectableTerm(selectable);
    const status = (() => {
      if (selectable) {
        if (!hasUserJob) {
          return 'SELECTABLE';
        } else {
          if (hasInvestments) {
            return 'SELECTED';
          } else {
            return 'JOB_ONLY';
          }
        }
      } else {
        if (hasInvestments) {
          return 'PROGRESS';
        }
      }
      return 'NOTHING';
    })();

    setStatus(status);
    setUserJob(userJob);
    setPrevUserJob(prevUserJob);
    setUser(userContext.state.data?.[0]);
    const user = userContext.state.data?.[0];
    const totalReturn =
      (user?.totalReturnCryptoPoint ?? 0) +
      (user?.totalReturnStockPoint ?? 0) +
      (user?.totalReturnTrustPoint ?? 0);

    setTotalReturn(totalReturn);

    // 無限ループ防止
    if (
      userJob == null ||
      pathname === '/job-end' ||
      pathname === '/job-start'
    ) {
      return;
    }

    // 運用期間が始まって初めてのアクセスはジョブ開始ページに飛ぶ
    if (
      isFirstJobStart(
        status,
        calendarStatus.currentInvestmentTerm,
        businessDate,
        calendarStatus.currentAnnoucementDay
      )
    ) {
      navigate('/job-start');
      return;
    }

    const isPrevJobStatus = userJob?.prevJobStatus === 'set';
    const isCurrentJobStatus = userJob?.currentJobStatus === 'set';
    const yetPrevPublicationStatus = !(
      userJob?.prevPublicationStatus === 'published'
    );
    const yetCurrentPublicationStatus = !(
      userJob?.currentPublicationStatus === 'published'
    );

    if (isPrevJobStatus && yetPrevPublicationStatus) {
      navigate('/job-end?term=prev');
      return;
    } else if (
      isCurrentJobStatus &&
      yetCurrentPublicationStatus &&
      isJobEnd(businessDate, calendarStatus.currentAnnoucementDay)
    ) {
      navigate('/job-end?term=current');
      return;
    }
  }, [userJobContext.state, businessDate, userJobKey, userContext.state]);

  useEffect(() => {
    if (
      isSelectableTerm == null ||
      status == null ||
      businessDate == null ||
      calendarStatus == null
    ) {
      return;
    }

    if (isSelectableTerm) {
      if (status === 'SELECTABLE') {
        setFooterMessages([
          'ようこそウォレットへ！',
          'まずは 運用（うんよう）の 設定（せってい）を してみるミラ！',
        ]);
        setFooterIcon(flat2Icon);
      } else if (status === 'JOB_ONLY') {
        setFooterMessages([
          '「とうしん」と「かそうつうか」と「ちょきん」は 1つ、',
          '「かぶ」は 3つまで えらぶことが できるミラ！',
        ]);
        setFooterIcon(flat3Icon);
      } else if (status === 'SELECTED') {
        setFooterMessages([
          '運用（うんよう）が きまったミラ！',
          '開催（かいさい）が はじまるまで まつミラ！',
        ]);
        setFooterIcon(smileIcon);
      }
    } else {
      if (status === 'PROGRESS') {
        if (userJob == null) {
          return;
        }

        // 結果発表後
        if (isJobEnd(businessDate, calendarStatus.currentAnnoucementDay)) {
          //if (userJob.totalYield >= userJob.quantitativeGoal) {
          if (userJob.totalReturn > 0) {
            setFooterMessages([
              'スゴい！うまく「リターン」を もらうことが できたミラ！',
              'このちょうしで つぎも 増（ふ）やすミラ！',
            ]);
            setFooterIcon(smileIcon);
          } else {
            setFooterMessages([
              'おしい！運用（うんよう）のコツは「リスク」と「リターン」の バランス ミラ！',
              'つぎは 「リスク」を 減（へ）らすと いいかもしれないミラ！',
            ]);
            setFooterIcon(sadIcon);
          }
        } else if (userJob.totalReturn > 0) {
          setFooterMessages([
            'ミラが 増（ふ）えてる！',
            'このちょうしで 増えると いいミラ！',
          ]);
          setFooterIcon(smileIcon);
        } else if (userJob.totalReturn < 0) {
          setFooterMessages([
            'ミラが 減（へ）ってる、',
            'まだ、増（ふ）える かもしれないミラ！',
          ]);
          setFooterIcon(sadIcon);
        } else {
          setFooterMessages([
            'ミラが 変（か）わってない！',
            'ここから 増（ふ）えると いいミラ！',
          ]);
          setFooterIcon(flat1Icon);
        }
      } else if (status === 'NOTHING') {
        setFooterMessages([
          'いまは 運用（うんよう）していないミラ！',
          'つぎの 開催（かいさい）まで まってほしいミラ！',
        ]);
        setFooterIcon(flat1Icon);
      }
    }
  }, [userJob, isSelectableTerm, status]);

  return useMemo(
    () => ({
      jobStatus: status,
      userJob,
      user,
      isSelectableTerm,
      calendarStatus,
      prevUserJob,
      totalReturn,
      businessDate,
    }),
    [
      status,
      userJob,
      user,
      isSelectableTerm,
      calendarStatus,
      prevUserJob,
      businessDate,
    ]
  );
};
