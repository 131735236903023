import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import Modal from 'react-modal';
import classes from './ModalWrapper.module.scss';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ModalOpenContext = createContext<{
  isOpenState: { [key: string]: boolean };
  setIsOpen: (componentName: string, isOpen: boolean) => void;
}>({
  isOpenState: {},
  setIsOpen: (componentName: string, isOpen: boolean) => {},
});

export const ModalOpenProvider = ({ children }: { children: ReactNode }) => {
  const [isOpenState, setIsOpenState] = useState({});

  const handleSetIsOpen = (componentName: string, value: boolean) => {
    setIsOpenState((prevState) => ({
      ...prevState,
      [componentName]: value,
    }));
  };

  return (
    <ModalOpenContext.Provider
      value={{
        isOpenState,
        setIsOpen: handleSetIsOpen,
      }}
    >
      {children}
    </ModalOpenContext.Provider>
  );
};

export type ModalWrapperProps = {
  componentName: string;
  callback?: () => void;
  children: ReactNode;
  modalStyle?: {
    overlay?: { [key: string]: string };
    content?: { [key: string]: string };
  };
};

const ModalWrapper = (props: ModalWrapperProps) => {
  const { isOpenState, setIsOpen } = useContext(ModalOpenContext);

  const closeModal = () => {
    setIsOpen(props.componentName, false);
  };

  const customStyles = (() => {
    return {
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        opacity: '1.0',
        zIndex: '100',
        ...props?.modalStyle?.overlay,
      },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '50%',
        minHeight: '50%',
        backgroundColor: 'transparent',
        padding: '20rem',
        border: 'none',
        height: '70%',
        width: '70%',
        ...props?.modalStyle?.content,
      },
    };
  })();

  return (
    <>
      <Modal isOpen={isOpenState[props.componentName]} style={customStyles}>
        <div
          className={classes.modal}
          onClick={(event) => event.stopPropagation()}
        >
          <div className={classes.closeIcon} onClick={closeModal}>
            <FontAwesomeIcon icon={faCircleXmark} />
          </div>
          {props.children}
        </div>
      </Modal>
    </>
  );
};

export default ModalWrapper;
