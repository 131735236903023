import { InstrumentType, typeMapper } from '../../hooks/reducer/UserJobReducer';
import classes from './BadgeResultCard.module.scss';
import miraImage from '../../static/images/img_mira.png';
import SimpleButton from '../../atoms/simple-button/SimpleButton';
import { FetchUserResponse } from '../../hooks/reducer/UserReducer';
import searchIcon from '../../static/images/search.svg';
import { useNavigate } from 'react-router-dom';
import { FetchStatusResponse } from '../../hooks/reducer/StatusReducer';
import {
  getColorByInstrumentType,
  getColorByPoint,
} from '../../common/utility/style.util';
import { addComma, formatSign } from '../../common/utility/number.util';

import stock1 from '../../static/images/stock-badge/1.png';
import stock2 from '../../static/images/stock-badge/2.png';
import stock3 from '../../static/images/stock-badge/3.png';
import stock4 from '../../static/images/stock-badge/4.png';
import stock5 from '../../static/images/stock-badge/5.png';
import stock6 from '../../static/images/stock-badge/6.png';
import stock7 from '../../static/images/stock-badge/7.png';
import stock8 from '../../static/images/stock-badge/8.png';
import stock9 from '../../static/images/stock-badge/9.png';
import stock10 from '../../static/images/stock-badge/10.png';
import trust1 from '../../static/images/trust-badge/1.png';
import trust2 from '../../static/images/trust-badge/2.png';
import trust3 from '../../static/images/trust-badge/3.png';
import trust4 from '../../static/images/trust-badge/4.png';
import trust5 from '../../static/images/trust-badge/5.png';
import trust6 from '../../static/images/trust-badge/6.png';
import trust7 from '../../static/images/trust-badge/7.png';
import trust8 from '../../static/images/trust-badge/8.png';
import trust9 from '../../static/images/trust-badge/9.png';
import trust10 from '../../static/images/trust-badge/10.png';
import crypto1 from '../../static/images/crypto-badge/1.png';
import crypto2 from '../../static/images/crypto-badge/2.png';
import crypto3 from '../../static/images/crypto-badge/3.png';
import crypto4 from '../../static/images/crypto-badge/4.png';
import crypto5 from '../../static/images/crypto-badge/5.png';
import crypto6 from '../../static/images/crypto-badge/6.png';
import crypto7 from '../../static/images/crypto-badge/7.png';
import crypto8 from '../../static/images/crypto-badge/8.png';
import crypto9 from '../../static/images/crypto-badge/9.png';
import crypto10 from '../../static/images/crypto-badge/10.png';
import defaultBadge from '../../static/images/default-badge.png';

import Badge from '../../atoms/badge/Badge';

export const badgeMap = {
  STOCK: [
    stock1,
    stock2,
    stock3,
    stock4,
    stock5,
    stock6,
    stock7,
    stock8,
    stock9,
    stock10,
  ],
  TRUST: [
    trust1,
    trust2,
    trust3,
    trust4,
    trust5,
    trust6,
    trust7,
    trust8,
    trust9,
    trust10,
  ],
  CRYPTO: [
    crypto1,
    crypto2,
    crypto3,
    crypto4,
    crypto5,
    crypto6,
    crypto7,
    crypto8,
    crypto9,
    crypto10,
  ],
  BANK: [],
};

export type BadgeResultCardProps = {
  totalReturnPoint?: number;
  totalSuccessCount?: number;
  totalStatusCount?: number;
  statusName?: string;
  skillName?: string;
  instrumentType: InstrumentType;
  userData?: FetchUserResponse;
  status?: FetchStatusResponse[];
};

const BadgeResultCard = (props: BadgeResultCardProps) => {
  const navigate = useNavigate();

  const openBadgeResult = () => {
    navigate('/badge-result', {
      state: {
        badgeResult: props,
      },
    });
  };

  return (
    <div className={classes.productArea}>
      <div className={classes.productTitle}>
        {typeMapper[props.instrumentType]}
      </div>
      <div className={classes.totalMiraContent}>
        <img className={classes.miraIcon2} src={miraImage} alt={miraImage} />
        <div
          className={classes.totalReturnPoint}
          style={getColorByPoint(props.totalReturnPoint)}
        >
          {addComma(props.totalReturnPoint)}
        </div>
      </div>
      <div className={classes.dashed}></div>
      {props.instrumentType !== 'BANK' ? (
        <>
          <div className={classes.badgeContainer}>
            {props.totalStatusCount != null && (
              <Badge
                height='90rem'
                width='90rem'
                boxShadow='0 0 16rem 0'
                instrumentType={props.instrumentType}
                badge={
                  props.totalStatusCount === 0
                    ? defaultBadge
                    : badgeMap[props.instrumentType][props.totalStatusCount - 1]
                }
              />
            )}
            <div className={classes.badgeName}>{props.skillName}</div>
          </div>
          <div className={classes.statusContainer}>
            <div className={classes.statusCountContainer}>
              <div className={classes.statusCount}>
                <div
                  style={getColorByInstrumentType(
                    props.instrumentType,
                    props.totalStatusCount === 0
                  )}
                  className={classes.numerator}
                >
                  {props.totalStatusCount}
                </div>
                <div className={classes.denominator}>
                  /{props.status?.length}
                </div>
              </div>
              <div className={classes.button}>
                <SimpleButton
                  color='lightGreen'
                  minHeight='24rem'
                  minWidth='56rem'
                  fontSize='14rem'
                  label={'みる'}
                  onClick={() => openBadgeResult()}
                  leftIcon={searchIcon}
                />
              </div>
            </div>
          </div>
          <div className={classes.incrementCountContainer}>
            <span>ふやした回数</span>
            <span>{props.totalSuccessCount}</span>
          </div>
        </>
      ) : (
        <div className={classes.bankContainer}>
          <div className={classes.bankMessage}>
            ちょきんの
            <br />
            バッジは
            <br />
            ないよ
          </div>
        </div>
      )}
    </div>
  );
};

export default BadgeResultCard;
