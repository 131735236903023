import { useContext, useEffect, useMemo, useState } from 'react';
import { FetchBusinessCalendarContext } from '../reducer/BusinessCalendarReducer';

export const useBusinessDate = () => {
  const [businessDate, setBusinessDate] = useState<Date>();
  const businessCalendarContext = useContext(FetchBusinessCalendarContext);

  useEffect(() => {
    if (businessDate != null) {
      return;
    }

    businessCalendarContext?.refetch?.();
  }, []);

  useEffect(() => {
    const d = businessCalendarContext?.state?.data?.[0].businessDate;
    if (d == null) {
      return;
    }

    setBusinessDate(new Date(d));
  }, [businessCalendarContext.state]);

  return useMemo(() => businessDate, [businessDate]);
};
