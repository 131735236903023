import { memo, useContext, useEffect, useState } from 'react';
import { FetchCryptoContext } from '../../hooks/reducer/CryptoReducer';
import classes from './Crypto.module.scss';
import bitcoinIcon from '../../static/images/bitcoin.jpg';
import checkIcon from '../../static/images/check-circle-black.svg';
import JobHeader from '../../organisms/job-header/JobHeader';
import RiskReturnProfile from '../../molecules/risk-return-profile/RiskReturnProfile';
import SimpleButton from '../../atoms/simple-button/SimpleButton';
import InvestmentFooter from '../../organisms/investment-footer/InvestmentFooter';
import { useJob } from '../../hooks/custom/useJob';
import { Investment } from '../../hooks/reducer/UserJobReducer';

const Crypto = () => {
  const { state, refetch } = useContext(FetchCryptoContext);
  const [isSelected, setIsSelected] = useState(false);

  // refetchは初回、買う、リセットの時に実行したい。無限ループになってしまうため初回のみ実行させる
  useEffect(() => {
    if (refetch == null) {
      return;
    }
    refetch({ instrumentId: 'ALL' });
  }, []);

  // 運用ミラ全額を自動でFooterに設定する
  const { userJob } = useJob();
  const [totalInitialJobAllocatedPoint, setTotalInitialJobAllocatedPoint] =
    useState('0');
  // 運用ミラの設定が終わるまでFooterの描画をしない
  const [isReady, setIsReady] = useState(false);
  // 既に購入済みの場合は強調表示する
  const [investments, setInvestments] = useState<Investment>();
  const cardClassName =
    isSelected || investments != null
      ? `${classes.card} ${classes.selectedCard}`
      : classes.card;

  useEffect(() => {
    if (userJob == null) {
      return;
    }
    const totalInitialJobAllocatedPoint =
      userJob?.totalInitialJobAllocatedPoint.toString();
    setTotalInitialJobAllocatedPoint(totalInitialJobAllocatedPoint);
    setIsReady(true);
    setInvestments(
      userJob.investments.find((v) => v.instrumentType === 'CRYPTO')
    );
  }, [userJob]);

  return (
    <>
      <div className={classes.container}>
        <JobHeader title='かそうつうかを買う' previousUrl='/job' />
        <div className={classes.contents}>
          {state.data?.map((crypto) => {
            return (
              <>
                <div className={cardClassName}>
                  {investments != null ? (
                    <div className={classes.imageContainer}>
                      <img
                        className={classes.image}
                        src={bitcoinIcon}
                        alt='icon'
                      />
                      <div className={classes.overlay}>
                        <span className={classes.purchased}>購入済み</span>
                      </div>
                    </div>
                  ) : (
                    <img
                      className={classes.defaultImage}
                      src={bitcoinIcon}
                      alt='icon'
                    />
                  )}
                  <div className={classes.titleArea}>
                    <div className={classes.title}>{crypto.name}</div>
                  </div>
                  <div className={classes.labelArea}>
                    <div className={classes.label}>
                      <RiskReturnProfile
                        profile={crypto.riskProfile}
                        type='RISK'
                      />
                    </div>
                    <div className={classes.label}>
                      <RiskReturnProfile
                        profile={crypto.returnProfile}
                        type='RETURN'
                      />
                    </div>
                  </div>
                  <div className={classes.sentence}>{crypto.profile}</div>
                  {/*
                  <div className={classes.button}>
                    <SimpleButton
                      color='whiteNoShadow'
                      label='えらぶ'
                      minWidth='180rem'
                      minHeight='48rem'
                      fontSize='24rem'
                      leftIcon={checkIcon}
                      onClick={() => {
                        setIsSelected(!isSelected);
                      }}
                    />
                  </div>
                  */}
                </div>
                {isReady && (
                  <InvestmentFooter
                    title={`このかそうつうかを買う？`}
                    type={'CRYPTO'}
                    instrumentName={`ビットミラ`}
                    instrumentId={crypto.instrumentId}
                    passedAllocatedPoint={totalInitialJobAllocatedPoint}
                  />
                )}
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default memo(Crypto);
