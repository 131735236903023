import { CSSProperties, useEffect, useState } from 'react';
import classes from './SimpleButton.module.scss';

type ColorValiation =
  | 'lightGreen'
  | 'transparent'
  | 'white'
  | 'whiteNoShadow'
  | 'red'
  | 'transparentGreen';
export type SimpleButtonProps = {
  label: string;
  onClick: () => void;
  color?: ColorValiation;
  leftIcon?: string;
  rightIcon?: string;
  minWidth?: string;
  maxWidth?: string;
  minHeight?: string;
  fontSize?: string;
  disabled?: boolean;
};

const classMap = {
  lightGreen: classes.lightGreen,
  transparent: classes.transparent,
  white: classes.white,
  red: classes.red,
  transparentGreen: classes.transparentGreen,
  whiteNoShadow: classes.whiteNoShadow,
};

const SimpleButton = (props: SimpleButtonProps) => {
  const cl = classMap[props.color ?? 'lightGreen'];

  const style = (() => {
    const tmp: CSSProperties = {};

    if (props.minWidth != null) {
      tmp.minWidth = props.minWidth;
    }

    if (props.maxWidth != null) {
      tmp.maxWidth = props.maxWidth;
    }

    if (props.minHeight != null) {
      tmp.minHeight = props.minHeight;
    }

    if (props.fontSize != null) {
      tmp.fontSize = props.fontSize;
    }

    return tmp;
  })();

  const iconStyle = (() => {
    const tmp: CSSProperties = {};

    // fontサイズに併せて画像サイズも変えてあげる
    if (props.fontSize != null) {
      tmp.height = props.fontSize;
    }

    return tmp;
  })();

  return (
    <button
      className={`${cl} ${props.disabled ? classes.disabled : ''}`}
      style={style}
      onClick={() => {
        if (props.disabled) {
          return;
        }
        props.onClick();
      }}
    >
      {props.leftIcon != null && (
        <img
          className={classes.leftIcon}
          style={iconStyle}
          src={props.leftIcon}
          alt='simple-button-left-icon'
        />
      )}
      <span>{props.label}</span>
      {props.rightIcon != null && (
        <img
          className={classes.rightIcon}
          style={iconStyle}
          src={props.rightIcon}
          alt='simple-button-right-icon'
        />
      )}
    </button>
  );
};

export default SimpleButton;
