import { Fragment, memo } from 'react';
import RiskReturnProfile from '../../molecules/risk-return-profile/RiskReturnProfile';
import classes from './TrustDetails.module.scss';
import assetIcon from '../../static/images/asset-icon.png';
import trustIcon from '../../static/images/trust.jpg';
import bondIcon from '../../static/images/bond.jpg';
import balanceIcon from '../../static/images/balance.jpg';
import SimpleButton from '../../atoms/simple-button/SimpleButton';
import checkIcon from '../../static/images/check-circle-black.svg';
import { FetchTrustResponse } from '../../hooks/reducer/TrustReducer';
import { words } from 'lodash';

export type TrustDetailsProps = {
  trust: FetchTrustResponse;
  onTrustClick: () => void;
  isSelected: boolean;
  isPurchased: boolean;
};

const TrustDetails = (props: TrustDetailsProps) => {
  const cardClassName =
    props.isSelected || props.isPurchased
      ? `${classes.card} ${classes.selectedCard}`
      : classes.card;
  return (
    <>
      <div className={cardClassName}>
        {props.isPurchased ? (
          <div className={classes.imageContainer}>
            <img
              className={classes.image}
              src={
                props.trust.instrumentId === 'T0001'
                  ? trustIcon
                  : props.trust.instrumentId === 'T0002'
                  ? bondIcon
                  : props.trust.instrumentId === 'T0003'
                  ? balanceIcon
                  : assetIcon
              }
              alt='icon'
            />
            <div className={classes.overlay}>
              <span className={classes.purchased}>購入済み</span>
            </div>
          </div>
        ) : (
          <img
            className={classes.image}
            src={
              props.trust.instrumentId === 'T0001'
                ? trustIcon
                : props.trust.instrumentId === 'T0002'
                ? bondIcon
                : props.trust.instrumentId === 'T0003'
                ? balanceIcon
                : assetIcon
            }
            alt='icon'
          />
        )}
        <div className={classes.titleArea}>
          <div className={classes.title}>
            {props.trust.name.split(' ').join('')}
            {/*props.trust.name.split(' ').map((word, index) => (
              <Fragment key={index}>
                {index > 0 ? <br /> : null}
                {word}
              </Fragment>
            ))*/}
          </div>
        </div>
        <div className={classes.labelArea}>
          <div className={classes.label}>
            <RiskReturnProfile profile={props.trust.riskProfile} type='RISK' />
          </div>
          <div className={classes.label}>
            <RiskReturnProfile
              profile={props.trust.returnProfile}
              type='RETURN'
            />
          </div>
        </div>
        <div className={classes.sentence}>{props.trust.profile}</div>
        <div className={classes.button}>
          <SimpleButton
            color='whiteNoShadow'
            label='えらぶ'
            minWidth='100%'
            minHeight='48rem'
            fontSize='24rem'
            leftIcon={checkIcon}
            onClick={props.onTrustClick}
          />
        </div>
      </div>
    </>
  );
};

export default memo(TrustDetails);
