import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useJob } from '../../hooks/custom/useJob';
import {
  FetchTrustContext,
  FetchTrustResponse,
} from '../../hooks/reducer/TrustReducer';
import { Investment } from '../../hooks/reducer/UserJobReducer';
import InvestmentFooter from '../../organisms/investment-footer/InvestmentFooter';
import JobHeader from '../../organisms/job-header/JobHeader';
import TrustDetails from '../trust-details/TrustDetails';
import classes from './Trust.module.scss';

const Trust = () => {
  const { state, refetch } = useContext(FetchTrustContext);
  const [selectedTrust, setSelectedTrust] = useState<FetchTrustResponse | null>(
    null
  );
  useEffect(() => {
    if (refetch == null) {
      return;
    }
    refetch({ instrumentId: 'ALL' });
  }, []);

  const handleTrustClick = useCallback((trust: FetchTrustResponse) => {
    setSelectedTrust(trust);
  }, []);

  // 運用ミラ全額を自動でFooterに設定する
  const { userJob } = useJob();
  const [totalInitialJobAllocatedPoint, setTotalInitialJobAllocatedPoint] =
    useState('0');
  // 運用ミラの設定が終わるまでFooterの描画をしない
  const [isReady, setIsReady] = useState(false);
  // 投信を既に購入済みの場合は強調表示する
  const [investments, setInvestments] = useState<Investment>();

  useEffect(() => {
    if (userJob == null) {
      return;
    }
    // 初期ポイントの設定
    const totalInitialJobAllocatedPoint =
      userJob?.totalInitialJobAllocatedPoint.toString();
    setTotalInitialJobAllocatedPoint(totalInitialJobAllocatedPoint);
    setIsReady(true);
    // 投信が既に購入されている場合は一致するinstrumentIdを持つカードを強調表示する
    setInvestments(
      userJob.investments.find((v) => v.instrumentType === 'TRUST')
    );
  }, [userJob]);

  return (
    <>
      <div className={classes.container}>
        <JobHeader title='とうしんを買う' previousUrl='/job' />
        <div className={classes.contents}>
          {state.data?.map((trust) => {
            return (
              <div className={classes.card}>
                <TrustDetails
                  key={trust.instrumentId}
                  trust={trust}
                  onTrustClick={() => handleTrustClick(trust)}
                  isSelected={trust === selectedTrust}
                  isPurchased={investments?.instrumentId === trust.instrumentId}
                />
              </div>
            );
          })}
        </div>
        {isReady && (
          <InvestmentFooter
            title='このとうしんを買う？'
            type='TRUST'
            instrumentName={selectedTrust?.name || investments?.instrumentName}
            instrumentId={
              selectedTrust?.instrumentId || investments?.instrumentId
            }
            passedAllocatedPoint={totalInitialJobAllocatedPoint}
            errorMessage={
              investments &&
              selectedTrust?.instrumentId !== investments.instrumentId
                ? '持っているファンドをリセットすると買えるよ！'
                : undefined
            }
          />
        )}
        ;
      </div>
    </>
  );
};

export default memo(Trust);
