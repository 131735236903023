import { useEffect, useState } from 'react';
import { FetchStockResponse } from '../../hooks/reducer/StockReducer';
import classes from './StockDetail.module.scss';

import image from '../../static/images/bitcoin.png';
import Arrow from '../../atoms/arrow/Arrow';
import InvestmentFooter, {
  ActionType,
} from '../investment-footer/InvestmentFooter';
import { useJob } from '../../hooks/custom/useJob';
import { useNavigate } from 'react-router-dom';

type Props = {
  stockList: FetchStockResponse[];
  instrumentId: string;
};
const StockDetail = (props: Props) => {
  const [selected, setSelected] = useState<FetchStockResponse>();
  const navigate = useNavigate();

  useEffect(() => {
    const selectIndex = props.stockList.findIndex(
      (s) => s.instrumentId === props.instrumentId
    );

    if (selectIndex == null || selectIndex < 0) {
      return;
    }

    setSelected(props.stockList[selectIndex]);
  }, []);

  const next = (instrumentId?: string) => {
    const selectIndex = props.stockList.findIndex(
      (s) => s.instrumentId === instrumentId
    );

    if (selectIndex < 0) {
      return;
    }

    if (selectIndex < props.stockList.length - 1) {
      setSelected(props.stockList[selectIndex + 1]);
    } else {
      setSelected(props.stockList[0]);
    }
  };

  const prev = (instrumentId?: string) => {
    const selectIndex = props.stockList.findIndex(
      (s) => s.instrumentId === instrumentId
    );

    if (selectIndex < 0) {
      return;
    }

    if (selectIndex === 0) {
      setSelected(props.stockList[props.stockList.length - 1]);
    } else {
      setSelected(props.stockList[selectIndex - 1]);
    }
  };

  return (
    <div className={classes.container}>
      <Arrow direction='left' click={() => prev(selected?.instrumentId)} />
      <div className={classes.body}>
        <div className={classes.header}>
          <img
            src={`api/stocks/get-logofile?imageFile=${selected?.imageFile}`}
          />
          <div className={classes.title}>{selected?.name}</div>
        </div>
        <div className={classes.sentence}>
          <div className={classes.label}>何をしている会社？</div>
          <div className={classes.text}>{selected?.profileSummary}</div>
        </div>
        <div className={classes.sentence}>
          <div className={classes.label}>どんな会社？</div>
          <div className={classes.text}>{selected?.profile}</div>
        </div>
      </div>
      <Arrow direction='right' click={() => next(selected?.instrumentId)} />
      <InvestmentFooter
        title='この会社のかぶをいくら買う？'
        type='STOCK'
        instrumentId={selected?.instrumentId}
        instrumentName={selected?.name}
      />
    </div>
  );
};

export default StockDetail;
