import axios from 'axios';
import type { ReactNode } from 'react';
import { createContext, useContext, useReducer } from 'react';
import { ErrorHandlerContext } from '../../organisms/error-handler/ErrorHandler';
import {
  ChangeLoadingState,
  LoadingContext,
} from '../../organisms/loading/Loading';
import { Action, State, createInitialState, createKey } from './state';

export type FetchEventHistoryResponse = {
  instrumentId: string;
  eventProbability: number;
  eventId: number;
  effectRate: number;
  eventProfile: string;
  instrumentType: string;
  effectType: string;
  eventDate: string;
};

type ContextType<T> = {
  state: State<T>;
  dispatch?: React.Dispatch<Action<T>>;
  refetch?: (params: Params) => State<T>;
  buy?: (request: T) => State<T>;
};

// レデューサー
const reducer = (
  state: State<FetchEventHistoryResponse>,
  action: Action<FetchEventHistoryResponse>
): State<FetchEventHistoryResponse> => {
  switch (action.type) {
    case 'processing':
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case 'success':
      return {
        data: action.response,
        error: null,
        status: action.type,
      };
    case 'error':
      return {
        data: null,
        error: action.error,
        status: action.type,
      };
    default:
      return state;
  }
};

export const initialState = createInitialState<FetchEventHistoryResponse>();

// コンテキストの作成
export const FetchEventHistoryContext = createContext<
  ContextType<FetchEventHistoryResponse>
>({
  state: initialState,
});

type Params = {
  instrumentType: string;
  investmentTerm: string;
};

let calling: { [key: string]: boolean } = {};

function fetchEventHistory(
  context: ContextType<FetchEventHistoryResponse>,
  changeLoadingState: ChangeLoadingState,
  handleError: (hasError: boolean, error?: unknown) => void,
  params: Params
) {
  const url = 'api/event-history';

  if (context.dispatch == null) {
    return context.state;
  }

  const key = createKey(params);

  if (key in calling && calling[key]) {
    return context.state;
  }

  calling[key] = true;
  context.dispatch({ type: 'processing', response: null, error: null });
  changeLoadingState(true);

  (async () => {
    try {
      const response = await axios.get(url, {
        params,
      });
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: 'success',
        response: response.data.response,
        error: null,
      });
      calling[key] = false;
      changeLoadingState(false);
    } catch (error) {
      handleError(true, error);
      calling[key] = false;
      changeLoadingState(false);
    }
  })();
  return context.state;
}

export function FetchEventHistoryProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { changeLoadingState } = useContext(LoadingContext);
  const { handleError } = useContext(ErrorHandlerContext);

  const refetch = (params: Params) =>
    fetchEventHistory(
      { state, dispatch },
      changeLoadingState,
      handleError,
      params
    );

  return (
    <FetchEventHistoryContext.Provider value={{ state, dispatch, refetch }}>
      {children}
    </FetchEventHistoryContext.Provider>
  );
}
