import { ReactNode, createContext, useReducer, useState } from 'react';
import { InstrumentType } from './UserJobReducer';

type ResultType = {
  type: InstrumentType;
  instrumentName: string;
  investPoint: number;
  remainingPoint?: number;
  remainingNumber?: number;
};

type ContextType = {
  result?: ResultType;
  setResult?: (result: ResultType) => void;
};

export const initialState = {};

// コンテキストの作成
export const InvestResultContext = createContext<ContextType>({});

export function InvestResultProvider({ children }: { children: ReactNode }) {
  const [result, setResult] = useState<ResultType>();

  return (
    <InvestResultContext.Provider value={{ result, setResult }}>
      {children}
    </InvestResultContext.Provider>
  );
}
