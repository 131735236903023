import classes from './Dashboard.module.scss';
import { toMDWithJapanese } from '../../common/utility/date.util';
import { useContext, useEffect, useState } from 'react';
import { FetchCalendarStatusContext } from '../../hooks/reducer/CalendarStatusReducer';
import { useJob } from '../../hooks/custom/useJob';
import ActiveHeader from '../../organisms/active-header/ActiveHeader';
import Footer from '../../organisms/footer/Footer';
import arrowLeft from '../../static/images/arrow-left.svg';
import arrowRight from '../../static/images/arrow-right.svg';
import Header from '../../organisms/header/Header';
import Job from '../job/Job';
import { useNavigate } from 'react-router-dom';
import Arrow from '../../atoms/arrow/Arrow';
import Record from '../record/Record';
import JobResult from '../job-result/JobResult';

import NotInvestModal from '../../organisms/not-invest-modal/NotInvestModal';
import {
  createNotInvestKey,
  getLocalStorage,
} from '../../common/utility/localstorage.util';

type DashboardProps = {
  type: 'job' | 'record' | 'result';
};

const dashboardTypes = ['job', 'record', 'result'];

const Dashboard = (props: DashboardProps) => {
  const navigate = useNavigate();
  const { jobStatus, calendarStatus } = useJob();
  const [showNotInvestModal, setShowNotInvestModal] = useState(false);
  const index = dashboardTypes.indexOf(props.type);
  const prev = () => {
    if (index === 0) {
      navigate(`/${dashboardTypes[dashboardTypes.length - 1]}`);
    } else {
      navigate(`/${dashboardTypes[index - 1]}`);
    }
  };
  const next = () => {
    if (index === dashboardTypes.length - 1) {
      navigate(`/${dashboardTypes[0]}`);
    } else {
      navigate(`/${dashboardTypes[index + 1]}`);
    }
  };
  useEffect(() => {
    if (jobStatus == null || calendarStatus == null) {
      return;
    }
    //const key = createNotInvestKey(calendarStatus.currentInvestmentTerm);
    //const storage = getLocalStorage(key);
    //if (jobStatus === 'NOTHING' && storage == null) {
    if (jobStatus === 'NOTHING') {
        setShowNotInvestModal(true);
    }
  }, [jobStatus, calendarStatus]);

  return (
    <>
      {showNotInvestModal && calendarStatus != null && (
        <div className={classes.notInvestModal}>
          <NotInvestModal
            calendarStatus={calendarStatus}
            callback={() => setShowNotInvestModal(false)}
          />
        </div>
      )}
      <div className={classes.container}>
        <div className={classes.header}>
          <Header title='ウォレット' />
        </div>
        <div className={classes.activeHeader}>
          <ActiveHeader />
        </div>
        <div className={classes.contents}>
          <Arrow direction='left' click={prev} />
          <div className={classes.body}>
            {index === 0 && <Job />}
            {index === 1 && <Record />}
            {index === 2 && <JobResult />}
          </div>
          <Arrow direction='right' click={next} />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Dashboard;
