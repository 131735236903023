import classes from './NotInvestModal.module.scss';
import teacher from '../../static/images/teacher-point.png';
import closeButton from '../../static/images/close-button.png';
import { FetchCalendarStatusResponse } from '../../hooks/reducer/CalendarStatusReducer';
import { toYYYYMDWithJapanese } from '../../common/utility/date.util';
import { useLocation } from 'react-router-dom';
import {
  createNotInvestKey,
  saveLocalStorage,
} from '../../common/utility/localstorage.util';

type Props = {
  calendarStatus: FetchCalendarStatusResponse;
  callback: () => void;
};
const NotInvestModal = (prop: Props) => {
  const location = useLocation();
  const onClose = () => {
    //const key = createNotInvestKey(prop.calendarStatus.currentInvestmentTerm);
    //saveLocalStorage(key, true);
    prop.callback();
  };
  
  // 現在のパスが '/job' でなければ何も表示しない
  if (location.pathname !== '/job') {
    onClose();
  }

  return (
    <div className={classes.outer}>
      <div className={classes.container}>
        <img
          className={classes.closeButton}
          src={closeButton}
          alt={closeButton}
          onClick={onClose}
        />

        <div className={classes.textArea}>
          <div className={classes.line}>いまは うんよう中だよ！</div>
          <div className={classes.line}>
            <span className={classes.highlight}>
              {toYYYYMDWithJapanese(prop.calendarStatus.nextSelectableTermFrom)}
            </span>
            から
            <span className={classes.highlight}>
              {toYYYYMDWithJapanese(prop.calendarStatus.nextSelectableTermTo)}
            </span>
            まで
          </div>
          <div className={classes.line}>せっていが できるから</div>
          <div className={classes.line}>それまで まっていてね！</div>
        </div>
        <div className={classes.imageArea}>
          <img src={teacher} alt={teacher} />
        </div>
      </div>
    </div>
  );
};

export default NotInvestModal;
