import { useEffect, useState } from 'react';
import { FetchStockResponse } from '../../hooks/reducer/StockReducer';
import { FetchUserJobResponse } from '../../hooks/reducer/UserJobReducer';
import classes from './StockCard.module.scss';
import moneyIcon from '../../static/images/money.svg';
import { setSelectionRange } from '@testing-library/user-event/dist/utils';

type Props = {
  stock: FetchStockResponse;
  userJob?: FetchUserJobResponse;
  selected?: boolean;
  onClick: (stock: FetchStockResponse) => void;
};

const StockCard = (props: Props) => {
  const [hasStock, setHasStock] = useState(false);
  const [selected, setSelected] = useState(false);
  const textMap = {
    risk: {
      HIGH: 'ハイリスク',
      MIDDLE: 'ミドルリスク',
      LOW: 'ローリスク',
    },
    return: {
      HIGH: 'ハイリターン',
      MIDDLE: 'ミドルリターン',
      LOW: 'ローリターン',
    },
  };

  const labelMap = {
    HIGH: classes.high,
    MIDDLE: classes.middle,
    LOW: classes.low,
  };

  useEffect(() => {
    if (props.userJob == null || props.userJob.currentJobStatus === 'unset') {
      return;
    }

    if (
      props.userJob.investments.find(
        (inv) =>
          inv.instrumentType === props.stock.instrumentType &&
          inv.instrumentId === props.stock.instrumentId
      ) != null
    ) {
      setHasStock(true);
    }
  }, [props.userJob]);

  return (
    <div
      className={props.selected ? classes.selected : classes.unselected}
      onClick={() => props.onClick(props.stock)}
    >
      <div className={classes.container}>
        {hasStock && <div className={classes.hasStock}>購入済み</div>}
        {hasStock && (
          <div className={classes.investMira}>
            <img
              className={classes.image}
              src={moneyIcon}
              alt='stock-card-money'
            />
            <span className={classes.text}>
              {
                props.userJob?.investments.find(
                  (i) =>
                    i.instrumentType === props.stock.instrumentType &&
                    i.instrumentId === props.stock.instrumentId
                )?.initialAllocatedPoint
              }
            </span>
          </div>
        )}
        <div
          className={classes.image}
          style={{
            backgroundImage: `url('api/stocks/get-logofile?imageFile=${props.stock.imageFile}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
        {/* <div className={classes.name}>{props.stock.name}</div>　*/}
        </div>
        <div className={classes.profile}>
          <div
            className={`${labelMap[props.stock.riskProfile]} ${
              classes.riskLabel
            }`}
          >
            {textMap.risk[props.stock.riskProfile]}
          </div>
          <div
            className={`${labelMap[props.stock.returnProfile]} ${
              classes.returnLabel
            }`}
          >
            {textMap.return[props.stock.returnProfile]}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockCard;
