import { ReactNode, createContext, useContext, useState } from 'react';
import classes from './Loading.module.scss';

export type ChangeLoadingState = (flag: boolean) => void;

export const LoadingContext = createContext<{
  loadingState: boolean;
  changeLoadingState: ChangeLoadingState;
}>({
  loadingState: false,
  changeLoadingState: (flag: boolean) => {},
});

export const LoadingProvider = ({ children }: { children: ReactNode }) => {
  const [loadingState, setLoaingState] = useState(false);
  let processingCount = 0;

  const changeLoadingState = (flag: boolean) => {
    if (flag) {
      processingCount++;
      setLoaingState(flag);
      return;
    }

    processingCount--;
    if (processingCount !== 0) {
      return;
    }
    setTimeout(() => {
      if (processingCount === 0) {
        setLoaingState(flag);
      }
    }, 1000);
  };

  return (
    <LoadingContext.Provider value={{ loadingState, changeLoadingState }}>
      {children}
    </LoadingContext.Provider>
  );
};
const Loading = () => {
  // const { loadingState } = useContext(LoadingContext);

  // Loadingを無効にと言われたので...
  const loadingState = false;

  return (
    <>
      {loadingState && (
        <div className={classes.containerOuter}>
          <div className={classes.container}>
            <div className={classes.spinnerBox}>
              <div className={classes.solarSystem}>
                <div className={`${classes.earthOrbit} ${classes.orbit}`}>
                  <div className={`${classes.planet}`}></div>
                  <div className={`${classes.venusOrbit} ${classes.orbit}`}>
                    <div className={`${classes.planet}`}></div>
                    <div className={`${classes.mercuryOrbit} ${classes.orbit}`}>
                      <div className={`${classes.planet}`}></div>
                      <div className={classes.sun}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loading;
