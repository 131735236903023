import { useEffect, useState } from 'react';
import { useJob } from '../../hooks/custom/useJob';
import { InstrumentType, Investment } from '../../hooks/reducer/UserJobReducer';
import InvestmentCard from '../../molecules/investment-card/InvestmentCard';
import classes from './InvestmentSetting.module.scss';

type SelectInvestment = {
  type: InstrumentType;
  investments: Investment[];
};

const INITIAL_ORDER: InstrumentType[] = ['STOCK', 'TRUST', 'CRYPTO', 'BANK'];
const InvestmentSetting = () => {
  const { userJob, isSelectableTerm } = useJob();
  const [selectInvestment, setSelectInvestment] = useState<SelectInvestment>();
  const [instrumetTypeList, setInstrumentTypeList] =
    useState<InstrumentType[]>(INITIAL_ORDER);

  useEffect(() => {
    setInstrumentTypeList(INITIAL_ORDER);
    if (userJob == null) {
      return;
    }
    if (userJob.investments.length > 0) {
      setSelectInvestment({
        type: userJob.investments[0].instrumentType,
        investments: userJob.investments,
      });
      setInstrumentTypeList(
        INITIAL_ORDER.slice().sort((a, b) =>
          a === userJob.investments[0].instrumentType ? -1 : 1
        )
      );
    }
  }, [userJob]);

  return (
    <div className={classes.container}>
      {selectInvestment == null ? (
        <div className={classes.investment}>
          {instrumetTypeList.map((type) => {
            return (
              <div className={classes.card}>
                <InvestmentCard
                  type={type}
                  userJob={userJob}
                  isSelectableTerm={isSelectableTerm}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <div className={classes.investment}>
          <div className={classes.selected}>
            <div className={classes.selectedCard}>
              <InvestmentCard
                type={instrumetTypeList[0]}
                userJob={userJob}
                isSelectableTerm={isSelectableTerm}
              />
            </div>
          </div>
          <div className={classes.unselected}>
            {instrumetTypeList.map((type, index) => {
              if (index > 0) {
                return (
                  <div className={classes.unselectedCard}>
                    <InvestmentCard
                      type={type}
                      userJob={userJob}
                      isSelectableTerm={isSelectableTerm}
                    />
                  </div>
                );
              }
              return <></>;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default InvestmentSetting;
