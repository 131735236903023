import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { CSSProperties, memo, useContext, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useLocation, useNavigate } from 'react-router-dom';
import Arrow from '../../atoms/arrow/Arrow';
import SimpleButton from '../../atoms/simple-button/SimpleButton';
import { getColorByPoint } from '../../common/utility/style.util';
import { useCalendarStatus } from '../../hooks/custom/useCalendarStatus';
import { useJob } from '../../hooks/custom/useJob';
import {
  FetchEventHistoryContext,
  FetchEventHistoryResponse,
} from '../../hooks/reducer/EventHistoryReducer';
import { FetchUserInstrumentChartContext } from '../../hooks/reducer/UserInstrumentChartReducer';
import InvestmentTerm from '../../molecules/investment-term/InvestmentTerm';
import News from '../../molecules/news/News';
import moneyIcon from '../../static/images/money.svg';
import classes from './ChartWrapper.module.scss';
import { addComma, formatSign } from '../../common/utility/number.util';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ChartWrapper = memo(() => {
  const urlParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const fetchUserInstrumentChartContext = useContext(
    FetchUserInstrumentChartContext
  );
  const fetchEventHistoryContext = useContext(FetchEventHistoryContext);
  const calendarStatus = useCalendarStatus();
  const userJob = useJob();

  const [x, setX] = useState<string[]>();
  const [yMax, setYMax] = useState<number>();
  const [yMin, setYMin] = useState<number>();
  const [term, setTerm] = useState<Date[]>();
  const [allocatedPoint, setAllocatedPoint] = useState<number[]>([]);
  const [currentPoint, setCurrentPoint] = useState<(number | null)[]>([]);
  const [newsPoint, setNewsPoint] = useState<(number | null)[]>([]);
  const [news, setNews] = useState<(FetchEventHistoryResponse | null)[]>();
  const [options, setOptions] = useState<ChartOptions<'line'>>();
  const [data, setData] = useState<ChartData<'line'>>({ datasets: [] });
  const [showNews, setShowNews] = useState<FetchEventHistoryResponse | null>(
    null
  );

  const [investmentReturnSummary, setInvestmentReturnSummary] = useState<{
    point: number;
    style: CSSProperties;
  }>();
  const [investmentYieldSummary, setInvestmentYieldSummary] = useState<{
    point: number;
    style: CSSProperties;
  }>();
  const [currentPointSummary, setCurrentPointSummary] = useState<{
    point: number;
    style: CSSProperties;
  }>();

  const [selectIndex, setSelectIndex] = useState<number>(
    Number(urlParams.get('index'))
  );

  useEffect(() => {
    if (calendarStatus == null || userJob == null) {
      return;
    }

    const from = new Date(calendarStatus.currentInvestmentTermFrom);
    //from.setDate(from.getDate() - 1);
    from.setDate(from.getDate());

    // 最終日まで履歴を出すため変更
    //const to = new Date(calendarStatus.currentInvestmentTermTo);
    const to = new Date(calendarStatus.currentAnnoucementDay);

    const term: Date[] = [];

    while (from < to) {
      term.push(new Date(from));
      from.setDate(from.getDate() + 1);
    }

    // 開催期間の配列
    setTerm(term);

    const investment = userJob.userJob?.investments[selectIndex];
    if (investment == null) {
      return;
    }

    fetchUserInstrumentChartContext.refetch?.({
      instrumentId: investment.instrumentId,
      instrumentType: investment.instrumentType,
      investmentTerm: calendarStatus.currentInvestmentTerm,
    });

    fetchEventHistoryContext.refetch?.({
      instrumentType: investment.instrumentType,
      investmentTerm: calendarStatus.currentInvestmentTerm,
    });
  }, [calendarStatus, userJob, selectIndex]);

  useEffect(() => {
    if (
      term == null ||
      fetchEventHistoryContext.state.data == null ||
      fetchUserInstrumentChartContext.state.data == null
    ) {
      return;
    }

    // 開催期間の配列と、ポイントのマッピング
    const currentPointList = term.map((d) => {
      const point =
        fetchUserInstrumentChartContext.state.data?.[0].pointHistory;
      const match = point?.find(
        (p) => new Date(p.pricingDate).getTime() === d.getTime()
      );
      if (match != null) {
        return match.currentPoint;
      }

      return null;
    });

    // 初期投資ポイントを開催期間中の配列とlengthを合わせる
    const initialAllocatedPoint =
      fetchUserInstrumentChartContext.state.data?.[0].chartSummary
        .initialAllocatedPoint ?? 0;
    const allocatedPointList = term.map((d) => {
      return initialAllocatedPoint;
    });

    // y軸のスケール設定
    // 初期ポイントと現在ポイントの最大値を1.3倍してY軸のスケールにする
    const yMax =
      currentPointList.reduce((prev, cur) => {
        if (cur == null) {
          return prev;
        }

        if (prev == null) {
          return cur;
        }

        return prev > cur ? prev : cur;
      }, initialAllocatedPoint) ?? 0;

    const yMin =
      currentPointList.reduce((prev, cur) => {
        if (cur == null) {
          return prev;
        }

        if (prev == null) {
          return cur;
        }

        return prev > cur ? cur : prev;
      }, initialAllocatedPoint) ?? 0;

    const diff = yMax * 1.0005 - yMax;
    const yScaleMax = Math.ceil((yMax + diff) / 100) * 100;
    const yScaleMin = Math.floor((yMin - diff) / 100) * 100;

    // クリックされたときにインデックスからNewsを取得して表示するために用意
    const newsList: (FetchEventHistoryResponse | null)[] = [];
    const newsPointList = term.map((d, index) => {
      const news = fetchEventHistoryContext.state.data?.filter(
        (n) =>
          n.instrumentId ===
          userJob.userJob?.investments[selectIndex].instrumentId
      );
      const match = news?.find(
        (n) => new Date(n.eventDate).getTime() === d.getTime()
      );

      if (match != null) {
        newsList.push(match);
        return currentPointList[index] ?? 0;
      }

      newsList.push(null);
      return null;
    });

    const currentPoint =
      fetchUserInstrumentChartContext.state.data[0].chartSummary.currentPoint;
    setCurrentPointSummary({
      point: currentPoint,
      style: getColorByPoint(currentPoint - initialAllocatedPoint),
    });

    const investmentReturn =
      fetchUserInstrumentChartContext.state.data[0].chartSummary
        .investmentReturn;
    setInvestmentReturnSummary({
      point: investmentReturn,
      style: getColorByPoint(investmentReturn),
    });

    const investmentYield =
      fetchUserInstrumentChartContext.state.data[0].chartSummary
        .investmentYield;
    setInvestmentYieldSummary({
      point: investmentYield,
      style: getColorByPoint(investmentYield),
    });

    setX(term.map((d) => d.getDate().toString()));
    setYMax(yScaleMax);
    setYMin(yScaleMin);
    setAllocatedPoint(allocatedPointList);
    setCurrentPoint(currentPointList);
    setNewsPoint(newsPointList);
    setNews(newsList);
  }, [
    term,
    fetchEventHistoryContext.state,
    fetchUserInstrumentChartContext.state,
  ]);

  useEffect(() => {
    if (
      x == null ||
      yMax == null ||
      yMin == null ||
      allocatedPoint == null ||
      currentPoint == null ||
      newsPoint == null ||
      news == null
    ) {
      return;
    }
    const op: ChartOptions<'line'> = {
      interaction: {
        mode: 'point',
        intersect: false,
      },

      onClick: (e: any, item: any) => {
        if (item?.[0]?.datasetIndex === 0) {
          setShowNews(news[item?.[0]?.index]);
        }
      },
      responsive: true,
      scales: {
        x: {
          ticks: {
            color: 'white',
            font: {
              size: 20,
              family: 'M PLUS Rounded 1c',
            },
          },

          display: true,
          grid: {
            color: '#58C2C7',
          },
        },
        y: {
          display: true,
          max: yMax,
          min: yMin,
          ticks: {
            color: 'white',
            font: {
              size: 20,
              family: 'M PLUS Rounded 1c',
            },
          },
          grid: {
            display: false,
          },
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    };

    const dt: ChartData<'line'> = {
      labels: x, // x軸のラベルの配列
      datasets: [
        {
          data: newsPoint,
          pointBackgroundColor: '#58C2C7',
          pointRadius: 12,
          pointHoverRadius: 20,
          label: 'news',
        },
        {
          data: currentPoint,
          backgroundColor: currentPointSummary?.style.color,
          pointHoverRadius: 0,
          borderWidth: 8,
          borderColor: currentPointSummary?.style.color, // グラフの棒の色
        },
        {
          label: '買ったとき', // 凡例
          data: allocatedPoint, // データの配列(labelsと要素数同じ)
          backgroundColor: '#00F5A0', // グラフの棒の色
          borderColor: '#00F5A0', // グラフの棒の色
          pointHoverRadius: 0,
          borderDash: [8, 8],
        },
      ],
    };
    setOptions(op);
    setData(dt);
  }, [x, yMax, yMin, allocatedPoint, currentPoint, newsPoint, news]);

  const prev = () => {
    if (selectIndex === 0) {
      const lastIndex = (userJob.userJob?.investments.length ?? 1) - 1;
      setSelectIndex(lastIndex);
    } else {
      setSelectIndex(selectIndex - 1);
    }
  };
  const next = () => {
    const lastIndex = (userJob.userJob?.investments.length ?? 1) - 1;
    if (selectIndex === lastIndex) {
      setSelectIndex(0);
    } else {
      setSelectIndex(selectIndex + 1);
    }
  };

  return (
    <>
      {showNews != null && (
        <div className={classes.newsOuter}>
          <div className={classes.newsInner}>
            <News
              data={showNews}
              has={true}
              onClick={() => setShowNews(null)}
              largeStyle={true}
            />
          </div>
        </div>
      )}
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.left}>
            <SimpleButton
              color='transparentGreen'
              label={'もどる'}
              fontSize='20rem'
              onClick={() => navigate(-1)}
            />
            <div className={classes.name}>
              {userJob.userJob?.investments[selectIndex].instrumentName}
            </div>
          </div>
          <div className={classes.right}>
            <img
              className={classes.icon}
              src={moneyIcon}
              alt='chart-money-icon'
            />
            <div>
              <div className={classes.above}>
                <span
                  className={classes.currentPoint}
                  style={currentPointSummary?.style}
                >
                  {addComma(currentPointSummary?.point)}
                </span>
              </div>
              <div className={classes.below}>
                <span
                  className={classes.subPoint}
                  style={investmentReturnSummary?.style}
                >
                  {formatSign(investmentReturnSummary?.point)}
                </span>
                <span
                  className={classes.subPoint}
                  style={investmentYieldSummary?.style}
                >
                  {formatSign(investmentYieldSummary?.point, true)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.contents}>
          <div className={classes.investmentTerm}>
            <InvestmentTerm investmentPoint={allocatedPoint[0]} />
          </div>
          <div className={classes.chartContainer}>
            <Arrow direction={'left'} click={prev} />
            <div className={classes.chart}>
              <Line
                options={options}
                data={data}
                style={{ height: '100%', width: '100%' }}
              />
              ;
            </div>
            <Arrow direction={'right'} click={next} />
          </div>
          <div className={classes.regend}>
            <div className={classes.block}>
              <span className={classes.buyRegend}></span>
              <span className={classes.text}>買ったとき</span>
            </div>
            <div className={classes.block}>
              <span className={classes.newsRegend}>・</span>
              <span className={classes.text}>ニュース・できごと</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default ChartWrapper;
