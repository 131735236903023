import { useParams, useSearchParams } from 'react-router-dom';
import classes from './StockDetails.module.scss';
import StockDetail from '../../organisms/stock-detail/StockDetail';
import { useContext, useEffect } from 'react';
import { FetchStockContext } from '../../hooks/reducer/StockReducer';
import JobHeader from '../../organisms/job-header/JobHeader';

const StockDetails = () => {
  const [searchParams] = useSearchParams();
  const instrumentId = searchParams.get('instrumentId');
  const { state, refetch } = useContext(FetchStockContext);

  useEffect(() => {
    if (refetch == null) {
      return;
    }

    refetch({ instrumentId: 'ALL' });
  }, [refetch]);

  return (
    <div className={classes.container}>
      <JobHeader title='かぶを買う' previousUrl='/stock' />
      {instrumentId != null && state.data != null && (
        <div className={classes.stockDetail}>
          <StockDetail instrumentId={instrumentId} stockList={state.data} />
        </div>
      )}
    </div>
  );
};

export default StockDetails;
