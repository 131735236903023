import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../atoms/button/Button';
import classes from './ActiveHeader.module.scss';

const ActiveHeader = () => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();

  const menuMap = [
    {
      key: '/job',
      value: 'うんよう',
    },
    {
      key: '/record',
      value: 'きろく',
    },
    {
      key: '/result',
      value: 'せいせき',
    },
  ];

  const onClickButton = (key: string) => {
    if (key === pathname) {
      return;
    }

    navigate(key);
  };

  return (
    <>
      {menuMap.map((menu) => {
        return (
          <div className={classes.button}>
            <Button
              label={menu.value}
              color={menu.key === pathname ? 'green' : 'gray'}
              onClick={() => onClickButton(menu.key)}
              minWidth='15rem'
            />
          </div>
        );
      })}
    </>
  );
};

export default ActiveHeader;
