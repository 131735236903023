import axios from 'axios';
import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useReducer } from 'react';
import { ErrorHandlerContext } from '../../organisms/error-handler/ErrorHandler';
import {
  ChangeLoadingState,
  LoadingContext,
} from '../../organisms/loading/Loading';
import { Action, State, createInitialState } from './state';

type FetchUserJobChartResponse = {
  investmentTerm: string;
  totalInitialInvestmentAllocatedPoint: number;
  totalCurrentPoint: number;
  totalReturn: number;
  totalYield: number;
  jobHistory: {
    pricingDate: string;
    aggregatePoint: number;
  }[];
};

export type ContextType = {
  state: State<FetchUserJobChartResponse>;
  dispatch?: React.Dispatch<Action<FetchUserJobChartResponse>>;
  refetch?: (params: Params) => State<FetchUserJobChartResponse>;
};

// レデューサー
const reducer = (
  state: State<FetchUserJobChartResponse>,
  action: Action<FetchUserJobChartResponse>
): State<FetchUserJobChartResponse> => {
  switch (action.type) {
    case 'processing':
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case 'success':
      return {
        data: action.response.map((res) => {
          return res;
        }),
        error: null,
        status: action.type,
      };
    case 'error':
      return {
        data: null,
        error: action.error,
        status: action.type,
      };
    default:
      return state;
  }
};

export const initialState = createInitialState<FetchUserJobChartResponse>();

// コンテキストの作成
export const FetchUserJobChartContext = createContext<ContextType>({
  state: initialState,
});

let calling = false;
function fetchChart(
  context: ContextType,
  changeLoadingState: ChangeLoadingState,
  handleError: (hasError: boolean, error?: unknown) => void,
  params: Params
) {
  const url = 'api/user-chart/job';

  if (context.dispatch == null) {
    return context.state;
  }

  if (calling) {
    return context.state;
  }

  calling = true;
  context.dispatch({ type: 'processing', response: null, error: null });
  changeLoadingState(true);

  (async () => {
    try {
      const response = await axios.get(url, { params });
      if (context == null || context.dispatch == null) {
        return;
      }
      context.dispatch({
        type: 'success',
        response: response.data.response,
        error: null,
      });
      calling = false;
      changeLoadingState(false);
    } catch (error) {
      handleError(true, error);
      calling = false;
      changeLoadingState(false);
    }
  })();
  return context.state;
}

type Params = {
  investmentTerm: string;
};

export function FetchUserJobChartProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { changeLoadingState } = useContext(LoadingContext);
  const { handleError } = useContext(ErrorHandlerContext);

  const refetch = useCallback(
    (params: Params) =>
      fetchChart({ state, dispatch }, changeLoadingState, handleError, params),
    []
  );

  return (
    <FetchUserJobChartContext.Provider value={{ state, dispatch, refetch }}>
      {children}
    </FetchUserJobChartContext.Provider>
  );
}
