import { addComma, formatSign } from '../../common/utility/number.util';
import { Investment } from '../../hooks/reducer/UserJobReducer';
import minusImage from '../../static/images/minus-graph.svg';
import moneyIcon from '../../static/images/money.svg';
import plusImage from '../../static/images/plus-graph.svg';
import zeroImage from '../../static/images/zero-graph.svg';
import classes from './InvestmentProduct.module.scss';

type Props = {
  investment: Investment;
  isDoing?: true;
};
const InvestmentProduct = (props: Props) => {
  const getClass = (value: number) => {
    if (value === 0) {
      return classes.zero;
    }

    if (value > 0) {
      return classes.plus;
    }

    return classes.minus;
  };

  const getImage = (value: number) => {
    if (value === 0) {
      return zeroImage;
    }

    if (value > 0) {
      return plusImage;
    }

    return minusImage;
  };

  return (
    <div className={classes.container}>
      {props.isDoing ? (
        <div className={classes.doing}>
          <div className={classes.left}>{props.investment.instrumentName}</div>
          <div className={classes.right}>
            <div className={classes.pointBlock}>
              <div className={classes.above}>
                <img
                  className={classes.icon}
                  src={moneyIcon}
                  alt='investment-product-money'
                />
                <div className={classes.mira}>
                  {addComma(props.investment.currentPoint)}
                </div>
              </div>
              <div className={classes.below}>
                <span
                  className={`${getClass(props.investment.investmentReturn)} ${
                    classes.mira
                  }`}
                >
                  {formatSign(props.investment.investmentReturn)}
                </span>
                <span
                  className={`${getClass(props.investment.investmentYield)} ${
                    classes.mira
                  }`}
                >
                  {formatSign(props.investment.investmentYield, true)}
                </span>
              </div>
            </div>
            <div className={classes.graph}>
              <img src={getImage(props.investment.investmentReturn)} />
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.setting}>
          <div className={classes.left}>{props.investment.instrumentName}</div>
          <div className={classes.right}>
            <img
              className={classes.icon}
              src={moneyIcon}
              alt='investment-product-money'
            />
            <div className={classes.mira}>
              {props.investment.initialAllocatedPoint}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvestmentProduct;
