import { addComma, formatSign } from '../../common/utility/number.util';
import { getColorByPoint } from '../../common/utility/style.util';
import { FetchUserJobResponse } from '../../hooks/reducer/UserJobReducer';
import moneyIcon from '../../static/images/money.svg';
import classes from './ResultCard.module.scss';

type Props = {
  userJob?: FetchUserJobResponse;
  isCancel?: boolean;
};

const ResultCard = (props: Props) => {
  return (
    <div className={classes.result}>
      <div className={classes.line}>
        <div className={classes.label}>うんよう</div>
        <div className={classes.point}>
          <img src={moneyIcon} />
          <div>
            {addComma(props.userJob?.totalInitialInvestmentAllocatedPoint)}
          </div>
        </div>
      </div>
      <div className={classes.line}>
        <div className={classes.label}>けっか</div>
        <div className={classes.point}>
          <img src={moneyIcon} />
          <div
            style={getColorByPoint(
              props.isCancel ? 0 : props.userJob?.totalReturn
            )}
          >
            {addComma(props.isCancel ? 0 : props.userJob?.totalCurrentPoint)}
          </div>
        </div>
      </div>
      <div className={classes.line}>
        <div className={classes.label}>ふえたミラ</div>
        <div className={classes.point}>
          <div
            style={getColorByPoint(
              props.isCancel ? 0 : props.userJob?.totalReturn
            )}
          >
            {formatSign(props.isCancel ? 0 : props.userJob?.totalReturn)}
          </div>
        </div>
      </div>
      <div className={classes.line}>
        <div className={classes.label}>ふえたミラ率</div>
        <div className={classes.point}>
          <div
            style={getColorByPoint(
              props.isCancel ? 0 : props.userJob?.totalYield
            )}
          >
            {formatSign(props.isCancel ? 0 : props.userJob?.totalYield, true)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultCard;
