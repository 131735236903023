import { CSSProperties, useState } from 'react';
import classes from './TextForm.module.scss';

export type TextFormProps = {
  type?: 'text' | 'number' | 'password';
  label?: string;
  name: string;
  value: string;
  disabled?: boolean;
  color?: 'white' | 'black';
  page?: 'job-setting' | 'investment-footer' | 'login';
  maxWidth?: string;
  validator?: (value: string) => { status: boolean; message?: string };
  onChange: (args: TextFormResult) => void;
};

export type TextFormResult = {
  status: boolean;
  value: string;
  name: string;
};
type ValidatorResult = {
  status: boolean;
  message?: string;
};

const TextForm = (props: TextFormProps) => {
  const inputType = props.type ? props.type : 'text';

  const [value, setValue] = useState(props.value);
  const [result, setResult] = useState<ValidatorResult>({ status: true });

  const change = (value: string) => {
    const validatorResult = props.validator?.(value) ?? { status: true };
    setValue(value);
    setResult(validatorResult);
    props.onChange({ status: validatorResult.status, value, name: props.name });
  };

  const createStyle = () => {
    const tmp: CSSProperties = {
      backgroundColor: '#0a0d10',
      color: 'white',
      padding: '24rem 32rem',
      fontSize: '24rem',
      fontWeight: '800',
      lineHeight: '150%',
    };

    if (props.color != null && props.color === 'black') {
      tmp.backgroundColor = '#0a0d10';
      tmp.color = 'white';
    }

    if (props.color != null && props.color === 'white') {
      tmp.backgroundColor = 'white';
      tmp.color = 'black';
    }

    if (props.page != null && props.page === 'investment-footer') {
      tmp.fontSize = '36rem';
      tmp.padding = '0 24rem';
      tmp.fontWeight = '800';
      tmp.lineHeight = '100%';
    }

    if (props.page != null && props.page === 'login') {
      tmp.fontSize = '24rem';
      tmp.padding = '12rem 24rem';
    }

    if (props.page != null && props.page === 'job-setting') {
      tmp.padding = '16rem';
    }

    if (props.maxWidth != null) {
      tmp.maxWidth = props.maxWidth;
    }

    return tmp;
  };

  return (
    <div className={classes.container}>
      <label>{props.label}</label>
      <input
        style={createStyle()}
        name={props.name}
        type={inputType}
        value={props.value}
        disabled={props.disabled ?? false}
        onChange={(e) => change(e.target.value)}
      />
    </div>
  );
};

export default TextForm;
