import axios from 'axios';
import type { ReactNode } from 'react';
import { createContext, useContext, useReducer } from 'react';
import { ErrorHandlerContext } from '../../organisms/error-handler/ErrorHandler';
import { LoadingContext } from '../../organisms/loading/Loading';
import { Action, State, createInitialState } from './state';

type SsoLoginResponse = {
  status: number;
  result: boolean;
};

const initialState = createInitialState<SsoLoginResponse>();

// コンテキストの作成
export const SsoLoginContext =
  createContext<State<SsoLoginResponse>>(initialState);

// レデューサー
const reducer = (
  state: State<SsoLoginResponse>,
  action: Action<SsoLoginResponse>
): State<SsoLoginResponse> => {
  switch (action.type) {
    case 'processing':
      return {
        status: action.type,
        data: null,
        error: null,
      };
    case 'success':
      return {
        data: action.response,
        error: null,
        status: action.type,
      };
    case 'error':
      return {
        data: null,
        error: action.error,
        status: action.type,
      };
    default:
      return state;
  }
};

// カスタムフック
export function useSsoLogin() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { changeLoadingState } = useContext(LoadingContext);
  const { handleError } = useContext(ErrorHandlerContext);

  const ssoLogin = (payload: { payload: string }) => {
    const url = 'api/sso/login';
    dispatch({ type: 'processing', response: null, error: null });
    changeLoadingState(true);
    (async () => {
      try {
        const response = await axios.post(url, payload);
        dispatch({ type: 'success', response: response.data, error: null });
        changeLoadingState(false);
      } catch (error) {
        dispatch({ type: 'error', response: null, error: [] });
        handleError(true, error);
        changeLoadingState(false);
      }
    })();
  };

  return { ssoLoginState: state, ssoLogin };
}

// FetchDataProviderコンポーネント
export function SsoLoginProvider({ children }: { children: ReactNode }) {
  const { ssoLoginState } = useSsoLogin();
  return (
    <SsoLoginContext.Provider value={ssoLoginState}>
      {children}
    </SsoLoginContext.Provider>
  );
}
