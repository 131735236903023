import { useContext, useEffect, useState } from 'react';
import { PostUserRecordResponse } from '../../hooks/reducer/PostUserRecordReducer';
import backgroundImage from '../../static/images/skill-badge-background.png';
import classes from './SkillBadge.module.scss';
import { FetchStatusContext } from '../../hooks/reducer/StatusReducer';
import { badgeMap } from '../../molecules/badge-result/BadgeResultCard';
import { InstrumentType, typeMapper } from '../../hooks/reducer/UserJobReducer';

type Props = {
  userRecord?: PostUserRecordResponse;
};

const SkillBadge = (props: Props) => {
  const statusContext = useContext(FetchStatusContext);
  const [clearCount, setClearCount] = useState<number>();

  useEffect(() => {
    if (props.userRecord == null) {
      return;
    }

    if (props.userRecord.totalSuccessStockCount != null) {
      setClearCount(props.userRecord.totalSuccessStockCount);
    }
    if (props.userRecord.totalSuccessTrustCount != null) {
      setClearCount(props.userRecord.totalSuccessTrustCount);
    }
    if (props.userRecord.totalSuccessCryptoCount != null) {
      setClearCount(props.userRecord.totalSuccessCryptoCount);
    }
  }, [props.userRecord]);

  const getBadge = (record?: PostUserRecordResponse) => {
    let index = 0;

    const targetStatus = statusContext.state.data?.filter(
      (d) => d.instrumentType === record?.instrumentType
    );

    if (targetStatus == null || record == null) {
      return '';
    }

    targetStatus.forEach((d, i) => {
      if (d.clearCount === clearCount) {
        index = i;
      }
    });

    return badgeMap[record.instrumentType][index];
  };

  const getText = (record?: PostUserRecordResponse) => {
    if (record == null) {
      return '';
    }

    return `${typeMapper[record.instrumentType]}
          で${clearCount}回ふやした結果、ゲットしました！`;
  };

  useEffect(() => {
    statusContext?.refetch?.({ instrumentType: 'ALL' });
  }, []);

  return (
    <div
      className={classes.container}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className={classes.title}>スキルバッジ ゲット！</div>
      <img
        className={classes.badge}
        src={getBadge(props.userRecord)}
        alt={getBadge(props.userRecord)}
      />
      <div className={classes.sentence}>
        <div className={classes.status}>{props.userRecord?.statusName}</div>
        <div className={classes.statusSummary}>{getText(props.userRecord)}</div>
      </div>
    </div>
  );
};

export default SkillBadge;
