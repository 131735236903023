import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Label from '../../atoms/label/Label';
import SimpleButton from '../../atoms/simple-button/SimpleButton';
import TextForm from '../../atoms/text-form/TextForm';
import { addComma } from '../../common/utility/number.util';
import { useJob } from '../../hooks/custom/useJob';
import { CreateUserJobContext } from '../../hooks/reducer/UserJobReducer';
import leftVectorImage from '../../static/images/left-vector-white.svg';
import moneyImage from '../../static/images/money.svg';
import resetImage from '../../static/images/reset.svg';
import rightVectorImage from '../../static/images/right-vector-black.svg';
import classes from './JobSetting.module.scss';

const JobSetting = () => {
  const { jobStatus, userJob, user } = useJob();
  const createContext = useContext(CreateUserJobContext);
  const navigate = useNavigate();
  const [quantitativeGoal, setQuantitativeGoal] = useState<string>('0');
  const [allocatedPoint, setAllocatedPoint] = useState<string>('1');
  const [qualitativeGoal, setQualitativeGoal] = useState('ミラを ふやしたい');

  const [alreadySetting, setAlreadySetting] = useState(false);

  const validate = () => {
    if (userJob == null || user == null) {
      return;
    }

    if (alreadySetting) {
      return;
    }

    if (Number(allocatedPoint) < 0) {
      return 'MINUS_VALUE_IS_INVALID';
    }

    if (Number(allocatedPoint) > user?.currentPoint) {
      return 'MIRA_IS_SHORTAGE';
    }
  };

  useEffect(() => {
    if (userJob == null) {
      return;
    }

    if (jobStatus == null) {
      return;
    }

    setAlreadySetting(jobStatus !== 'SELECTABLE');

    if (userJob.qualitativeGoal != null && userJob.qualitativeGoal !== '') {
      setQualitativeGoal(userJob.qualitativeGoal);
    }

    if (userJob.totalInitialJobAllocatedPoint != null) {
      setAllocatedPoint(userJob.totalInitialJobAllocatedPoint.toString());
    }
    if (userJob.quantitativeGoal != null) {
      setQuantitativeGoal(userJob.quantitativeGoal.toString());
    }

    validate();
  }, [userJob, jobStatus]);

  useEffect(() => {
    if (createContext.state?.status === 'success') {
      createContext?.dispatch?.({
        type: 'processing',
        response: null,
        error: null,
      });
      navigate(-1);
    }
  }, [createContext.state]);

  const submit = () => {
    createContext.create?.({
      qualitativeGoal,
      quantitativeGoal: Number(quantitativeGoal),
      allocatedPoint: Number(allocatedPoint),
    });
  };

  const resetJob = () => {
    navigate('/job-reset');
  };

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.contents}>
          <div className={classes.title}>うんよう　せってい</div>
          <div className={`${classes.line} ${classes.holdMira}`}>
            <div className={classes.label}>もっているミラ</div>
            <img src={moneyImage} />
            <span>{addComma(user?.currentPoint)}</span>
          </div>
          <div className={`${classes.line} ${classes.parts}`}>
            <div className={classes.label}>うんよう</div>
            <div className={classes.content}>
              <div className={classes.line}>
                {validate() === 'MIRA_IS_SHORTAGE' && (
                  <div className={classes.balloon}>
                    <span className={classes.error}>ミラが足りないよ！</span>
                  </div>
                )}
                {validate() === 'MINUS_VALUE_IS_INVALID' && (
                  <div className={classes.balloon}>
                    <span className={classes.error}>マイナスはダメだよ！</span>
                  </div>
                )}
                <div className={classes.textLeft}>ミラを</div>
                <TextForm
                  type='number'
                  maxWidth='300rem'
                  value={allocatedPoint}
                  name={'allocatedPoint'}
                  disabled={alreadySetting}
                  page='job-setting'
                  onChange={(args) => setAllocatedPoint(args.value)}
                />
                <div className={classes.textRight}>つかう</div>
              </div>
            </div>
          </div>
          <div className={classes.parts}>
            <div className={classes.line}>
              <div className={classes.label}>ていりょう目標</div>
              <div className={classes.content}>
                <div className={classes.line}>
                  <div className={classes.textLeft}>ミラを</div>
                  <TextForm
                    type='number'
                    maxWidth='150rem'
                    value={quantitativeGoal}
                    name={'quantitativeGoal'}
                    disabled={alreadySetting}
                    page='job-setting'
                    onChange={(args) => setQuantitativeGoal(args.value)}
                  />
                  <div className={classes.textRight}>%ふやす</div>
                </div>
              </div>
            </div>
            <div className={`${classes.line} ${classes.optionArea}`}>
              {!['0', ''].includes(allocatedPoint) &&
                !['0', ''].includes(quantitativeGoal) && (
                  <div className={classes.content}>
                    {allocatedPoint}ミラを{quantitativeGoal}%ふやすと
                    {Math.floor(
                      Number(allocatedPoint) *
                        (1 + Number(quantitativeGoal) / 100)
                    )}
                    ミラになるよ
                  </div>
                )}
            </div>
          </div>
          <div className={`${classes.line} ${classes.parts}`}>
            <div className={classes.label}>ていせい目標</div>
            <div className={classes.content}>
              <select
                className={classes.select}
                value={qualitativeGoal}
                disabled={alreadySetting}
                onChange={(args) =>
                  setQualitativeGoal(args.currentTarget.value)
                }
              >
                <option value='ミラを ふやしたい'>ミラを ふやしたい</option>
                <option value='ジョブを そだてたい'>ジョブを そだてたい</option>
                <option value='おカネについて くわしくなりたい'>
                  おカネについて くわしくなりたい
                </option>
                <option value='おこづかいを ふやすための さんこうにしたい'>
                  おこづかいを ふやすための さんこうにしたい
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className={classes.buttonArea}>
          <div className={classes.button}>
            <SimpleButton
              leftIcon={leftVectorImage}
              color='transparent'
              label='もどる'
              fontSize='24rem'
              minHeight='45rem'
              minWidth='170rem'
              maxWidth='170rem'
              onClick={() => navigate(-1)}
            />
          </div>
          {alreadySetting && (
            <div className={classes.button}>
              <SimpleButton
                leftIcon={resetImage}
                color='red'
                label='リセット'
                fontSize='24rem'
                minHeight='45rem'
                minWidth='170rem'
                maxWidth='170rem'
                onClick={resetJob}
              />
            </div>
          )}
          <div className={classes.button}>
            <SimpleButton
              label='すすむ'
              disabled={alreadySetting}
              rightIcon={rightVectorImage}
              fontSize='24rem'
              minHeight='45rem'
              minWidth='170rem'
              maxWidth='170rem'
              onClick={submit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobSetting;
