import axios from 'axios';
import { ChangeLoadingState } from '../../organisms/loading/Loading';

export type ErrorResponse = string;

export type StateWithKey<T = object> =
  | InitialState
  | ProcessingState
  | SuccessStateWithKey<T>
  | ErrorState;

export type State<T = object> =
  | InitialState
  | ProcessingState
  | SuccessState<T>
  | ErrorState;

type InitialState = {
  status: null;
  data: null;
  error: null;
};

type ProcessingState = {
  status: 'processing';
  data: null;
  error: null;
};

type SuccessStateWithKey<T = object> = {
  status: 'success';
  data: { [key: string]: T[] };
  error: null;
};
type SuccessState<T = object> = {
  status: 'success';
  data: T[];
  error: null;
};

type ErrorState = {
  status: 'error';
  data: null;
  error: ErrorResponse[];
};

export type Action<T> = ProcessingAction | SuccessAction<T> | ErrorAction;
export type ActionWithKey<T> =
  | ProcessingAction
  | SuccessActionWithKey<T>
  | ErrorAction;

type ProcessingAction = {
  type: 'processing';
  response: null;
  error: null;
};

type SuccessAction<T = object> = {
  type: 'success';
  response: T[];
  error: null;
};

type SuccessActionWithKey<T = object> = {
  type: 'success';
  response: { [key: string]: T[] };
  error: null;
};

export type ErrorAction = {
  type: 'error';
  response: null;
  error: ErrorResponse[];
};

export const createInitialState = <T>(): State<T> => {
  return {
    status: null,
    data: null,
    error: null,
  };
};

export const createInitialStateWithKey = <T>(): StateWithKey<T> => {
  return {
    status: null,
    data: null,
    error: null,
  };
};

export const createKey = (params: object) => {
  return JSON.stringify(params);
};

type ContextType<T, U> = {
  state: StateWithKey<T>;
  dispatch?: React.Dispatch<ActionWithKey<T>>;
  refetch?: (params: U) => StateWithKey<T>;
};

const calling: { [key: string]: { [key: string]: boolean } } = {};
export const request = <T, U extends object>(
  type: 'GET' | 'POST' | 'PUT' | 'DELTE',
  url: string,
  context: ContextType<T, U>,
  changeLoadingState: ChangeLoadingState,
  handleError: (hasError: boolean, error?: unknown) => void,
  params: U,
  callback?: (data: any) => any
) => {
  if (context.dispatch == null) {
    return context.state;
  }

  const key = createKey(params);
  if (calling?.[url]?.[key]) {
    return context.state;
  }

  if (calling?.[url] == null) {
    calling[url] = {};
  }

  calling[url][key] = true;
  context.dispatch({ type: 'processing', response: null, error: null });
  changeLoadingState(true);

  const method = () => {
    if (type === 'GET') {
      return axios.get(url, { params });
    }

    return axios.get(url, { params });
  };

  (async () => {
    try {
      const response = await method();
      if (context == null || context.dispatch == null) {
        return;
      }

      const res = response.data.response.map((d: any) => {
        if (callback != null) {
          return callback(d);
        }

        return d;
      }) as T[];
      context.dispatch({
        type: 'success',
        response: { [key]: res },
        error: null,
      });
      calling[url][key] = false;
      changeLoadingState(false);
    } catch (error) {
      handleError(true, error);
      calling[url][key] = false;
      changeLoadingState(false);
    }
  })();
  return context.state;
};
