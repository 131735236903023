import classes from './Button.module.scss';
import bubbleImage from '../../static/images/bubble.svg';
import { CSSProperties } from 'react';

type ColorValiation = 'red' | 'yellow' | 'green' | 'gray';
export type ButtonProps = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  color?: ColorValiation;
  minWidth?: string;
};

const Button = (props: ButtonProps) => {
  const {
    buttonStyle,
    textStyle,
  }: { buttonStyle: CSSProperties; textStyle: CSSProperties } = (() => {
    if (props.color === 'green') {
      return {
        buttonStyle: {
          minWidth: props.minWidth,
          background:
            'var(--linear, linear-gradient(220deg, #00f5a0 0%, #00d9f5 100%))',
        },
        textStyle: {
          backgroundColor: 'rgba(0, 245, 160, 0.5)',
        },
      };
    }
    return {
      buttonStyle: {
        minWidth: props.minWidth,
        background:
          'radial-gradient(50% 50.00% at 50% 50.00%, #173D49 0%, #0A0D10 100%)',
      },
      textStyle: {
        backgroundColor: 'rgba(10, 13, 16, 0.8)',
        color: '#00F5A0',
      },
    };
  })();

  return (
    <button
      className={classes.button}
      style={buttonStyle}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <div className={classes.dummy}>{props.label}</div>
      <img src={bubbleImage} alt='button-bubble-image' />
      <div className={classes.block} style={textStyle}>
        {props.label}
      </div>
    </button>
  );
};

export default Button;
