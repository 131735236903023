import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import classes from './App.module.scss';
import AppProvider from './hooks/provider/AppProvider';
import { InvestResultProvider } from './hooks/reducer/InvestResultReducer';
import { ModalOpenProvider } from './molecules/modal-wrapper/ModalWrapper';
import ChartWrapper from './organisms/chart-wrapper/ChartWrapper';
import ErrorHandler, {
  ErrorHandlerProvider,
} from './organisms/error-handler/ErrorHandler';
import { FooterProvider } from './organisms/footer/Footer';
import Loading, { LoadingProvider } from './organisms/loading/Loading';
import Bank from './pages/bank/Bank';
import Crypto from './pages/crypto/Crypto';
import Dashboard from './pages/dashboard/Dashboard';
import ExceedStock from './pages/exceed-stock/ExceedStock';
import InvestResult from './pages/invest-result/InvestResult';
import JobEnd from './pages/job-end/JobEnd';
import JobReset from './pages/job-reset/JobReset';
import JobSetting from './pages/job-setting/JobSetting';
import JobStart from './pages/job-start/JobStart';
import Login from './pages/login/Login';
import SsoLogin from './pages/sso/SsoLogin';
import StockDetails from './pages/stock-details/StockDetails';
import Stock from './pages/stock/Stock';
import Trust from './pages/trust/Trust';
import BadgeResult from './organisms/badge-result/BadgeResult';
import RecordChart from './organisms/record-chart/RecordChart';

// Providerをまとめたい
const App = () => {
  return (
    <>
      <BrowserRouter basename='/app'>
        <ErrorHandlerProvider>
          <ErrorHandler />
          <LoadingProvider>
            <Loading />
            <InvestResultProvider>
              <FooterProvider>
                <div className={classes.container}>
                  <AppProvider>
                    <ModalOpenProvider>
                      <Routes>
                        <Route
                          index
                          element={<Navigate replace to={'/login'} />}
                        />
                        <Route path={'/login'} element={<Login />} />
                        <Route path={'/sso'} element={<SsoLogin />} />
                        <Route
                          path={'/job'}
                          element={<Dashboard type='job' />}
                        />
                        <Route
                          path={'/record'}
                          element={<Dashboard type='record' />}
                        />
                        <Route
                          path={'/result'}
                          element={<Dashboard type='result' />}
                        />
                        <Route path={'/stock'} element={<Stock />} />
                        <Route path={'/trust'} element={<Trust />} />
                        <Route path={'/crypto'} element={<Crypto />} />
                        <Route path={'/bank'} element={<Bank />} />
                        <Route
                          path={'/stock-detail'}
                          element={<StockDetails />}
                        />
                        <Route
                          path={'/exceed-stock'}
                          element={<ExceedStock />}
                        />
                        <Route path={'/job-setting'} element={<JobSetting />} />
                        <Route path={'/job-reset'} element={<JobReset />} />
                        <Route path={'/job-start'} element={<JobStart />} />
                        <Route
                          path={'/invest-result'}
                          element={<InvestResult />}
                        />
                        <Route
                          path={'/badge-result'}
                          element={<BadgeResult />}
                        />
                        <Route path={'/chart'} element={<ChartWrapper />} />
                        <Route
                          path={'/record-chart'}
                          element={<RecordChart />}
                        />
                        <Route path={'/job-end'} element={<JobEnd />} />
                        <Route
                          path='*'
                          element={<Navigate replace to={'/login'} />}
                        />
                      </Routes>
                    </ModalOpenProvider>
                  </AppProvider>
                </div>
              </FooterProvider>
            </InvestResultProvider>
          </LoadingProvider>
        </ErrorHandlerProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
